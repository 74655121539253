import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Spinner } from '@chakra-ui/react'; // Spinner 컴포넌트를 가져옵니다.
import FailureModal from '../../components/Modal/FailureModal';
import { postStoreDetail } from '../../api';

function GoToRankingBtn({ store }) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false); // 로딩 상태 추가
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const handleMutationError = (error) => {
    setLoading(false); // 에러 발생 시 로딩 상태 해제
    if (error.response && error.response.data && error.response.data.error) {
      setErrorMessage(error.response.data.error);
    } else {
      setErrorMessage(`Error: ${error}`);
    }
    setErrorModalOpen(true);
  };

  const postMutation = useMutation(postStoreDetail, {
    onSuccess: (data) => {
      setLoading(false); // 성공 시 로딩 상태 해제
      const keywordsParam = encodeURIComponent(store.my_keywords.join(','));
      navigate(
        `/ranking/trace/detail/${store.name}/${store.id}?fromButton=true&keywords=${keywordsParam}`,
      );
    },
    onError: handleMutationError,
  });

  const handleButtonClick = () => {
    setLoading(true); // 버튼 클릭 시 로딩 상태 설정
    postMutation.mutate(store);
  };

  return (
    <div>
      {isLoading ? (
        <Spinner color="#ff6524" size="md" />
      ) : (
        <button onClick={handleButtonClick} style={{ fontWeight: '500' }}>
          순위추적 바로가기
        </button>
      )}
      <FailureModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
          setErrorMessage(null);
        }}
        error={errorMessage}
      />
    </div>
  );
}

export default GoToRankingBtn;
