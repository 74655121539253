import React, { useState, useEffect } from 'react';
import styles from './CompareDetail.module.scss';
import CompareKeywordBar from '../../components/CompareBar/CompareKeywordBar/CompareKeywordBar';
import CompareStoreBar from '../../components/CompareBar/CompareStoreBar/CompareStoreBar';
import FindTable from '../../components/FindTable/FindTable';
import CompareStoreTable from '../../components/CompareStoreTable/CompareStoreTable';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getCompareFindKeyword,
  getCompareFindStore,
  getCompareKeyword,
  addCompareKeyword,
  deleteCompareKeyword,
  getCompareStore,
  addCompareStore,
  deleteCompareStore,
  getCompare,
  getTaskStatus,
} from '../../api';
import FailureModal from '../../components/Modal/FailureModal';
import { BiSearchAlt } from 'react-icons/bi';
import CompareModal from '../../components/CompareModal/CompareModal';

function CompareDetail() {
  const [searchKeyword, setSearchKeyword] = useState(''); // 키워드 관련 상태
  const [searchStore, setSearchStore] = useState(''); // 매장 관련 상태
  const [searchAddress, setSearchAddress] = useState(''); // 주소 관련 상태
  const [selectedKeywords, setSelectedKeywords] = useState(new Set()); //선택 키워드를 관리하는 state
  const [selectedStores, setSelectedStores] = useState(new Map()); // 선택 상점을 관리하는 state

  const [isModalOpen, setModalOpen] = useState(false); // 모달의 상태를 관리하는 state
  const [modalError, setModalError] = useState(''); // 모달에 표시될 에러 메시지

  const [isCompareModalOpen, setCompareModalOpen] = useState(false); // 순위 비교 모달

  const queryClient = useQueryClient();

  const handleKeywordSearch = (newSearchKeyword) => {
    setSearchKeyword(newSearchKeyword);
  };

  const handleStoreSearch = (newSearchStore, newAddress) => {
    setSearchStore(newSearchStore);
    setSearchAddress(newAddress);
  };

  // 현재 사용자의 키워드를 가져오는 쿼리
  const {
    data: compareKeywords,
    isLoading: isLoadingUserKeywords,
    isError,
    error,
  } = useQuery(['getCompareKeyword'], getCompareKeyword, {
    onSuccess: (keywords) => {
      setSelectedKeywords(new Set(keywords));
    },
    onError: (error) => {
      console.error('getCompareKeyword Error:', error);
    },
    retry: false,

    staleTime: 1000 * 60 * 5, // 5분 동안은 캐시 데이터 사용
  });

  useEffect(() => {
    if (compareKeywords) {
      setSelectedKeywords(new Set(compareKeywords));
    }
  }, [compareKeywords]);

  // 현재 사용자의 상점을 가져오는 쿼리
  const { data: compareStores, isLoading: isLoadingUserStores } = useQuery(
    ['getCompareStore'],
    getCompareStore,
    {
      onSuccess: (compareStores) => {
        const storeMap = new Map(
          compareStores.map((store) => [store.id, store.name]),
        );
        setSelectedStores(storeMap);
      },
      onError: (error) => {
        console.error('getCompareStore Error:', error);
      },
      retry: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  useEffect(() => {
    if (compareStores) {
      const storeMap = new Map(
        compareStores.map((store) => [String(store.id), store.name]),
      );
      setSelectedStores(storeMap);
    }
  }, [compareStores]);

  const {
    isLoading: isLoadingKeyword,
    data: keywordData,
    isError: isKeywordError,
    error: keywordError,
  } = useQuery(
    ['getCompareFindKeyword', searchKeyword],
    () => getCompareFindKeyword(searchKeyword),
    {
      enabled: !!searchKeyword,
      retry: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  const {
    isLoading: isLoadingStore,
    data: storeData,
    isError: isStoreError,
    error: storeError,
  } = useQuery(
    ['getCompareFindStore', searchStore, searchAddress],
    () => getCompareFindStore(searchStore, searchAddress),
    {
      enabled: !!searchStore, // searchAddress는 선택적
      retry: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  const [singleTaskId, setSingleTaskId] = useState(null);

  // 순위 비교 쿼리
  const { data: compareData, refetch: refetchCompare } = useQuery(
    ['getCompare'],
    getCompare,
    {
      enabled: false,
      retry: false,
      onSuccess: (data) => {
        // 2. singleTaskId 설정
        const firstTaskId = Object.values(data.task_ids)[0];
        setSingleTaskId(firstTaskId); // 첫 번째 task_id를 singleTaskId로 설정
      },
    },
  );

  // getTaskStatus 로딩 상태 관리를 위한 state

  const [taskStatus, setTaskStatus] = useState(null);

  useEffect(() => {
    let timeoutId;

    const checkTaskStatus = async () => {
      if (singleTaskId) {
        const statusResponse = await getTaskStatus(singleTaskId);
        setTaskStatus(statusResponse[0] || statusResponse);

        // "SUCCESS"나 "FAILURE" 상태가 아닐 때 (즉, "PENDING"이나 "STARTED"일 때) 상태를 확인합니다.
        if (!['SUCCESS', 'FAILURE'].includes(statusResponse[0]?.state)) {
          timeoutId = setTimeout(checkTaskStatus, 5000); // 다시 상태를 확인합니다.
        }
      }
    };

    checkTaskStatus();

    return () => {
      clearTimeout(timeoutId); // 컴포넌트 unmount 시 타이머를 정리합니다.
    };
  }, [singleTaskId]);

  const mutationAddKeyword = useMutation(addCompareKeyword);

  const mutationDeleteKeyword = useMutation(deleteCompareKeyword);

  const mutationAddStore = useMutation(addCompareStore);

  const mutationDeleteStore = useMutation(deleteCompareStore);

  // 키워드를 추가하는 함수
  const handleKeywordAdd = (keyword) => {
    if (selectedKeywords.size >= 3) {
      setModalError('키워드는 최대 3개까지 추가할 수 있습니다.');
      setModalOpen(true);
      return;
    }

    mutationAddKeyword.mutate(keyword, {
      onSuccess: () => {
        // 직접 쿼리 데이터를 업데이트
        queryClient.setQueryData(['getCompareKeyword'], (oldData) => [
          ...oldData,
          keyword,
        ]);

        setSelectedKeywords(new Set([...selectedKeywords, keyword]));
      },
    });
  };

  // 키워드를 삭제하는 함수
  const handleKeywordRemove = (keyword) => {
    mutationDeleteKeyword.mutate(keyword, {
      onSuccess: () => {
        // 직접 쿼리 데이터를 업데이트
        queryClient.setQueryData(['getCompareKeyword'], (oldData) =>
          oldData.filter((k) => k !== keyword),
        );

        const updatedKeywords = new Set(selectedKeywords);
        updatedKeywords.delete(keyword);
        setSelectedKeywords(updatedKeywords);
      },
    });
  };

  // 상점을 추가하는 함수
  const handleStoreAdd = (store) => {
    if (selectedStores.size >= 3) {
      setModalError('매장은 최대 3개까지 추가할 수 있습니다.');
      setModalOpen(true);
      return;
    }

    mutationAddStore.mutate(store, {
      onSuccess: () => {
        // 캐시 업데이트
        queryClient.setQueryData(['getCompareStore'], (oldData) => [
          ...oldData,
          { name: store.name, id: store.id },
        ]);

        // UI 상태 업데이트
        setSelectedStores(new Map([...selectedStores, [store.id, store.name]]));
      },
    });
  };

  // 상점을 삭제하는 함수
  const handleStoreRemove = (storeId) => {
    mutationDeleteStore.mutate(storeId, {
      onSuccess: () => {
        // 캐시 업데이트
        queryClient.setQueryData(['getCompareStore'], (oldData) =>
          oldData.filter((s) => String(s.id) !== String(storeId)),
        );

        // UI 상태 업데이트
        const updatedStores = new Map(selectedStores);
        updatedStores.delete(String(storeId));
        setSelectedStores(updatedStores);
      },
    });
  };

  // 모달을 닫는 함수
  const handleCloseModal = () => {
    setModalOpen(false);
    setModalError(''); // 에러 메시지를 초기화합니다.
  };

  // 버튼 클릭 이벤트 핸들러
  const handleCompareClick = () => {
    // 상태 초기화
    setSingleTaskId(null);
    setTaskStatus(null);

    // 키워드 목록이 1개 이상, 매장 목록이 2개 이상인지 확인
    if (selectedKeywords.size < 1 || selectedStores.size < 2) {
      setModalError('최소 1개의 키워드와 2개의 매장을 선택해야 합니다.');
      setModalOpen(true);
      return; // 이 부분은 중요합니다. 조건에 맞지 않으면 함수를 종료합니다.
    }

    // 나머지 로직은 그대로 유지합니다.
    refetchCompare().then((response) => {
      const singleTaskId = Object.values(response.data.task_ids)[0];
      setTaskStatus({ task_id: singleTaskId, state: 'STARTED' });
    });
    setCompareModalOpen(true);
  };

  const handleCloseCompareModal = () => {
    setCompareModalOpen(false);
  };

  // API로부터 받아온 비교 데이터를 처리하는 로직 (예: console.log)
  useEffect(() => {
    if (compareData) {
      console.log('비교 데이터:', compareData);
    }
  }, [compareData]);

  if (isError) {
    console.error('API 호출에 실패했습니다: ', error);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <header>네이버 플레이스 순위 비교</header>
      </div>

      <div className={styles.compareList}>
        <div className={styles.listTitle}>
          <div>
            키워드 목록
            <span className={styles.description}>
              최대 3개까지 비교 가능합니다
            </span>
          </div>
          <div>
            <button
              className={styles.searchButton}
              onClick={handleCompareClick}
            >
              키워드별 플레이스 순위 비교
              <BiSearchAlt />
            </button>
          </div>
        </div>
        <div className={styles.listItem}>
          {isLoadingUserKeywords ? (
            <div className={styles.loading}>로딩 중...</div>
          ) : (
            compareKeywords.map((keyword) => (
              <div key={keyword} className={styles.keywordTag}>
                <button
                  className={styles.tagDelete}
                  onClick={() => handleKeywordRemove(keyword)}
                >
                  X
                </button>
                <span>{keyword}</span>
              </div>
            ))
          )}
        </div>
        <div className={styles.listTitle}>
          <div>
            매장 목록
            <span className={styles.description}>
              최대 3개까지 비교 가능합니다
            </span>
          </div>
        </div>
        <div className={styles.listItem}>
          {isLoadingUserStores ? (
            <div className={styles.loading}>로딩 중...</div>
          ) : (
            compareStores?.map((store) => (
              <div key={store.id} className={styles.keywordTag}>
                <button
                  className={styles.tagDelete}
                  onClick={() => handleStoreRemove(store.id)}
                >
                  X
                </button>
                <span>{store.name}</span>
              </div>
            ))
          )}
        </div>
        <div className={styles.mobileBtnBox}>
          <button
            className={styles.mobileSearchButton}
            onClick={handleCompareClick}
          >
            키워드별 플레이스 순위 비교
            <BiSearchAlt />
          </button>
        </div>
      </div>

      <div className={styles.findKeyword}>
        <div className={styles.header}>
          <img src="/images/eye.png" alt="눈 이미지" />
          <span>키워드 찾기</span>
        </div>
        <CompareKeywordBar onSearch={handleKeywordSearch} />

        {isLoadingKeyword && !!searchKeyword ? (
          <div className={styles.loading}>키워드 정보 로딩 중...</div>
        ) : isKeywordError ? (
          <div className={styles.error}>
            {keywordError?.response?.data?.error || '알 수 없는 에러 발생'}
          </div>
        ) : (
          !!keywordData && (
            <div className={styles.tableLayout}>
              <FindTable
                data={keywordData}
                searchTerm={searchKeyword}
                onKeywordAdd={handleKeywordAdd}
                onKeywordRemove={handleKeywordRemove}
                selectedKeywords={selectedKeywords}
                scrollable={true}
              />
            </div>
          )
        )}
      </div>

      <div className={styles.findStore}>
        <div className={styles.header}>
          <img src="/images/eye.png" alt="눈 이미지" />
          <span>매장 찾기</span>
        </div>
        <CompareStoreBar onSearch={handleStoreSearch} />

        {isLoadingStore && !!searchStore ? (
          <div className={styles.loading}>매장 정보 로딩 중...</div>
        ) : isStoreError ? (
          <div className={styles.error}>
            {storeError?.response?.data?.error || '알 수 없는 에러 발생'}
          </div>
        ) : (
          !!storeData && (
            <div className={styles.tableLayout}>
              <CompareStoreTable
                data={storeData}
                searchTerm={searchStore}
                onStoreAdd={handleStoreAdd}
                onStoreRemove={handleStoreRemove}
                selectedStores={selectedStores}
                scrollable={true}
              />
            </div>
          )
        )}
      </div>

      <FailureModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        error={modalError}
      />

      <CompareModal
        isOpen={isCompareModalOpen}
        onClose={handleCloseCompareModal}
        taskStatus={taskStatus}
        storeData={compareData?.store_data}
      />
      {isCompareModalOpen && (
        <div
          className={`${styles.overlay} ${
            isCompareModalOpen ? styles.show : ''
          }`}
          onClick={handleCloseModal}
        />
      )}
    </div>
  );
}

export default CompareDetail;
