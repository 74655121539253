import React, { useState } from 'react';
import styles from './KeywordChart.module.scss';
import RankingLineChart from '../Charts/RankingLineChart';

function KeywordChart({ mainKeyword, data }) {
  const [period, setPeriod] = useState('daily');
  if (
    !data ||
    !data.keyword ||
    !data.keyword[mainKeyword] ||
    !Array.isArray(data.keyword[mainKeyword])
  ) {
    // console.error('No data for keyword:', mainKeyword);
    return (
      <div className={styles.container}>
        <div className={styles.header}>메인 키워드 순위 변화</div>
        <div className={styles.contents}>
          <div className={styles.title}>
            <p>메인키워드</p> <p>|</p> <span></span> <span>- 위</span>
          </div>
          <div className={styles.chart}></div>
        </div>
      </div>
    ); // or some fallback UI
  }

  const keywordData = data.keyword[mainKeyword];
  const latestRank = keywordData[keywordData.length - 1].rank;

  return (
    <div className={styles.container}>
      <div className={styles.header}>메인 키워드 순위 변화</div>
      <div className={styles.contents}>
        <div className={styles.chart_header}>
          <div className={styles.title}>
            <p>메인키워드</p>
            <p>|</p> <span>{mainKeyword}</span>
            {/* <span>{latestRank === 300 ? '순위 밖' : `${latestRank}위`}</span> */}
          </div>
          <div className={styles.category}>
            <span
              onClick={() => setPeriod('daily')}
              style={{
                color: period === 'daily' ? 'black' : null,
                fontWeight: period === 'daily' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              일간
            </span>
            <span
              onClick={() => setPeriod('weekly')}
              style={{
                color: period === 'weekly' ? 'black' : null,
                fontWeight: period === 'weekly' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              주간
            </span>
            <span
              onClick={() => setPeriod('monthly')}
              style={{
                color: period === 'monthly' ? 'black' : null,
                fontWeight: period === 'monthly' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              월간
            </span>
          </div>
        </div>
        <div className={styles.chart}>
          <RankingLineChart rawData={{ graph: data.graph }} period={period} />
        </div>
      </div>
    </div>
  );
}

export default KeywordChart;
