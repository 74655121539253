import React, { useState } from 'react';
import styles from './SolutionSearch.module.scss';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import StoreList from '../../components/StoreList/StoreList';
import { getStoreListSolution, postStoreDetailSolution } from '../../api';
import GuideModal from '../../components/Modal/GuideModal';
import FailureModal from '../../components/Modal/FailureModal';

function SolutionSearch() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const address = searchParams.get('adr');
  const searchTerm = searchParams.get('q');

  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const {
    data: storeData = [],
    isLoading, // add this
    isError,
    error,
  } = useQuery(
    ['storeListSolution', searchTerm, address],
    () => getStoreListSolution(searchTerm, address),
    {
      retry: false,
    },
  );

  const mutation = useMutation(postStoreDetailSolution, {
    onSuccess: (data, variables) => {
      navigate(`/solution/detail/${variables.name}/${variables.id}`);
      setErrorMessage(null);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage(`Error while posting store detail: ${error}`);
      }
      setErrorModalOpen(true);
    },
  });

  const handleProceed = (store) => {
    mutation.mutate(store); // trigger the mutation
  };

  const handleClick = (store) => {
    setSelectedStore(store);
    setIsOpen(true);
  };

  const MessageComponent = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        fontWeight: 500,
        fontSize: '18px',
      }}
    >
      <p style={{ fontSize: '20px', marginBottom: '10px' }}>매장 등록</p>
      <p>
        <span style={{ color: '#ff6524' }}>마케팅 솔루션</span>을 받아볼
      </p>
      <p>매장을 등록해보세요.</p>
    </div>
  );

  if (isLoading) {
    // add this
    return <div>Loading...</div>;
  }

  if (isError) {
    console.error(error);
    // Check if error.response exists before trying to access its properties
    if (error.response) {
      return <div>{error.response.data.error}</div>;
    } else {
      if (error.message === 'No access token found') {
        navigate('/error'); // 이 페이지에서는 기능 사용할 때 require 페이지로 보내주기
      }
      return null;
    }
  }

  const handleLoadMore = () => {
    setLoadMoreCount(loadMoreCount + 1);
  };

  const reviewsToShow = 10 * (loadMoreCount + 1);

  let searchDescription;
  if (address) {
    searchDescription = `${address}, ${searchTerm}`;
  } else {
    searchDescription = `${searchTerm}`;
  }

  return (
    <div className={styles.container}>
      <div>
        {searchDescription}(으)로 검색한 {storeData.total_count}개의 매장입니다.
      </div>
      <div className={styles.listContainer}>
        {storeData?.values.slice(0, reviewsToShow).map((store) => (
          <StoreList
            key={store.id}
            store={store}
            onClick={() => handleClick(store)}
          />
        ))}
      </div>
      {storeData.values.length > reviewsToShow && (
        <div className={styles.btnContainer}>
          <button className={styles.listMoreBtn} onClick={handleLoadMore}>
            매장 더보기
          </button>
        </div>
      )}
      <GuideModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedStore(null);
        }}
        onProceed={() => {
          handleProceed(selectedStore);
          setIsOpen(false);
        }}
        MessageComponent={MessageComponent}
      />
      {/* 에러 처리를 위한 FailureModal */}
      <FailureModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
          setErrorMessage(null);
        }}
        error={errorMessage}
      />
    </div>
  );
}

export default SolutionSearch;
