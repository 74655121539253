import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postBlogLog } from '../../api';
import styles from './BlogRegister.module.scss';
import { Spinner } from '@chakra-ui/react';
import FailureModal from '../Modal/FailureModal';

function BlogRegister({ storeId }) {
  const [blogUrl, setBlogUrl] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalError, setModalError] = useState('');
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newBlogUrl) => postBlogLog(storeId, newBlogUrl),
    {
      onSuccess: (data) => {
        if (data.msg === '이미 등록한 블로그 입니다.') {
          setModalError('이미 등록한 블로그 입니다.');
          setModalOpen(true);
        } else if (
          data.msg === '존재 하지 않는 블로그 이거나 서버 오류 입니다.'
        ) {
          setModalError('존재하지 않는 블로그이거나 서버 오류 입니다.');
          setModalOpen(true);
        } else if (data.msg === '블로그는 최대 10개만 등록할수 있습니다.') {
          setModalError('블로그는 최대 10개만 등록할수 있습니다.');
          setModalOpen(true);
        } else {
          queryClient.invalidateQueries('getBlogLog');
        }
      },
      onError: (error) => {
        if (error?.response?.data?.error === 'Invalid link format') {
          setModalError('잘못된 형식의 주소입니다.');
          setModalOpen(true);
        } else {
          setModalError('알 수 없는 오류가 발생했습니다.');
          setModalOpen(true);
        }
      },
    },
  );

  const handleAddBlog = (e) => {
    e.preventDefault();
    if (blogUrl) {
      mutation.mutate(blogUrl);
      setBlogUrl('');
    }
  };

  return (
    <div className={styles.container}>
      <FailureModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        error={modalError}
      />
      <form onSubmit={handleAddBlog} className={styles.addInput}>
        <input
          className={styles.inputWord}
          placeholder="네이버 블로그 URL을 입력해주세요."
          value={blogUrl}
          onChange={(e) => setBlogUrl(e.target.value)}
        />
        <button
          className={styles.inputBtn}
          type="submit"
          disabled={!blogUrl.trim() || mutation.isLoading}
        >
          {mutation.isLoading ? <Spinner size="sm" /> : '+ 추가'}
        </button>
      </form>
    </div>
  );
}

export default BlogRegister;
