import React, { useState, useEffect } from 'react';
import styles from './FindDetail.module.scss';
import { useSearchParams } from 'react-router-dom';
import FindTable from '../../components/FindTable/FindTable';
import { Tooltip } from '@chakra-ui/react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  findKeyword,
  getMyKeyword,
  addMyKeyword,
  deleteMyKeyword,
} from '../../api';
import Cookies from 'js-cookie';
import FailureModal from '../../components/Modal/FailureModal';

function FindDetail() {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('q');
  const [selectedKeywords, setSelectedKeywords] = useState(new Set());
  const [isFailureModalOpen, setFailureModalOpen] = useState(false); // FailureModal 상태 변수 추가
  const [failureModalMessage, setFailureModalMessage] = useState(''); // 에러 메시지 상태 변수 추가

  const queryClient = useQueryClient();

  const { isLoading, isError, data, error } = useQuery(
    ['findKeyword', searchTerm], // 고유한 쿼리 키
    () => findKeyword(searchTerm), // API 호출 함수
    {
      enabled: !!searchTerm, // searchTerm이 있을 때만 쿼리 실행
      retry: false, // 실패했을 때 재시도하지 않음
    },
  );

  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}년 ${
    currentDate.getMonth() + 1
  }월 ${currentDate.getDate()}일 00시 기준`;

  const mutationAddKeyword = useMutation(addMyKeyword);
  const mutationDeleteKeyword = useMutation(deleteMyKeyword);

  // 페이지 로드 시 현재 사용자의 키워드를 가져옵니다.

  const token = Cookies.get('access');
  useEffect(() => {
    // 로그인 되어 있지 않다면 API를 호출하지 않습니다.
    if (!token) {
      return;
    }

    getMyKeyword().then((keywords) => {
      setSelectedKeywords(new Set(keywords));
    });
  }, [token]);

  // 키워드를 추가하는 함수
  const handleKeywordAdd = (keyword) => {
    if (!checkLoginAndAlert()) return;
    // 먼저 로컬 상태 업데이트
    const updatedKeywords = new Set([...selectedKeywords, keyword]);

    // mutation 실행
    mutationAddKeyword.mutate(keyword, {
      onSuccess: () => {
        queryClient.setQueryData(['findKeyword', searchTerm], (oldData) => {
          if (!oldData || !Array.isArray(oldData.keywords)) {
            return oldData; // oldData가 undefined이거나 oldData.keywords가 배열이 아닐 경우 그대로 반환
          }
          return {
            ...oldData,
            keywords: [...oldData.keywords, keyword],
          };
        });
        setSelectedKeywords(updatedKeywords);
      },
    });
    console.log('After handleKeywordAdd:', Array.from(updatedKeywords));
  };

  // 키워드를 삭제하는 함수
  const handleKeywordRemove = (keyword) => {
    if (!checkLoginAndAlert()) return;
    // 먼저 로컬 상태 업데이트
    const updatedKeywords = new Set(selectedKeywords);
    updatedKeywords.delete(keyword);

    // mutation 실행
    mutationDeleteKeyword.mutate(keyword, {
      onSuccess: () => {
        queryClient.setQueryData(['findKeyword', searchTerm], (oldData) => {
          if (!oldData || !Array.isArray(oldData.keywords)) {
            return oldData; // oldData가 undefined이거나 oldData.keywords가 배열이 아닐 경우 그대로 반환
          }
          return {
            ...oldData,
            keywords: oldData.keywords.filter((k) => k !== keyword),
          };
        });
        setSelectedKeywords(updatedKeywords);
      },
    });
    console.log('After handleKeywordRemove:', Array.from(updatedKeywords));
  };

  // 로그인 확인 및 알림 표시를 위한 함수
  const checkLoginAndAlert = () => {
    if (!token) {
      // 로그인 모달 대신 FailureModal을 열기
      setFailureModalMessage('이 기능을 사용하려면 로그인이 필요합니다.');
      setFailureModalOpen(true);
      return false;
    }
    return true;
  };

  const isLoadingOverall = isLoading;

  if (isLoadingOverall) {
    return <div>로딩 중입니다...</div>;
  }

  if (isError) {
    console.error('An error occurred:', error);
    return <div>{error.message}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src="/images/eye.png" alt="눈 이미지" />
        <span>키워드찾기 결과</span>
      </div>
      <div className={styles.keywordTab}>
        <div className={styles.keywordBox}>
          <div className={styles.findKeyword}>{searchTerm}</div>
          <div className={styles.updateTime}>{formattedDate}</div>
        </div>
        <div className={styles.keywordInfo}>
          {/* <div className={styles.infoTitle}>키워드 월간 데이터</div> */}
          <div className={styles.infoList}>
            <div className={styles.staticLayout}>
              <p className={styles.statics}>
                {data.keyword.search_count.toLocaleString()}
              </p>
              <p className={styles.staticName}>월간 검색량</p>
            </div>
            <div className={styles.staticLayout}>
              <p className={styles.statics}>
                {data.keyword.blog_total_num.toLocaleString()}
              </p>
              <p className={styles.staticName}>월간 콘텐츠 발행량</p>
            </div>
            <div className={styles.staticLayout}>
              <p className={styles.statics}>{data.keyword.saturation}</p>
              <p className={styles.staticIndex}>
                {data.keyword.saturation_index}
              </p>
              <p className={styles.staticName}>콘텐츠 포화지수</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.categoryTab}>
        <div className={styles.tabTitle}>추가된 키워드</div>

        <div className={styles.btnList}>
          <Tooltip
            label={'준비중입니다.'}
            placement="top"
            hasArrow
            padding="10px"
          >
            <button className={styles.btn} disabled={true}>
              <img src="/images/filter.png" alt="필터 이미지" />
            </button>
          </Tooltip>
          <Tooltip
            label={'준비중입니다.'}
            placement="top"
            hasArrow
            padding="10px"
          >
            <button className={styles.btn} disabled={true}>
              <img src="/images/excel.png" alt="엑셀 이미지" />
            </button>
          </Tooltip>
        </div>
      </div>

      <div className={styles.listBox}>
        {[...selectedKeywords].map((keyword) => (
          <div key={keyword} className={styles.keywordTag}>
            <button
              className={styles.tagDelete}
              onClick={() => handleKeywordRemove(keyword)}
            >
              X
            </button>
            <span>{keyword}</span>
          </div>
        ))}
      </div>

      <FindTable
        data={data}
        searchTerm={searchTerm}
        onKeywordAdd={handleKeywordAdd}
        onKeywordRemove={handleKeywordRemove}
        selectedKeywords={selectedKeywords}
      />

      <FailureModal
        isOpen={isFailureModalOpen}
        onClose={() => setFailureModalOpen(false)}
        error={failureModalMessage}
      />
    </div>
  );
}

export default FindDetail;
