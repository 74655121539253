import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userSignup } from '../../api';
import styles from './Signuppage.module.scss';
import { Spinner } from '@chakra-ui/react';
import SignUpModal from '../../components/Modal/SignUpModal';

function SignupPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const navigate = useNavigate();
  const token = Cookies.get('access');

  useEffect(() => {
    // 로그인 상태일 경우 홈페이지로 리다이렉트
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  // `watch`를 사용하여 'password' 필드의 값을 실시간으로 관찰합니다.
  const password = watch('password');

  const [serverError, setServerError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [phoneFirst, setPhoneFirst] = useState('');
  const [phoneMiddle, setPhoneMiddle] = useState('');
  const [phoneLast, setPhoneLast] = useState('');

  const [emailLocalPart, setEmailLocalPart] = useState('');
  const [emailDomain, setEmailDomain] = useState('');
  // 이메일 도메인 input 요소를 참조하기 위한 ref
  const emailDomainInputRef = useRef(null);
  // `select`의 값을 관리하기 위한 새로운 상태를 추가합니다.
  const [selectedDomain, setSelectedDomain] = useState('');

  // 이메일 도메인 선택 옵션
  const emailDomains = ['gmail.com', 'naver.com', 'daum.net', 'hanmail.net'];

  // 이메일 도메인 `select` 변경 핸들러
  const handleEmailDomainChange = (e) => {
    const value = e.target.value;
    setSelectedDomain(value); // 선택된 도메인 상태 업데이트
    setEmailDomain(value); // 이메일 도메인 상태 업데이트

    // '직접 입력'이 선택된 경우에는 input에 포커스를 줍니다.
    if (value === '') {
      emailDomainInputRef.current.focus();
    } else {
      // 직접 입력이 아닌 경우에는 선택된 도메인을 이메일 도메인 필드에 설정
      setEmailDomain(value);
    }
    clearErrors('email'); // 'email' 필드의 에러 초기화
  };

  // 이메일 도메인 input 요소의 onChange 핸들러
  const handleEmailDomainInputChange = (e) => {
    const newEmailDomain = e.target.value;
    setEmailDomain(newEmailDomain);
    clearErrors('email'); // 'email' 필드의 에러 초기화

    // 사용자가 직접 입력을 시작하면 select의 값을 초기화합니다.
    if (selectedDomain !== '') {
      setSelectedDomain('');
    }
  };
  // 이메일 도메인 input 포커스 핸들러
  const handleEmailDomainFocus = () => {
    // '직접 입력'이 아니라면
    if (selectedDomain && selectedDomain !== '직접 입력') {
      setEmailDomain(''); // input 필드 비우기
      setSelectedDomain(''); // select의 값을 '직접 입력'으로 변경
    }
  };

  const mutation = useMutation(userSignup, {
    onSuccess: () => {
      setShowModal(true);
    },
    onError: (error) => {
      // 에러 처리 로직
      const errorResponse = error.response?.data;
      if (errorResponse) {
        if (errorResponse.username) {
          setError('username', {
            type: 'server',
            message: errorResponse.username[0],
          });
        }
        if (errorResponse.email) {
          setError('email', {
            type: 'server',
            message: errorResponse.email[0],
          });
        }

        if (errorResponse.phone_number) {
          setError('phone_number', {
            type: 'server',
            message: errorResponse.phone_number[0],
          });
        }
      }
      console.error('Signup failed');
    },
    // onSettled 콜백은 제거
  });

  // 폼 제출 함수
  const onSubmit = (data) => {
    clearErrors();
    let hasError = false;

    const fullPhoneNumber = `${phoneFirst}-${phoneMiddle}-${phoneLast}`;
    if (!/^\d{3}-\d{4}-\d{4}$/.test(fullPhoneNumber)) {
      setError('phone_number', {
        type: 'manual',
        message: '유효한 전화번호 형식이 아닙니다.',
      });
      hasError = true; // 에러가 있음을 표시합니다.
    }

    const fullEmail = `${emailLocalPart}@${emailDomain}`;
    if (!/\S+@\S+\.\S+/.test(fullEmail)) {
      setError('email', {
        type: 'manual',
        message: '유효한 이메일 형식이 아닙니다.',
      });
      hasError = true; // 에러가 있음을 표시합니다.
    }

    // 비밀번호 확인 검증 로직
    if (data.password !== data.confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: '비밀번호가 일치하지 않습니다.',
      });
      hasError = true; // 에러가 있음을 표시합니다.
    }

    // 에러가 있으면 함수를 여기서 종료합니다.
    if (hasError) return;

    // 에러가 없으면 mutation을 실행합니다.
    const newData = {
      ...data,
      phone_number: fullPhoneNumber,
      email: fullEmail,
      store_name: data.store_name || '', // 매장명이 없으면 빈 문자열로 처리
    };

    mutation.mutate(newData); // mutation을 사용하여 데이터 전송
  };

  const handleModalClose = () => {
    setShowModal(false);
    window.location.href = '/login';
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <div className={styles.logo}>마케차이즈</div>
        <label>
          아이디:
          <input
            type="text"
            {...register('username', {
              required: '아이디를 입력해주세요.',
              minLength: {
                value: 6,
                message: '아이디는 6자 이상이어야 합니다.',
              },
              maxLength: {
                value: 20,
                message: '아이디는 20자를 초과할 수 없습니다.',
              },
            })}
            onChange={(e) => {
              // 필드 값이 변경될 때 실행할 로직
              clearErrors('username'); // 서버 에러 리셋
              // 나머지 변경 처리
            }}
            autoComplete="username"
          />
          {/* 서버에서 전달된 에러 메시지를 보여줍니다. */}
          {errors.username && <p>{errors.username.message}</p>}
        </label>
        <label>
          이름:
          <input
            type="text"
            {...register('name', {
              required: '이름을 입력해주세요.',
              pattern: {
                value: /^[가-힣a-zA-Z]+$/,
                message: '이름에는 문자만 포함될 수 있습니다.',
              },
            })}
            autoComplete="name"
          />
          {errors.name && <p>이름을 확인해주세요.</p>}
        </label>
        <label>
          비밀번호:
          <input
            type="password"
            {...register('password', {
              required: true,
              pattern: {
                value:
                  /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-z])(?=.*[^A-Za-z0-9])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])).{8,}$/,
                message:
                  '비밀번호는 최소 8자리이며, 대문자, 소문자, 숫자 및 특수문자 중 3가지 조합을 포함해야 합니다.',
              },
            })}
            autoComplete="current-password"
          />
          {errors.password && <p>{errors.password.message}</p>}
        </label>

        <label>
          비밀번호 확인:
          <input
            type="password"
            {...register('confirmPassword', {
              validate: (value) =>
                value === password || '비밀번호가 일치하지 않습니다.', // 실시간으로 비밀번호 일치 여부 검증
            })}
            autoComplete="new-password"
          />
          {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
        </label>

        <label>
          휴대전화:
          {/* 전화번호 입력 필드를 세 부분으로 나누어 구성 */}
          <div className={styles.phoneInputContainer}>
            <input
              type="text"
              value={phoneFirst}
              onChange={(e) => {
                setPhoneFirst(e.target.value);
                clearErrors('phone_number'); // 서버 에러 리셋
              }}
              className={styles.phoneInput}
              maxLength="3"
            />
            <span className={styles.phoneDash}>-</span>
            <input
              type="text"
              value={phoneMiddle}
              onChange={(e) => {
                setPhoneMiddle(e.target.value);
                clearErrors('phone_number'); // 서버 에러 리셋
              }}
              className={styles.phoneInput}
              maxLength="4"
            />
            <span className={styles.phoneDash}>-</span>
            <input
              type="text"
              value={phoneLast}
              onChange={(e) => {
                setPhoneLast(e.target.value);
                clearErrors('phone_number'); // 서버 에러 리셋
              }}
              className={styles.phoneInput}
              maxLength="4"
            />
          </div>
          {errors.phone_number && (
            <p className={styles.error}>{errors.phone_number.message}</p>
          )}
        </label>

        <label>
          이메일:
          <div className={styles.emailInputContainer}>
            <input
              type="text"
              value={emailLocalPart}
              onChange={(e) => {
                setEmailLocalPart(e.target.value);
                clearErrors('email'); // 'email' 필드의 에러 초기화
              }}
              className={styles.emailInput}
            />
            <span className={styles.emailDash}>@</span>
            <input
              type="text"
              value={emailDomain}
              onChange={(e) => {
                handleEmailDomainInputChange(e); // 기존 로직을 호출합니다.
                // 추가적으로 필요한 다른 변경 처리 로직이 있다면 여기에 넣습니다.
              }}
              onFocus={handleEmailDomainFocus}
              ref={emailDomainInputRef}
              className={styles.emailInput}
            />
            <select
              value={selectedDomain}
              onChange={(e) => {
                handleEmailDomainChange(e);
              }}
              className={`${styles.emailInput} ${styles.emailDropdown}`}
            >
              <option value="">직접입력</option>
              {emailDomains.map((domain, index) => (
                <option key={index} value={domain}>
                  {domain}
                </option>
              ))}
            </select>
          </div>
          {errors.email && <p>{errors.email.message}</p>}
        </label>

        <label>
          매장명(선택):
          <input
            type="text"
            {...register('store_name', {
              // required 제거
              pattern: {
                value: /^[가-힣a-zA-Z0-9\s]+$/,
                message: '매장명에는 문자와 숫자만 포함될 수 있습니다.',
              },
            })}
            autoComplete="store_name"
          />
          {errors.store_name && <p>{errors.store_name.message}</p>}
        </label>

        <button type="submit" disabled={mutation.isLoading}>
          {mutation.isLoading ? <Spinner /> : '이용 신청'}
        </button>

        {mutation.isError && (
          <p className={styles.submitErr}>
            회원가입에 실패했습니다. <br />
            다시 시도해주세요.
          </p>
        )}
      </form>

      <SignUpModal isOpen={showModal} onClose={handleModalClose} />
    </div>
  );
}

export default SignupPage;
