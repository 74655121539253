import { atom, useRecoilTransactionObserver_UNSTABLE } from 'recoil';

export const currentUserState = atom({
  key: 'currentUser',
  default: null,
});

export function StateObserver() {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    const loadable = snapshot.getLoadable(currentUserState);
    if (loadable.state === 'hasValue') {
      console.log('Current user state: ', loadable.contents);
    }
  });

  return null; // render nothing, this component is just for observing
}
