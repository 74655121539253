import React from 'react';
import styles from './Find.module.scss';
import { useNavigate, Outlet } from 'react-router-dom';
import FindBar from '../../components/FindBar/FindBar';

function Find() {
  const navigate = useNavigate();

  const handleSearch = (searchTerm) => {
    console.log('검색어:', searchTerm);

    const params = new URLSearchParams({
      q: searchTerm,
    }).toString();
    // 쿼리 매개변수를 활용한 동적 라우팅

    navigate(`/find/detail?${params}`);
  };
  return (
    <div className={styles.container}>
      {/* <Seo title="순위추적" /> */}
      <header className={styles.title}>가장 쉬운 키워드 찾기</header>
      <div className={styles.logo}>
        <img src="/images/engLogo.png" alt="마케차이즈 영문로고" />
      </div>

      <FindBar onSearch={handleSearch} />

      <Outlet />
    </div>
  );
}

export default Find;
