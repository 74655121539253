import React, { useRef } from 'react';
import styles from './Solution.module.scss';
import { useNavigate, Outlet } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import SearchInputContext from '../../hooks/SearchInputContext';

function Solution() {
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const handleSearch = (searchTerm, address) => {
    console.log('검색어:', searchTerm);
    console.log('주소:', address);

    const params = new URLSearchParams({
      adr: address,
      q: searchTerm,
    }).toString();
    // 쿼리 매개변수를 활용한 동적 라우팅

    navigate(`/solution/search?${params}`);
  };

  return (
    <SearchInputContext.Provider value={searchInputRef}>
      <div className={styles.container}>
        {/* <Seo title="순위추적" /> */}
        <header className={styles.title}>우리가게 최고의 솔루션</header>
        <div className={styles.logo}>
          <img src="/images/engLogo.png" alt="마케차이즈 영문로고" />
        </div>

        <SearchBar onSearch={handleSearch} inputRef={searchInputRef} />

        <Outlet />
      </div>
    </SearchInputContext.Provider>
  );
}

export default Solution;
