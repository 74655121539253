import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
    ${reset};

    @font-face {
        font-family: 'Cafe24Ohsquare';
        src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/Cafe24Ohsquare.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }


    *{
      box-sizing: border-box;
    }

    body{
        padding: 0;
        margin: 0;
        font-family: 'Noto Sans KR',sans-serif,'Cafe24Ohsquare'; 
    };
    button{
        display: inline-block;
        cursor: pointer;
        outline: none;
        border-radius: 3px;
    };
    input{
        display: inline-block;
        outline: none;
    }
`;

export default GlobalStyle;
