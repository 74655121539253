import React, { useState, useEffect } from 'react';
import styles from './DiagnosisModal.module.scss';
import { Progress } from '@chakra-ui/react';
import useStripHtml from '../../hooks/useStripHtml';
import useWindowSize from '../../hooks/useWindowSize';

function DiagnosisModal({ isOpen, onClose, BlogData, data }) {
  function formatNumber(num) {
    return num.toLocaleString();
  }

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${year.slice(-2)}/${month}/${day}`;
  }

  const stripHtml = useStripHtml();

  const [width] = useWindowSize();
  const [progressHeight, setProgressHeight] = useState('20px'); // 기본 높이 설정

  useEffect(() => {
    // 화면 너비에 따라 높이를 변경합니다.
    if (width <= 976) {
      setProgressHeight('10px'); // 모바일 뷰에서의 높이
    } else {
      setProgressHeight('20px'); // 기본 높이
    }
  }, [width]); // width 값이 변경될 때마다 이 effect를 실행합니다.

  function CustomProgress({ value }) {
    return (
      <Progress
        value={value}
        height={progressHeight}
        borderRadius="12px"
        sx={{
          // 배경 (track) 스타일
          bg: 'none',
          border: '0.5px solid #999999',

          // 채워진 부분 (filledTrack) 스타일
          '> div': {
            bg: '#ff6524',
          },
        }}
      />
    );
  }

  return (
    <div className={styles.modal}>
      <button className={styles.closeBtn} onClick={onClose}>
        X
      </button>
      <div className={styles.modalHeader}>
        <strong>{data.values[0].name}</strong> 블로그글 상세진단
      </div>

      <div className={styles.modalBody}>
        <div className={styles.bodyTop}>
          <div className={styles.imgBox}>
            {' '}
            {data.values[0].thumUrl ? (
              <img
                src={data.values[0].thumUrl}
                alt={`${data.values[0].name} 이미지`}
              />
            ) : (
              <p className={styles.noImage}>등록된 이미지가 없습니다.</p>
            )}
          </div>

          <div className={styles.info}>
            <div className={styles.title}>
              <a
                href={BlogData.blog_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {stripHtml(BlogData.blog_title)}
              </a>
            </div>
            <div className={styles.score}>
              <div className={styles.textLeft}>
                <p className={styles.scoreText}>작성일</p>
                <p className={styles.scoreNum}>
                  {formatDate(BlogData.blog_post_date)}
                </p>
              </div>
              <div className={styles.textCenter}>
                <p className={styles.scoreText}>블로거 지수</p>
                <p className={styles.scoreNum}>
                  {BlogData.blog_diagnostic.blogger_level}단계
                </p>
              </div>
              <div className={styles.textCenter}>
                <p className={styles.scoreText}>리뷰 반영 점수</p>
                <p className={styles.scoreNum}>
                  {BlogData.blog_diagnostic.review_level}단계
                </p>
              </div>
              <div className={styles.textLeft}>
                <p className={styles.scoreText}>글자 수</p>
                <p className={styles.scoreNum}>
                  {formatNumber(BlogData.blog_diagnostic.count_char)}자
                </p>
              </div>
              <div className={styles.textCenter}>
                <p className={styles.scoreText}>사진 개수</p>
                <p className={styles.scoreNum}>
                  {BlogData.blog_diagnostic.count_img}개
                </p>
              </div>
              <div className={styles.textCenter}>
                <p className={styles.scoreText}>댓글</p>
                <p className={styles.scoreNum}>
                  {BlogData.blog_diagnostic.count_comments}개
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.subTitle}>항목 세부 내역</div>

        <div className={styles.scoreContainer}>
          <div className={styles.scoreBox}>
            <div className={styles.text}>글자 수</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.char_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.char_score} />
            </div>
          </div>
          <div className={styles.scoreBox}>
            <div className={styles.text}>사진 개수</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.img_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.img_score} />
            </div>
          </div>
          <div className={styles.scoreBox}>
            <div className={styles.text}>동영상 개수</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.video_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.video_score} />
            </div>
          </div>
          <div className={styles.scoreBox}>
            <div className={styles.text}>키워드 반복</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.keyword_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.keyword_score} />
            </div>
          </div>
          <div className={styles.scoreBox}>
            <div className={styles.text}>댓글</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.comments_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.comments_score} />
            </div>
          </div>
          <div className={styles.scoreBox}>
            <div className={styles.text}>공감</div>
            <div className={styles.textNum}>
              {BlogData.blog_diagnostic.likes_score}점
            </div>
            <div>
              <CustomProgress value={BlogData.blog_diagnostic.likes_score} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosisModal;
