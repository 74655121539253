import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';
import styles from './Root.module.scss';

export default function Root() {
  return (
    <div>
      <div className={styles.container}>
        <Header />
        <Sidebar />
        <Outlet />
        <Footer className={styles.footer} />
      </div>
    </div>
  );
}
