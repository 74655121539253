import React, { useState, useEffect } from 'react';
import styles from './FindTable.module.scss';

function FindTable({
  data,
  searchTerm,
  onKeywordAdd,
  onKeywordRemove,
  selectedKeywords,
  scrollable,
}) {
  const [loadMoreCount, setLoadMoreCount] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');

  const handleLoadMore = () => {
    setLoadMoreCount(loadMoreCount + 1);
  };

  const rowsToShow = 20 * (loadMoreCount + 1);

  const handleSort = (column) => {
    if (sortColumn === column) {
      if (sortOrder === 'desc') {
        setSortOrder('asc'); // 내림차순 다음은 오름차순
      } else if (sortOrder === 'asc') {
        setSortOrder('original'); // 오름차순 다음은 원래 데이터 순서
      } else {
        setSortOrder('desc'); // 원래 데이터 순서 다음은 내림차순
      }
    } else {
      setSortColumn(column);
      setSortOrder('desc'); // 새 열을 클릭하면 처음에는 내림차순
    }
  };

  const search_data = data.search_data; // 실제 테이블 데이터

  const sortedData = [...search_data];

  if (sortColumn && sortOrder !== 'original') {
    sortedData.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (sortColumn === 'compIdx') {
        const order = { 높음: 3, 중간: 2, 낮음: 1 };
        return sortOrder === 'asc'
          ? order[valueA] - order[valueB]
          : order[valueB] - order[valueA];
      }

      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    });
  }

  const highlightSearchTerm = (keyword) => {
    const index = keyword.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (index === -1) return keyword;

    return (
      <span>
        {keyword.substring(0, index)}
        <span style={{ color: '#ff6524' }}>
          {keyword.substring(index, index + searchTerm.length)}
        </span>
        {keyword.substring(index + searchTerm.length)}
      </span>
    );
  };

  const handleCheckboxChange = (e, keyword) => {
    if (e.target.checked) {
      onKeywordAdd(keyword);
    } else {
      onKeywordRemove(keyword);
    }
  };

  useEffect(() => {
    console.log('selectedKeywords in FindTable:', Array.from(selectedKeywords));
  }, [selectedKeywords]);

  return (
    <div
      className={`${styles.container} ${scrollable ? styles.scrollable : ''}`}
    >
      <div className={styles.table_box}>
        <table className={`${styles.table} ${styles.table_min}`}>
          <thead>
            <tr>
              <th style={{ width: '8%' }} className={styles.center}>
                추가
              </th>
              <th style={{ width: '22%' }} className={styles.left}>
                키워드
              </th>
              <th style={{ width: '19%' }} className={styles.diagnosis}>
                PC 검색량
                <button
                  className={styles.alingBtn}
                  onClick={() => handleSort('monthlyPcQcCnt')}
                >
                  <img src="/images/arrow.png" alt="화살표 이미지" />
                </button>
              </th>
              <th style={{ width: '19%' }} className={styles.diagnosis}>
                모바일 검색량
                <button
                  className={styles.alingBtn}
                  onClick={() => handleSort('monthlyMobileQcCnt')}
                >
                  <img src="/images/arrow.png" alt="화살표 이미지" />
                </button>
              </th>
              <th style={{ width: '18%' }} className={styles.diagnosis}>
                총 검색량
                <button
                  className={styles.alingBtn}
                  onClick={() => handleSort('totalCnt')}
                >
                  <img src="/images/arrow.png" alt="화살표 이미지" />
                </button>
              </th>
              <th style={{ width: '14%' }} className={styles.diagnosis}>
                경쟁정도
                <button
                  className={styles.alingBtn}
                  onClick={() => handleSort('compIdx')}
                >
                  <img src="/images/arrow.png" alt="화살표 이미지" />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.slice(0, rowsToShow).map((item, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>
                  <input
                    className={styles.keywordCheckBtn}
                    type="checkbox"
                    checked={selectedKeywords.has(item.relKeyword)} // 이 부분 확인
                    onChange={(e) => handleCheckboxChange(e, item.relKeyword)}
                  />
                </td>
                <td className={`${styles.left} ${styles.tableKeyword}`}>
                  {highlightSearchTerm(item.relKeyword)}
                </td>
                <td className={`${styles.diagnosis} ${styles.truncateText}`}>
                  {item.monthlyPcQcCnt.toLocaleString()}
                </td>
                <td className={`${styles.diagnosis} ${styles.truncateText}`}>
                  {item.monthlyMobileQcCnt.toLocaleString()}
                </td>
                <td className={styles.diagnosis}>
                  {item.totalCnt.toLocaleString()}
                </td>
                <td className={styles.diagnosis}>{item.compIdx}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.search_data.length > rowsToShow && ( // 더 불러올 데이터가 있는 경우에만 "더보기" 버튼을 보여줍니다.
        <div className={styles.btnContainer}>
          <button onClick={handleLoadMore} className={styles.loadMoreButton}>
            더 보기
          </button>
        </div>
      )}
    </div>
  );
}

export default React.memo(FindTable);
