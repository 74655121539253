import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Dot,
} from 'recharts';
import useWindowSize from '../../hooks/useWindowSize';

function formatData(rawData, period) {
  if (
    !rawData ||
    !rawData.graph ||
    !rawData.graph[period] ||
    !Array.isArray(rawData.graph[period]) ||
    rawData.graph[period].length === 0
  ) {
    return [];
  }

  let periodData = rawData.graph[period];
  let endDate = new Date(periodData[0]?.date || rawData.date_list[0]);
  let startDate = new Date(endDate);

  if (period === 'daily') startDate.setDate(endDate.getDate() - 11);
  else if (period === 'weekly') startDate.setDate(endDate.getDate() - 77);
  else if (period === 'monthly') startDate.setMonth(endDate.getMonth() - 11);

  let formattedData = [];
  for (let i = 0; i < 12; i++) {
    const dateStr = startDate.toISOString().slice(0, 10);
    const entry = periodData.find((e) => e.date === dateStr) || {
      date: dateStr,
      rank: null,
    };

    formattedData.push({
      date: entry.date,
      rank: entry.rank,
    });

    if (period === 'daily') startDate.setDate(startDate.getDate() + 1);
    else if (period === 'weekly') startDate.setDate(startDate.getDate() + 7);
    else if (period === 'monthly') startDate.setMonth(startDate.getMonth() + 1);
  }

  return formattedData;
}
// 주차 계산을 위한 함수
function getMonthWeek(date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const weekOfMonth = Math.ceil(
    (date.getDate() + firstDayOfMonth.getDay()) / 7,
  );
  return `${date.getMonth() + 1}월 ${weekOfMonth}주`;
}

// Custom Tooltip content
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: 'white',
          borderRadius: '6px',
          boxShadow: ' 0px 0px 5px rgba(0, 0, 0, 0.2)',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          color: '#555555',
        }}
      >
        <p
          style={{ fontWeight: '500', fontSize: '14px' }}
        >{`${payload[0].payload.date.replace(/-/g, '.')} 기준`}</p>
        <p style={{ fontWeight: '500' }}>
          {payload[0].value === 300
            ? '순위 밖'
            : payload[0].value !== null
            ? `${payload[0].value}위`
            : 'No Data'}
        </p>
      </div>
    );
  }
  return null;
};

function RankingLineChart({ rawData, period }) {
  const windowSize = useWindowSize();
  const [chartConfig, setChartConfig] = useState({
    xAxisPadding: { left: 40, right: 40 },
    tickFontSize: '14px',
  });

  useEffect(() => {
    // 화면 크기에 따라 XAxis의 패딩 값을 조정합니다.
    if (windowSize < 576) {
      setChartConfig({
        xAxisPadding: { left: 20, right: 20 },
        tickFontSize: '12px',
      });
    } else {
      setChartConfig({
        xAxisPadding: { left: 40, right: 40 },
        tickFontSize: '14px',
      });
    }
  }, [windowSize]); // windowSize가 바뀔 때마다 이 effect를 실행합니다.

  const data = formatData(rawData, period);

  // 가장 낮은 순위와 가장 높은 순위를 찾는 함수
  const findBounds = (data) => {
    const validRanks = data.map((d) => d.rank).filter((rank) => rank !== null);
    const hasRankOutside = validRanks.includes(300); // 300위가 있는지 확인
    return {
      min: Math.min(...validRanks),
      max: Math.max(...validRanks),
      hasRankOutside, // 이 값을 반환 객체에 추가합니다.
    };
  };

  // 순위에 맞게 Y축 틱을 생성하는 함수
  const generateTicks = (min, max, hasRankOutside) => {
    const ticks = [];
    for (let i = min; i <= max; i++) {
      ticks.push(i);
    }
    if (hasRankOutside) {
      ticks.push(300); // "순위 밖"을 위한 300 추가
    }
    return ticks;
  };

  // 가장 낮은 순위와 가장 높은 순위를 바탕으로 도메인과 틱 설정
  const { min, max, hasRankOutside } = findBounds(data);
  const yAxisTicks = generateTicks(min, max, hasRankOutside);

  // 틱 포맷터 함수
  const yAxisTickFormatter = (value) => {
    if (value === 300 && hasRankOutside) {
      return '순위 밖';
    }
    return `${value}위`;
  };
  return (
    <ResponsiveContainer width="100%" height={320}>
      <LineChart
        key={Date.now()}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 10,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={(value) => {
            const date = new Date(value);
            if (period === 'daily') return value.slice(-5).replace(/-/g, '.'); // YYYY-MM-DD => MM.DD
            if (period === 'weekly') return getMonthWeek(date);
            if (period === 'monthly')
              return value.slice(2, 7).replace(/-/g, '.'); // YYYY-MM => YY.MM
            return value; // 기본값 반환
          }}
          tickLine={false}
          padding={chartConfig.xAxisPadding}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
        />
        <YAxis
          domain={[min, max]}
          ticks={yAxisTicks}
          reversed
          tickLine={false}
          padding={{ top: 20, bottom: 20 }}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
          tickFormatter={yAxisTickFormatter}
        />

        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Line
          type="linear"
          dataKey="rank"
          stroke="#ff6524"
          activeDot={{ r: 6, fill: '#e75719' }}
          dot={(props) =>
            props.payload.rank !== null ? (
              <Dot {...props} fill="#ff6524" />
            ) : null
          } // hide dot when rank is null
          connectNulls={true}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default RankingLineChart;
