import React, { useState, useEffect } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  StackedBarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Dot,
} from 'recharts';
import useWindowSize from '../../hooks/useWindowSize';

function formatData(rawData, period) {
  if (
    !rawData ||
    !Array.isArray(rawData[period]) ||
    rawData[period].length === 0
  ) {
    return [];
  }

  if (period === 'weekly') {
    // rawData[period] 배열을 slice()로 복사하고 sort()로 날짜 기준 오름차순 정렬
    const sortedWeeklyData = rawData[period]
      .slice()
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    return sortedWeeklyData.map((entry) => ({
      date: entry.date,
      // rank가 0이면 null을 할당하여 차트에서 선이 그려지지 않도록 함
      rank: entry.rank === 0 ? null : entry.rank,
      index: entry.index,
    }));
  }

  // 'daily' 및 'monthly' 데이터의 경우 날짜 계산 및 기본값 설정
  let periodData = rawData[period];
  let endDate = new Date(periodData[0]?.date);
  let startDate = new Date(endDate);

  // 디버깅 콘솔
  console.log('Raw Data:', rawData);
  console.log('Period:', period);

  if (period === 'daily') startDate.setDate(endDate.getDate() - 11);
  else if (period === 'monthly') startDate.setMonth(endDate.getMonth() - 11);

  let formattedData = [];
  for (let i = 0; i < 12; i++) {
    const dateStr = startDate.toISOString().slice(0, 10);
    const entry = periodData.find((e) => e.date === dateStr) || {
      date: dateStr,
      rank: null,
      high_rank: null,
    };

    formattedData.push({
      date: entry.date,
      rank: entry.rank,
      // 'high_rank' 필드는 현재 사용되지 않으므로 포함시키지 않음
    });

    if (period === 'daily') startDate.setDate(startDate.getDate() + 1);
    else if (period === 'monthly') startDate.setMonth(startDate.getMonth() + 1);
  }

  return formattedData;
}

const CustomTooltipForRank = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: 'white',
          borderRadius: '6px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          color: '#555555',
        }}
      >
        <p
          style={{ fontWeight: '500', fontSize: '14px' }}
        >{`${payload[0].payload.date.replace(/-/g, '.')} 기준`}</p>
        <p style={{ fontWeight: '500' }}>
          블로그 최고 순위 : {payload[0].payload.rank}위
        </p>
      </div>
    );
  }
  return null;
};

function ReviewRankChart({ rawData, period }) {
  const windowSize = useWindowSize();
  const [chartConfig, setChartConfig] = useState({
    xAxisPadding: { left: 40, right: 40 },
    tickFontSize: '14px',
  });

  useEffect(() => {
    // 화면 크기에 따라 XAxis의 패딩 값을 조정합니다.
    if (windowSize < 576) {
      setChartConfig({
        xAxisPadding: { left: 10, right: 10 },
        tickFontSize: '12px',
      });
    } else {
      setChartConfig({
        xAxisPadding: { left: 40, right: 40 },
        tickFontSize: '14px',
      });
    }
  }, [windowSize]); // windowSize가 바뀔 때마다 이 effect를 실행합니다.

  const data = formatData(rawData, period);

  return (
    <ResponsiveContainer width="100%" height={240}>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis
          dataKey={period === 'weekly' ? 'index' : 'date'}
          tickFormatter={(value) => {
            const date = new Date(value);
            if (period === 'daily') return value.slice(-5).replace(/-/g, '.');
            if (period === 'weekly') {
              return value; // 주간 데이터의 경우 'index' 값 그대로 표시
            }
            if (period === 'monthly')
              return value.slice(2, 7).replace(/-/g, '.');
            return value;
          }}
          tickLine={false}
          padding={chartConfig.xAxisPadding}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
          textAnchor="middle"
        />

        {/* YAxis for rank */}
        <YAxis
          domain={[1, 100]}
          ticks={[100, 80, 60, 40, 20, 1]}
          reversed
          tickLine={false}
          padding={{ top: 10, bottom: 20 }}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
        />

        <Tooltip content={<CustomTooltipForRank />} cursor={false} />

        {/* Line for rank */}
        <Line
          type="monotone"
          dataKey="rank"
          stroke="#ff6524"
          activeDot={{ r: 6, fill: '#e75719' }}
          // rank가 null이 아닐 때만 Dot을 렌더링합니다.
          dot={(props) =>
            props.payload.rank != null ? (
              <Dot {...props} fill="#ff6524" />
            ) : null
          }
          // rank가 null일 때 선이 연결되지 않도록 합니다.
          connectNulls={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ReviewRankChart;
