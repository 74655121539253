import React from 'react';
import styles from './NotFound.module.scss';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.logo}>마케차이즈</h1>
        <p className={styles.text}>존재하지 않는 페이지입니다.</p>
        <p className={styles.nav} onClick={() => navigate('/')}>
          홈페이지로 가기
        </p>
      </div>
    </div>
  );
}

export default NotFound;
