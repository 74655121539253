import React, { useEffect, useState } from 'react';
import styles from './CompareModal.module.scss';

import { Spinner } from '@chakra-ui/react';

function CompareModal({ isOpen, onClose, taskStatus, storeData }) {
  if (!isOpen || !taskStatus) {
    return null;
  }

  const storeCount =
    taskStatus && taskStatus.result ? Object.keys(taskStatus.result).length : 0;
  const storeWidth = `${(100 - 15) / storeCount}%`;

  // if (!taskKeywordMapping) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <div className={styles.headerTitle}>
          <img src="/images/compare.png" alt="순위 비교 아이콘" />
          <span>키워드별 플레이스 순위 비교</span>
        </div>

        <button className={styles.closeBtn} onClick={onClose}>
          X
        </button>
      </div>

      <div className={styles.modalBody}>
        <div className={styles.detail}>
          {(taskStatus?.state === 'PENDING' ||
            taskStatus?.state === 'STARTED') && (
            <div className={styles.message}>
              <Spinner
                color="#ff6524"
                size="xl"
                speed="0.65s"
                emptyColor="gray.200"
                thickness="4px"
              />
              <p className={styles.messageText}>
                플레이스와 키워드 별로 순위 비교 중입니다.
                <br />
                1~2분 정도 소요됩니다.
              </p>
            </div>
          )}
          {taskStatus?.state === 'SUCCESS' && taskStatus?.result && (
            <div className={styles.compareTableContainer}>
              <table className={styles.compareTable}>
                <thead>
                  <tr style={{ height: '180px' }}>
                    <th style={{ width: '15%' }}>플레이스 정보</th>
                    {Object.keys(taskStatus.result).map((storeName) => (
                      <th
                        style={{ width: storeWidth }}
                        className={styles.storeName}
                        key={storeName}
                      >
                        <div className={styles.storeLayout}>
                          <div className={styles.imgBox}>
                            {storeData[storeName]?.thumUrl ? (
                              <img
                                src={storeData[storeName]?.thumUrl}
                                alt={`${storeName} 이미지`}
                              />
                            ) : (
                              <p className={styles.noImage}>
                                등록된 이미지가 없습니다.
                              </p>
                            )}
                          </div>
                          {storeName}
                        </div>
                      </th>
                    ))}
                  </tr>
                  <tr style={{ height: '30px' }}>
                    <th>키워드</th>
                    {Object.keys(taskStatus.result).map((storeName) => (
                      <th key={storeName}>플레이스 순위</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {taskStatus.result &&
                  Object.keys(taskStatus.result).length > 0
                    ? taskStatus.result[Object.keys(taskStatus.result)[0]].map(
                        (keywordObj) => {
                          const keyword = Object.keys(keywordObj)[0];
                          return (
                            <tr key={keyword} style={{ height: '120px' }}>
                              <th className={styles.keyword}>{keyword}</th>
                              {Object.keys(taskStatus.result).map(
                                (storeName) => {
                                  const keywordData = taskStatus.result[
                                    storeName
                                  ].find((k) => Object.keys(k)[0] === keyword)[
                                    keyword
                                  ];
                                  return (
                                    <td key={storeName} style={{ padding: 0 }}>
                                      <table
                                        className={styles.innerTable}
                                        style={{ height: '120px' }}
                                      >
                                        <thead>
                                          <tr style={{ height: '30px' }}>
                                            <th>
                                              요즘 뜨는
                                              <span className={styles.subTitle}>
                                                (메인)
                                              </span>
                                            </th>
                                            <th>많이 찾는</th>
                                            <th>리뷰 많은</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            style={{
                                              height: '90px',
                                              fontSize: '24px',
                                              fontWeight: '700',
                                            }}
                                          >
                                            <td className={styles.hotMain}>
                                              {keywordData['요즘 뜨는']}
                                            </td>
                                            <td>{keywordData['많이 찾는']}</td>
                                            <td>{keywordData['리뷰 많은']}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  );
                                },
                              )}
                            </tr>
                          );
                        },
                      )
                    : null}
                </tbody>
              </table>
            </div>
          )}
          {taskStatus?.state === 'FAILURE' && (
            <div className={styles.message}>{taskStatus.status}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompareModal;
