import axios from 'axios';
import Cookies from 'js-cookie';

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

// 전역 함수를 설정합니다.
let showGlobalModal = () => {};

export const setGlobalModalHandler = (handler) => {
  showGlobalModal = handler;
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // 401 에러가 발생했지만 이미 재시도를 한 경우이거나,
    // '/user/login/verify/' 엔드포인트에서 발생한 오류인 경우
    // 재시도를 하지 않습니다.
    if (
      error.response.status === 401 &&
      (originalRequest._retry ||
        originalRequest.url.includes('user/login/verify/'))
    ) {
      // 리프레시 토큰이 만료된 경우, 로그아웃 처리를 진행합니다.
      if (error.response.data.detail === 'Invalid or expired refresh token') {
        Cookies.remove('access');
        Cookies.remove('refresh_token');
        localStorage.removeItem('username');
        showGlobalModal(); // 모달 표시

        return Promise.reject(error);
      }

      // 다른 경우의 401 오류 처리는 여기서 진행합니다.
    }

    // 액세스 토큰이 만료되거나 없을 경우 재시도를 시도합니다.
    if (error.response.status === 401 && !originalRequest._retry) {
      const accessToken = Cookies.get('access');

      // 쿠키에 액세스 토큰이 없으면 재시도하지 않습니다.
      if (!accessToken) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;

      try {
        const verifyResponse = await instance.post('/user/login/verify/', {
          token: accessToken,
        });

        const { access } = verifyResponse.data;
        Cookies.set('access', access);
        originalRequest.headers['Authorization'] = `Bearer ${access}`;
        return instance(originalRequest);
      } catch (verifyError) {
        // 리프레시 토큰 관련 오류는 위에서 이미 처리했으므로, 여기서는 추가적인 처리가 필요하지 않습니다.
        return Promise.reject(verifyError);
      }
    }

    // 다른 모든 오류를 잡습니다.
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(async (config) => {
  // 로그인, 회원가입, 로그아웃, 로그인 검증 API가 아닌 경우에만 Authorization 헤더를 추가합니다.
  if (
    !config.url.endsWith('user/login/') &&
    !config.url.endsWith('user/register/') &&
    !config.url.endsWith('user/logout/') &&
    !config.url.endsWith('user/login/verify/')
  ) {
    const token = Cookies.get('access');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  // 모든 요청에 CSRF 토큰을 추가합니다.
  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }

  return config;
});

export function userSignup({
  username,
  name,
  password,
  email,
  phone_number,
  store_name,
}) {
  return instance.post('user/register/', {
    username,
    name,
    password,
    email,
    phone_number,
    store_name,
  });
}

export function userLogin({ username, password }) {
  return instance
    .post('user/login/', {
      username,
      password,
    })
    .then((response) => {
      Cookies.set('access', response.data.data.access, {
        // domain: '.marketchise.com',
        // path: '/',
        // secure: true,
        // sameSite: 'none',
      });
      localStorage.setItem('username', username);
      console.log(`You have logged in as ${username}.`);
      return username;
    })
    .catch((error) => {
      console.error(`Error while logging in: ${error}`);
      throw error;
    });
}

export async function userLogout() {
  try {
    await instance.post('user/logout/');
    Cookies.remove('access', {
      // domain: '.marketchise.com',
      // path: '/',
      // secure: true,
      // sameSite: 'none',
    });
    localStorage.removeItem('username');
    Cookies.remove('refresh_token');
    // 로그아웃 후 로그인 페이지로 리다이렉트 합니다.
    window.location.href = '/login';
  } catch (error) {
    console.error(`Error while logging out: ${error}`);
    throw error;
  }
}

export function getUserStoreList(category) {
  return instance
    .get(`user/${category}/`)
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getUserStoreList: ${error}`);
      throw error;
    });
}

export function deleteStore(category, storeId) {
  return instance
    .delete(`${category}/storedetail/`, {
      params: {
        store_id: storeId.toString(), // storeId를 문자열로 변환합니다.
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while deleteStore: ${error}`);
      throw error;
    });
}

// 엑셀 다운로드
export const downloadExcel = async (tag, storeId) => {
  try {
    const response = await instance.get(`/log/excel/${tag}/`, {
      params: { store_id: storeId },
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return { success: true };
  } catch (error) {
    console.error('Error downloading the file:', error);
    return { success: false, error };
  }
};

// 순위추적
export function getStoreList(searchTerm, address) {
  return instance
    .get('keyword/store/', {
      params: {
        q: searchTerm,
        // 'address'가 정의되지 않았거나 null인 경우, 빈 문자열로 기본 설정됩니다.
        region: address || ' ',
      },
    })
    .then((response) => response.data); // 서버 응답의 데이터를 반환합니다.
}

export function postStoreDetail(store) {
  return instance
    .post('keyword/storedetail/', store)
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while postStoreDetail: ${error}`);
      throw error;
    });
}

export function getStoreDetail({ storeName, storeId }) {
  return instance
    .get('keyword/storedetail/', {
      params: {
        name: storeName,
        store_id: storeId,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getStoreDetail: ${error}`);
      throw error;
    });
}

export function putKeywordList({ storeId, keywords }) {
  return instance
    .put('keyword/keyword/', {
      store_id: storeId,
      keywords: keywords,
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while putKeywordList: ${error}`);
      throw error;
    });
}

export function postKeywordLog(storeId) {
  return instance
    .post('log/keyword/', { store_id: storeId })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while postKeywordLog: ${error}`);
      throw error;
    });
}

export function getTaskStatus(task_ids) {
  return instance
    .get('log/task-status/', {
      params: {
        task_ids,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getTaskStatus: ${error}`);
      throw error;
    });
}

export function getKeywordLog({ storeId, date = ' ' }) {
  // default to ' ' if no date is provided
  return instance
    .get('log/keyword/', {
      params: {
        store_id: storeId,
        date,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getKeywordLog: ${error}`);
      throw error;
    });
}

export function putMainKeyword({ storeName, keyword, storeId }) {
  return instance
    .put('keyword/keyword/main/', {
      name: storeName,
      store_id: storeId,
      keyword: keyword,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while putMainKeyword: ${error}`);
      throw error;
    });
}

// 리뷰 분석
export function getStoreListAnalyze(searchTerm, address) {
  return instance
    .get('review/store/', {
      params: {
        q: searchTerm,
        // 'address'가 정의되지 않았거나 null인 경우, 빈 문자열로 기본 설정됩니다.
        region: address || ' ',
      },
    })
    .then((response) => response.data); // 서버 응답의 데이터를 반환합니다.
}

export function postStoreDetailAnalyze(store) {
  return instance
    .post('review/storedetail/', store)
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while postStoreDetail: ${error}`);
      throw error;
    });
}

export function getStoreDetailAnalyze({ storeName, storeId }) {
  return instance
    .get('review/storedetail/', {
      params: {
        name: storeName,
        store_id: storeId,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getStoreDetail: ${error}`);
      throw error;
    });
}

export function putKeywordListAnalyze({ storeId, keywords }) {
  return instance
    .put('review/keyword/', {
      store_id: storeId,
      keywords: keywords,
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while putKeywordList: ${error}`);
      throw error;
    });
}

export function postKeywordLogAnalyze(storeId) {
  return instance
    .post('log/review/', { store_id: storeId })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while postKeywordLog: ${error}`);
      throw error;
    });
}

export function getKeywordLogAnalyze({ storeId, date = ' ' }) {
  return instance
    .get('log/review/', {
      params: {
        store_id: storeId,
        date,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getKeywordLog: ${error}`);
      throw error;
    });
}

export function getReviewChart({ storeId, date = ' ', keyword }) {
  return instance
    .get(`log/review/${storeId}/${keyword}/`, {
      params: {
        date,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getReviewChart: ${error}`);
      throw error;
    });
}

// 리뷰 진단
export function getStoreListDiagnosis(searchTerm, address) {
  return instance
    .get('blog/store/', {
      params: {
        q: searchTerm,
        // 'address'가 정의되지 않았거나 null인 경우, 빈 문자열로 기본 설정됩니다.
        region: address || ' ',
      },
    })
    .then((response) => response.data); // 서버 응답의 데이터를 반환합니다.
}

export function postStoreDetailDiagnosis(store) {
  return instance
    .post('blog/storedetail/', store)
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while postStoreDetail: ${error}`);
      throw error;
    });
}

export function getStoreDetailDiagnosis({ storeName, storeId }) {
  return instance
    .get('blog/storedetail/', {
      params: {
        name: storeName,
        store_id: storeId,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getStoreDetail: ${error}`);
      throw error;
    });
}

export function postBlogLog(storeId, blogUrl) {
  return instance
    .post('log/blog/', { store_id: storeId, link: blogUrl })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while postBlogLog: ${error}`);
      throw error;
    });
}

export function getBlogLog({ storeId }) {
  return instance
    .get('log/blog/', {
      params: {
        store_id: storeId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getBlogLog: ${error}`);
      throw error;
    });
}

export function deleteBlogLog({ storeId }, blogLinks) {
  return instance
    .delete('log/blog/', {
      params: {
        store_id: storeId,
      },
      data: {
        // 본문에 link 정보를 넣습니다.
        links: blogLinks,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while deleteBlogLog: ${error}`);
      throw error;
    });
}

export function postDiagnosis(storeId, link) {
  return instance
    .post('log/blog/diagnosis/', { store_id: storeId, link: link })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while postDiagnosis: ${error}`);
      throw error;
    });
}

// 키워드 찾기
export function findKeyword(searchTerm) {
  return instance
    .get('keyword/findkeyword/', {
      params: {
        keyword: searchTerm,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while findKeyword: ${error}`);
      throw error;
    });
}

export function getMyKeyword() {
  return instance
    .get('user/findkeyword/')
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getMyKeyword: ${error}`);
      throw error;
    });
}

export function addMyKeyword(keyword) {
  return instance
    .post('user/findkeyword/', {
      keywords: keyword,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while addMyKeyword: ${error}`);
      throw error;
    });
}

export function deleteMyKeyword(keyword) {
  return instance
    .delete('user/findkeyword/', {
      params: {
        keywords: keyword,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while deleteMyKeyword: ${error}`);
      throw error;
    });
}

// 순위 비교

export function getCompareFindKeyword(searchKeyword) {
  return instance
    .get('competition/findkeyword/', {
      params: {
        keyword: searchKeyword,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getCompareFindKeyword: ${error}`);
      throw error;
    });
}

export function getCompareFindStore(searchStore, searchAddress) {
  return instance
    .get('competition/findstore/', {
      params: {
        q: searchStore,
        // 'address'가 정의되지 않았거나 null인 경우, 빈 문자열로 기본 설정됩니다.
        region: searchAddress || ' ',
      },
    })
    .then((response) => response.data); // 서버 응답의 데이터를 반환합니다.
}

export function getCompareKeyword() {
  return instance
    .get('competition/keyword/')
    .then((response) => {
      console.log('getCompareKeyword response:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error(`Error while getCompareKeyword: ${error}`);
      throw error;
    });
}

export function addCompareKeyword(keyword) {
  return instance
    .post('competition/keyword/', {
      keywords: keyword,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while addCompareKeyword: ${error}`);
      throw error;
    });
}

export function deleteCompareKeyword(keyword) {
  return instance
    .delete('competition/keyword/', {
      params: {
        keywords: keyword,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while deleteCompareKeyword: ${error}`);
      throw error;
    });
}

export function getCompareStore() {
  return instance
    .get('competition/store/')
    .then((response) => {
      console.log('getCompareKeyword response:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error(`Error while getCompareStore: ${error}`);
      throw error;
    });
}

export function addCompareStore(store) {
  return instance
    .post('competition/store/', {
      id: store.id,
      name: store.name,
      thumUrl: store.thumUrl || ' ',
      reviewCount: store.reviewCount,
      placeReviewCount: store.placeReviewCount,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while addCompareStore: ${error}`);
      throw error;
    });
}

export function deleteCompareStore(storeId) {
  return instance
    .delete('competition/store/', {
      params: {
        id: storeId,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while deleteCompareStore: ${error}`);
      throw error;
    });
}

export function getCompare() {
  return instance
    .get('competition/competition/')
    .then((response) => {
      console.log('getCompareKeyword response:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error(`Error while getCompare: ${error}`);
      throw error;
    });
}

// 마케팅 솔루션

export function getStoreListSolution(searchTerm, address) {
  return instance
    .get('solution/store/', {
      params: {
        q: searchTerm,
        // 'address'가 정의되지 않았거나 null인 경우, 빈 문자열로 기본 설정됩니다.
        region: address || ' ',
      },
    })
    .then((response) => response.data); // 서버 응답의 데이터를 반환합니다.
}

export function postStoreDetailSolution(store) {
  return instance
    .post('solution/storedetail/', store)
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while postStoreDetail: ${error}`);
      throw error;
    });
}

export function getStoreDetailSolution({ storeName, storeId }) {
  return instance
    .get('solution/storedetail/', {
      params: {
        name: storeName,
        store_id: storeId,
      },
    })
    .then((response) => response.data) // Return data from server response.
    .catch((error) => {
      console.error(`Error while getStoreDetail: ${error}`);
      throw error;
    });
}

export function postStoreSolution(storeId) {
  return instance
    .post('solution/solution/', { store_id: storeId })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while postStoreSolution: ${error}`);
      throw error;
    });
}

export function getStoreSolution({ storeId, date = ' ' }) {
  // default to ' ' if no date is provided
  return instance
    .get('solution/solution/', {
      params: {
        store_id: storeId,
        date,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Error while getStoreSolution: ${error}`);
      throw error;
    });
}
