import React, { useState, useEffect } from 'react';
import styles from './DiagonosisTable.module.scss';
import { Spinner, Tooltip } from '@chakra-ui/react';
import DiagnosisModal from '../DiagnosisModal/DiagnosisModal';
import useStripHtml from '../../hooks/useStripHtml';

function DiagonosisTable({
  logData,
  onDiagnosisClick,
  storeId,
  loadingTaskIds,
  data,
  selectedBlogs,
  setSelectedBlogs,
}) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDiagnosticData, setSelectedDiagnosticData] = useState(null);

  const stripHtml = useStripHtml();

  const formatDate = (date) => {
    return date.replace(/^20/, '').replace(/-/g, '.');
  };

  const handleOpenModal = (blogData) => {
    setSelectedDiagnosticData(blogData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDiagnosticData(null);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allBlogLinks = logData?.results.map((item) => item.blog_link);
      setSelectedBlogs(new Set(allBlogLinks));
    } else {
      setSelectedBlogs(new Set());
    }
  };

  const handleSelectBlog = (blogLink) => {
    const updatedSelectedBlogs = new Set(selectedBlogs);
    if (updatedSelectedBlogs.has(blogLink)) {
      updatedSelectedBlogs.delete(blogLink);
    } else {
      updatedSelectedBlogs.add(blogLink);
    }
    setSelectedBlogs(updatedSelectedBlogs);
  };

  if (!selectedBlogs) {
    return null; // 또는 로딩 스피너, 경고 메시지 등을 렌더링
  }

  return (
    <div className={styles.container}>
      <div className={styles.table_box}>
        <table className={`${styles.table} ${styles.table_min}`}>
          <thead>
            <tr>
              <th
                style={{ width: '4%', verticalAlign: 'middle' }}
                className={styles.center}
              >
                <input
                  className={styles.keywordCheckBtn}
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={logData?.results.length === selectedBlogs.size}
                />
              </th>
              <th style={{ width: '10%' }} className={styles.left}>
                게시일자
              </th>
              <th style={{ width: '36%' }} className={styles.left}>
                제목
              </th>
              <th style={{ width: '17%' }} className={styles.diagnosis}>
                상위노출 키워드
              </th>
              <th style={{ width: '10%' }} className={styles.diagnosis}>
                누락여부
              </th>
              <th style={{ width: '12%' }} className={styles.diagnosis}>
                최적화 점수
              </th>
              <th style={{ width: '11%' }} className={styles.diagnosis}>
                상세진단
              </th>
            </tr>
          </thead>
          <tbody>
            {logData?.results.map((item, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: 'middle' }}>
                  <input
                    className={styles.keywordCheckBtn}
                    type="checkbox"
                    onChange={() => handleSelectBlog(item.blog_link)}
                    checked={selectedBlogs.has(item.blog_link)}
                  />
                </td>
                <td className={styles.left}>
                  {formatDate(item.blog_post_date)}
                </td>
                <td
                  className={`${styles.left} ${styles.truncateText} ${styles.linkHover}`}
                >
                  <Tooltip
                    label={stripHtml(item.blog_title)}
                    placement="top"
                    hasArrow
                    padding="10px"
                  >
                    <a
                      href={item.blog_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {stripHtml(item.blog_title)}
                    </a>
                  </Tooltip>
                </td>
                <td className={`${styles.diagnosis} ${styles.truncateText}`}>
                  {item.is_calculated
                    ? item.high_rank_keyword === '상위 노출 키워드가 없습니다.'
                      ? '-'
                      : item.high_rank_keyword
                    : '-'}
                </td>
                <td
                  className={`${styles.diagnosis} ${
                    item.is_calculated
                      ? item.is_omitted
                        ? styles.normal
                        : styles.omitted
                      : ''
                  }`}
                >
                  {item.is_calculated
                    ? item.is_omitted
                      ? '정상'
                      : '누락'
                    : '-'}
                </td>

                <td className={styles.diagnosis}>
                  {item.is_calculated
                    ? `${item.blog_diagnostic.total_score}점`
                    : '-'}
                </td>
                <td className={styles.diagnosis}>
                  {loadingTaskIds.has(item.blog_log_num) ? (
                    <Spinner color="#ff6524" size="md" />
                  ) : item.is_calculated ? (
                    <button
                      className={styles.resultButton}
                      onClick={() => handleOpenModal(item)}
                    >
                      결과보기
                    </button>
                  ) : (
                    <button
                      className={styles.diagnoseButton}
                      onClick={() =>
                        onDiagnosisClick(
                          storeId,
                          item.blog_link,
                          item.blog_log_num,
                        )
                      }
                    >
                      상세진단
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && selectedDiagnosticData && data && (
        <DiagnosisModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          BlogData={selectedDiagnosticData}
          data={data}
        />
      )}
      {isModalOpen && (
        <div
          className={`${styles.overlay} ${isModalOpen ? styles.show : ''}`}
          onClick={handleCloseModal}
        />
      )}
    </div>
  );
}

export default DiagonosisTable;
