import React, { useState, useEffect } from 'react';
import styles from './Sidebar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.pathname.substring(1);

  const [showRankingSubMenu, setShowRankingSubMenu] = useState(false);
  const [showReviewSubMenu, setShowReviewSubMenu] = useState(false);
  const [selectedMainItem, setSelectedMainItem] = useState('');
  const [selectedSubItem, setSelectedSubItem] = useState('');

  useEffect(() => {
    // 로케이션 변경 시 서브 아이템 선택 상태 초기화
    // 특정 조건에 따라 초기화를 수행
    if (
      page.startsWith('find') ||
      page.startsWith('solution') ||
      page === '' ||
      page.startsWith('login')
    ) {
      setSelectedSubItem('');
    }

    if (page === '' || page.startsWith('login')) {
      setShowReviewSubMenu(false);
      setShowRankingSubMenu(false);
    }

    // 새로고침이나 첫 로딩 시 상태를 설정
    if (page.startsWith('ranking/trace')) {
      setSelectedMainItem('ranking');
      setSelectedSubItem('trace');
      setShowRankingSubMenu(true);
    } else if (page.startsWith('ranking/compare')) {
      setSelectedMainItem('ranking');
      setSelectedSubItem('compare');
      setShowRankingSubMenu(true);
    } else if (page.startsWith('review/analyze')) {
      setSelectedMainItem('review');
      setSelectedSubItem('analyze');
      setShowReviewSubMenu(true);
    } else if (page.startsWith('review/diagnosis')) {
      setSelectedMainItem('review');
      setSelectedSubItem('diagnosis');
      setShowReviewSubMenu(true);
    }
  }, [location.pathname, page]);

  const handleClickRanking = () => {
    setShowRankingSubMenu(!showRankingSubMenu);
    setSelectedMainItem('ranking');
  };

  const handleClickReview = () => {
    setShowReviewSubMenu(!showReviewSubMenu);
    setSelectedMainItem('review');
  };

  const handleClickSubMenuItem = (subItem) => {
    setSelectedSubItem(subItem);
  };

  const handleResetSubItem = () => {
    setSelectedSubItem('');
  };

  return (
    <div className={styles.sidebar}>
      <div
        className={styles.nav_logo}
        onClick={() => {
          navigate('/');
          handleResetSubItem();
        }}
      >
        <img src="/images/logo.png" alt="로고" />
      </div>
      <div className={styles.nav_header}>MENU</div>
      <ul className={styles.nav_list}>
        <li
          className={`${styles.nav_item} ${
            page.startsWith('find') ? styles.selected : ''
          }`}
          onClick={() => {
            navigate('find');
            handleResetSubItem();
          }}
        >
          <span>키워드 찾기</span>
        </li>
        <li
          className={`${styles.nav_item} ${
            page.startsWith('ranking/') ? styles.selected : ''
          }`}
          onClick={handleClickRanking}
        >
          <span>플레이스 순위 추적</span>
        </li>
        {showRankingSubMenu && (
          <ul
            className={`${styles.sub_nav_list} ${
              showRankingSubMenu ? styles.show : ''
            }`}
          >
            <li
              className={`${styles.sub_nav_item} ${
                selectedSubItem === 'trace' ? styles.selected : ''
              }`}
              onClick={() => {
                navigate('ranking/trace');
                handleClickSubMenuItem('trace');
              }}
            >
              순위 추적
            </li>
            <li
              className={`${styles.sub_nav_item} ${
                selectedSubItem === 'compare' ? styles.selected : ''
              }`}
              onClick={() => {
                navigate('ranking/compare');
                handleClickSubMenuItem('compare');
              }}
            >
              순위 비교
            </li>
          </ul>
        )}
        <li
          className={`${styles.nav_item} ${
            page.startsWith('review/') ? styles.selected : ''
          }`}
          onClick={handleClickReview}
        >
          <span>블로그 리뷰 분석</span>
        </li>
        {showReviewSubMenu && (
          <ul
            className={`${styles.sub_nav_list} ${
              showReviewSubMenu ? styles.show : ''
            }`}
          >
            <li
              className={`${styles.sub_nav_item} ${
                selectedSubItem === 'analyze' ? styles.selected : ''
              }`}
              onClick={() => {
                navigate('review/analyze');
                handleClickSubMenuItem('analyze');
              }}
            >
              상위 노출 분석
            </li>
            <li
              className={`${styles.sub_nav_item} ${
                selectedSubItem === 'diagnosis' ? styles.selected : ''
              }`}
              onClick={() => {
                navigate('review/diagnosis');
                handleClickSubMenuItem('diagnosis');
              }}
            >
              리뷰 진단
            </li>
          </ul>
        )}
        <li
          className={`${styles.nav_item} ${
            page.startsWith('solution') ? styles.selected : ''
          }`}
          onClick={() => {
            navigate('solution');
            handleResetSubItem();
          }}
        >
          <span>마케팅 솔루션</span>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
