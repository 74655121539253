import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

function LogoutModal({ isOpen, onClose }) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxW={['320px', '320px', '480px']}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
        <ModalBody style={{ textAlign: 'center' }}>
          세션이 만료되었습니다.
          <br />
          다시 로그인해 주세요.
        </ModalBody>
        <ModalFooter style={{ gap: '10px', marginTop: '10px' }}>
          <Button
            onClick={() => {
              onClose();
              window.location.href = '/login';
            }}
            color="white"
            backgroundColor="#ff6524"
            _hover={{
              backgroundColor: '#e75719',
            }}
          >
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default LogoutModal;
