import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './DiagnosisDetail.module.scss';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getStoreDetailDiagnosis,
  postBlogLog,
  getBlogLog,
  deleteBlogLog,
  postDiagnosis,
  getTaskStatus,
} from '../../api';
import DiagnosisStore from '../../components/DiagnosisStore/DiagnosisStore';
import DiagonosisTable from '../../components/DiagnosisTable/DiagnosisTable';
import BlogRegister from '../../components/BlogRegister/BlogRegister';
import FailureModal from '../../components/Modal/FailureModal';
import GuideModal from '../../components/Modal/GuideModal';

const MessageComponent = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
      fontWeight: 500,

      fontSize: '18px',
    }}
  >
    <p style={{ fontSize: '20px', marginBottom: '10px' }}>블로그 삭제</p>
    <p>
      선택한
      <span style={{ color: '#ff6524' }}> 블로그</span>를
    </p>
    <p>삭제하시겠습니까?</p>
  </div>
);

function DiagnosisDetail() {
  const { storeName, storeId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [loadingTaskIds, setLoadingTaskIds] = useState(new Set());
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedBlogs, setSelectedBlogs] = useState(new Set());

  const storeDetailQueryKey = useMemo(
    () => ['storeDetailDiagnosis', { storeName, storeId }],
    [storeName, storeId],
  );

  const { isLoading, isError, data, error } = useQuery(
    storeDetailQueryKey,
    () => getStoreDetailDiagnosis({ storeName, storeId }),
    { retry: false },
  );

  const {
    data: logData,
    isLoading: logLoading,
    isError: logError,
    error: logErrorMsg,
  } = useQuery(['getBlogLog', { storeId }], () => getBlogLog({ storeId }), {
    retry: false,
  });

  const diagnosisMutation = useMutation(
    ({ storeId, link }) => postDiagnosis(storeId, link),
    {
      // onSuccess와 onError 콜백은 필요에 따라 설정하세요.
    },
  );

  const handleDiagnosisClick = async (storeId, link, blogLogNum) => {
    const { task_id } = await diagnosisMutation.mutateAsync({ storeId, link });
    setLoadingTaskIds(new Set([...loadingTaskIds, blogLogNum]));

    const checkTaskStatus = async () => {
      const statusResponse = await getTaskStatus(task_id);
      const taskState = statusResponse[0].state;
      const taskStatus = statusResponse[0].status;

      if (taskState === 'SUCCESS') {
        setLoadingTaskIds((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(blogLogNum);
          return newSet;
        });
        queryClient.invalidateQueries('getBlogLog');
      } else if (taskState === 'FAILURE') {
        console.error(`Task failed: ${taskStatus}`);
        setErrorModalMessage(taskStatus); // 오류 메시지 설정
        setErrorModalOpen(true); // 모달을 띄웁니다
        setLoadingTaskIds((prevSet) => {
          const newSet = new Set(prevSet);
          newSet.delete(blogLogNum);
          return newSet;
        });
      } else {
        setTimeout(checkTaskStatus, 5000);
      }
    };

    checkTaskStatus();
  };

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  const deleteMutation = useMutation(
    ({ storeId, links }) => deleteBlogLog({ storeId }, links),
    {
      onSuccess: () => {
        // 성공 시 로직, 예를 들어 캐시를 업데이트하거나 UI를 변경할 수 있습니다.
        setSelectedBlogs(new Set());
        setIsDeleteModalOpen(false);
        queryClient.invalidateQueries('getBlogLog');
      },
      onError: (error) => {
        // 실패 시 로직, 예를 들어 모달을 띄우거나 에러 메시지를 표시할 수 있습니다.
        console.error('Failed to delete selected blogs:', error);
      },
    },
  );

  const handleDeleteSelected = () => {
    const selectedBlogLinks = Array.from(selectedBlogs).join(',');
    deleteMutation.mutate({ storeId: storeId, links: selectedBlogLinks });
  };

  if (isLoading || logLoading) {
    return <div>로딩중입니다...</div>;
  }

  if (isError) {
    console.error('An error occurred:', error);
    return <div>{error.message}</div>;
  }
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.topRight}>
          <button
            className={styles.returnBtn}
            onClick={() => navigate('/review/diagnosis')}
          >
            목록 돌아가기
          </button>
          <header>네이버 블로그 리뷰 검증 결과</header>
        </div>

        <div className={styles.topLeft}>
          <div>등록 상점 {data?.my_stores}/30</div>
          <div>등록 블로그 {logData ? logData.count : 0}/10</div>
        </div>
      </div>
      <div className={styles.contents}>
        <div className={styles.storeInfo}>
          <DiagnosisStore
            thumUrl={data.values[0].thumUrl}
            name={data.values[0].name}
            storeId={data.values[0].id}
            address={data.values[0].address}
            myKeywords={data.values[0].my_keywords}
            data={data}
          />
        </div>

        <BlogRegister storeId={storeId} />
        <div className={styles.btnContainer}></div>

        {logData && logData.results.length > 0 ? (
          <>
            <DiagonosisTable
              logData={logData}
              onDiagnosisClick={handleDiagnosisClick}
              storeId={storeId}
              loadingTaskIds={loadingTaskIds}
              data={data}
              selectedBlogs={selectedBlogs}
              setSelectedBlogs={setSelectedBlogs}
            />
            <div className={styles.deleteBtn}>
              <button
                className={`${styles.deleteBtn} ${
                  selectedBlogs.size > 0 ? styles.active : ''
                }`}
                onClick={() => setIsDeleteModalOpen(true)}
                disabled={selectedBlogs.size === 0}
              >
                선택한 블로그 삭제
              </button>
            </div>

            <GuideModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              MessageComponent={MessageComponent}
              onProceed={handleDeleteSelected}
            />
          </>
        ) : (
          <>
            <DiagonosisTable />
            <p className={styles.keyword_add_msg}>블로그를 추가해주세요.</p>
          </>
        )}
      </div>

      {/* 오류 모달 */}
      <FailureModal
        isOpen={isErrorModalOpen}
        onClose={handleCloseErrorModal}
        error={errorModalMessage}
      />
    </div>
  );
}

export default DiagnosisDetail;
