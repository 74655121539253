import React from 'react';
import styles from './MarketingScore.module.scss';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Progress } from '@chakra-ui/react';
import useWindowSize from '../../../hooks/useWindowSize';

function MarketingScore({ solutionData, storeData }) {
  const formattedDate = formatDate(solutionData.data.updated_at);

  function formatDate(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }

  // 줄바꿈 문자를 <br />로 변환하는 함수
  const convertNewlinesToBreaks = (text) => {
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const {
    total_result: {
      total_result,
      content_result,
      review_result,
      connection_result,
    },
  } = solutionData;

  function CustomProgress({ value, color }) {
    const [windowWidth] = useWindowSize();
    const progressBarHeight = windowWidth < 576 ? '14px' : '20px';

    return (
      <Progress
        value={value}
        height={progressBarHeight}
        borderRadius="12px"
        sx={{
          bg: '#f1f1f1',
          border: '1px solid #999999',
          '> div': {
            bg: color || '#ff6524', // 지정된 색상이 없으면 기본 색상 사용
          },
        }}
      />
    );
  }

  function TextDes({ children }) {
    return (
      <div className={styles.textDesWrapper}>
        <div className={styles.textDesIcon}>▶︎</div>
        <p className={styles.textDesText}>{children}</p>
      </div>
    );
  }

  function TextWithDash({ children }) {
    return (
      <div className={styles.textWithDashWrapper}>
        <div className={styles.textWithDashIcon}>-</div>
        <p className={styles.textWithDashText}>{children}</p>
      </div>
    );
  }

  const { data } = solutionData;
  // 콘텐츠 점수 관련 내용을 관리할 객체
  const contentInspectionContents = {
    photoCount: {
      ment: convertNewlinesToBreaks(data.photoCount.ment),
      des:
        data.photoCount.photocount >= 16
          ? `메뉴별, 매장사진 별로 정렬해주면 좋습니다.(15장 이상)`
          : `네이버 플레이스에서 추천하는 이미지 등록 수는 15장이에요. 메뉴별, 매장사진 별로 정렬해주면 좋습니다.`,
    },
    descriptionLength: {
      ment: convertNewlinesToBreaks(data.descriptionLength.ment),
      des:
        data.descriptionLength.descriptionLength >= 1501
          ? `지역키워드, 연관키워드 위주로 변경해주시면 더욱 좋습니다.(1500자 이상)`
          : `지역 키워드, 연관 키워드 위주로 1500자 이상 적어주시면 좋을 것 같아요.`,
    },
    menuCount: {
      ment: convertNewlinesToBreaks(data.menuCount.ment),
      des:
        data.menuCount.menuCount >= 7
          ? `메뉴 상세설명을 더욱 자세하게 작성해주시면 좋습니다.(7개 이상)`
          : `메뉴는 최소 7개 정도는 등록해 주시는 게 좋아요. 메뉴 상세설명은 필수입니다.`,
    },
    roadAddressLength: {
      ment: convertNewlinesToBreaks(data.roadAddressLength.ment),
      des:
        data.roadAddressLength.roadAddressLength >= 151
          ? `네비게이션, 건물 이름, 도보 몇분, 지하철역 키워드를 포함해주시면 좋습니다.(150자 이상)`
          : `네비게이션, 건물 이름, 도보 몇분, 지하철역 키워드를 포함해서 150자로 작성해주세요.`,
    },
  };

  // 리뷰 점수 관련 내용을 관리할 객체
  const reviewInspectionContents = {
    weeklyPhotoCount: {
      ment:
        solutionData?.weekly_review?.weeklyphotoCount?.ment1 || '데이터 없음',
      des:
        solutionData?.weekly_review?.weeklyphotoCount?.ment2 || '데이터 없음',
    },
    weeklyPlaceReviewCount: {
      ment:
        solutionData?.weekly_review?.weeklyplaceReviewCount?.ment1 ||
        '데이터 없음',
      des:
        solutionData?.weekly_review?.weeklyplaceReviewCount?.ment2 ||
        '데이터 없음',
    },
    weeklyReviewCount: {
      ment:
        solutionData?.weekly_review?.weeklyreviewCount?.ment1 || '데이터 없음',
      des:
        solutionData?.weekly_review?.weeklyreviewCount?.ment2 || '데이터 없음',
    },
    reviewKeywords: {
      ment: solutionData?.review_keywords?.ment1 || '데이터 없음',
      des: solutionData?.review_keywords?.ment2 || '데이터 없음',
    },
  };

  // 키워드 점수 관련 내용을 관리할 객체
  const keywordInspectionContents = {
    user_keywords: solutionData?.blog_keyword_result?.user_keywords || [],
    add_keywords: solutionData?.blog_keyword_result?.add_keywords || [],
  };

  // 연동 점수 관련 내용을 관리할 객체
  const connectionInspectionContents = {
    naverBooking_isConnected: {
      ment: convertNewlinesToBreaks(data.naverBooking_isConnected.ment),
      des: '예약 연동은 플레이스 점수에 아주 큰 영향을 줍니다.',
    },
    naverTalkTalk_isConnected: {
      ment: convertNewlinesToBreaks(data.naverTalkTalk_isConnected.ment),
      des: '톡톡 연동은 플레이스 점수에 아주 큰 영향을 줍니다.',
    },
    naverDelivery_isConnected: {
      ment: convertNewlinesToBreaks(data.naverDelivery_isConnected.ment),
      des: '배달 연동은 플레이스 점수에 아주 큰 영향을 줍니다.',
    },
    naverBlog_isConnected: {
      ment: convertNewlinesToBreaks(data.naverBlog_isConnected.ment),
      des: data.naverBlog_isConnected
        ? '매장 블로그 글 발행을 주 5회 진행할 시 플레이스에 아주 큰 영향을 줍니다.'
        : '우리 매장 브랜드 블로그를 개설해서 연동해 보는 것은 어떨까요?',
    },
    feed_isConnected: {
      ment: convertNewlinesToBreaks(
        data.feed_isConnected.feed_isConnected
          ? data.last_feed_date.ment
          : data.feed_isConnected.ment,
      ),
      des: data.feed_isConnected.feed_isConnected
        ? '한 달에 최소 1회는 새 소식을 올려 플레이스 체류시간과 플레이스 활성화 지수를 높여보세요.'
        : '피드가 연동되어 있지 않아요. 피드를 연동해 보는 것은 어떨까요?',
    },
  };

  // 각 점수 범위에 따른 뱃지 색상을 결정하는 함수를 정의.
  const getBadgeColor = (score) => {
    if (score >= 80) return '최상';
    if (score >= 60) return '양호';
    if (score >= 40) return '보통';
    if (score >= 20) return '위험';
    return '심각';
  };

  return (
    <div className={styles.container}>
      <div>종합 진단 평가</div>
      <div className={styles.entire}>
        <div className={styles.e_right}>
          이 플레이스의 진단 결과는
          <br />
          <span>보통</span> 입니다.
        </div>
        <div className={styles.e_center}>
          <CircularProgressbarWithChildren
            value={total_result}
            strokeWidth={5}
            styles={buildStyles({
              strokeLinecap: 'butt',
              textColor: 'black',
              pathColor: '#ff6524',
              trailColor: '#eeeeee',
            })}
          >
            <div className={styles.scoreLabel}>
              <strong>총점</strong>
            </div>
            <div className={styles.scoreValue}>{total_result}점</div>
            <div className={styles.scoreOutOf}>/100점</div>
          </CircularProgressbarWithChildren>
        </div>
        <div className={styles.e_left}>
          <div className={styles.e_left_badge}>
            {['최상', '양호', '보통', '위험', '심각'].map((label, index) => {
              const isOrange = getBadgeColor(total_result) === label;
              return (
                <div key={index} className={styles.badge}>
                  <img
                    src={
                      isOrange
                        ? '/images/badgeOrange.png'
                        : '/images/badgeGray.png'
                    }
                    alt={`${isOrange ? '주황' : '회색'} 뱃지 이미지`}
                  />
                  <span>{label}</span>
                </div>
              );
            })}
          </div>

          <div className={styles.e_left_update}>
            <p>마지막 업데이트</p>
            <p>{formattedDate}</p>
          </div>
        </div>
      </div>

      <div>항목 진단 평가</div>
      <div className={styles.detail}>
        <div className={styles.d_content}>
          <div className={styles.d_title}>콘텐츠</div>
          <div className={styles.d_score}>{content_result}점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={content_result} color="#ffc940" />
          </div>
        </div>
        <div className={styles.d_content}>
          <div className={styles.d_title}>리뷰</div>
          <div className={styles.d_score}>{review_result}점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={review_result} color="#ff6524" />
          </div>
        </div>
        <div className={styles.d_content}>
          <div className={styles.d_title}>연동</div>
          <div className={styles.d_score}>{connection_result}점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={connection_result} color="#59c4fa" />
          </div>
        </div>
      </div>

      <div>점검 필요 사항</div>
      <div className={styles.inspection}>
        <div className={`${styles.i_content} ${styles.box1}`}>
          <div className={styles.content}>
            <header>콘텐츠 점수</header>
            <p className={styles.headerDes}>
              플레이스의 본문 내용을 분석했어요.
            </p>
            {Object.keys(contentInspectionContents).map((key, index) => (
              <React.Fragment key={index}>
                <TextWithDash>
                  {contentInspectionContents[key].ment}
                </TextWithDash>
                <TextDes>{contentInspectionContents[key].des}</TextDes>
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* 리뷰 점수 부분 */}
        <div className={`${styles.i_content} ${styles.box2}`}>
          <div className={styles.content}>
            <header>리뷰 점수</header>
            <p className={styles.headerDes}>
              플레이스의 등록된 리뷰들을 분석했어요.
            </p>
            {Object.keys(reviewInspectionContents).map((key, index) => (
              <React.Fragment key={index}>
                <TextWithDash>
                  {reviewInspectionContents[key].ment}
                </TextWithDash>
                <TextDes>{reviewInspectionContents[key].des}</TextDes>
              </React.Fragment>
            ))}
            <div className={styles.keywordDetail}>키워드 점수</div>

            <div className={styles.userKeywords}>대표 키워드</div>
            <div className={styles.keyworddes}>
              사장님이 플레이스에서 선택한 대표키워드로 발행된 블로그가
              상위노출이 될 경우 플레이스 지수에 큰 영향을 줍니다.
            </div>
            {keywordInspectionContents.user_keywords.map((keyword, index) => (
              <React.Fragment key={index}>
                <TextWithDash>{keyword.ment1}</TextWithDash>
                <TextDes>{keyword.ment2}</TextDes>
              </React.Fragment>
            ))}
            <div className={styles.localKeywords}>지역 키워드</div>
            <div className={styles.keyworddes}>
              지역키워드는 고객들이 많이 검색해보는 키워드로, 해당 키워드로
              블로그 발행 및 상위노출이 필요합니다.
            </div>
            {keywordInspectionContents.add_keywords.map((keyword, index) => (
              <React.Fragment key={index}>
                <TextWithDash>{keyword.ment1}</TextWithDash>
                <TextDes>{keyword.ment2}</TextDes>
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* 연동 점수 부분 */}
        <div className={`${styles.i_content} ${styles.box3}`}>
          <div className={styles.content}>
            <header>연동 점수</header>
            <p className={styles.headerDes}>
              플레이스에 연동된 항목들을 분석했어요.
            </p>
            {Object.keys(connectionInspectionContents).map((key, index) => (
              <React.Fragment key={index}>
                <TextWithDash>
                  {connectionInspectionContents[key].ment}
                </TextWithDash>
                <TextDes>{connectionInspectionContents[key].des}</TextDes>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingScore;
