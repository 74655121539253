import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateContainer.module.scss';
import { BsCalendar } from 'react-icons/bs';
import { ko } from 'date-fns/locale';

const DateContainer = ({ logData, onDateChange, selectedDate }) => {
  // const [selectedDate, setSelectedDate] = useState('날짜'); // 선택된 날짜

  const availableDates = logData?.date_list.map((date) => new Date(date));

  // 날짜 형식 변환 함수 (e.g., '2023-08-13' -> '230813')
  const formatDateForAPI = (date) => {
    const year = date.substring(2, 4);
    const month = date.substring(5, 7);
    const day = date.substring(8, 10);

    return year + month + day;
  };

  const handleDateChange = (date) => {
    // 날짜 객체를 'yyyy-mm-dd' 형태의 문자열로 변환
    const calendarDate = date.toISOString().substring(0, 10);
    // console.log('formattedDate (yyyy-mm-dd):', calendarDate);

    // 필요한 형식('230813'과 같은 형식)으로 변환
    const dateForAPI = formatDateForAPI(calendarDate);
    // console.log('dateForAPI (yymmdd):', dateForAPI);

    // 변환된 날짜를 onDateChange에 전달
    onDateChange(calendarDate);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className={styles.calendarButton} onClick={onClick}>
      <span>{value}</span>
      <BsCalendar />
    </button>
  ));
  // console.log('selectedDate:', selectedDate);
  // console.log('new Date(selectedDate):', new Date(selectedDate));

  return (
    <div className={styles.dateContainer}>
      <DatePicker
        selected={(selectedDate && new Date(selectedDate)) || null}
        onChange={handleDateChange}
        includeDates={availableDates}
        customInput={<CustomInput />}
        locale={ko}
        dateFormat="yyyy.MM.dd"
      />
    </div>
  );
};
export default DateContainer;
