import React, { useState } from 'react';
import styles from './ReviewChart.module.scss';
import ReviewRankChart from '../Charts/ReviewRankChart';
import ReviewHighRankChart from '../Charts/ReviewHighRankChart';

function ReviewChart({ data, isLoading, chartKeyword }) {
  const [period, setPeriod] = useState('weekly');
  if (!chartKeyword) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>메인 키워드 순위 변화</div>
        <div className={styles.contents}>
          <p className={styles.contentsMessage}>
            키워드를 선택하면 차트를 조회할 수 있습니다.
          </p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.header}>메인 키워드 순위 변화</div>
        <div className={styles.contents}>
          <div className={styles.contentsMessage}>로딩 중...</div>
        </div>
      </div>
    );
  }

  const isDataEmpty =
    !data ||
    !data[period] ||
    !Array.isArray(data[period]) ||
    data[period].length === 0;

  // const keywordData = data.keyword[mainKeyword];
  // const latestRank = keywordData[keywordData.length - 1].rank;

  return (
    <div className={styles.container}>
      <div className={styles.header}>메인 키워드 순위 변화</div>
      <div className={styles.contents}>
        <div className={styles.chart_header}>
          <div className={styles.title}>
            <p>메인키워드</p>
            <p>|</p>
            <span>{chartKeyword}</span>
          </div>
          <div className={styles.category}>
            <span
              onClick={() => setPeriod('daily')}
              style={{
                color: period === 'daily' ? 'black' : null,
                fontWeight: period === 'daily' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              일간
            </span>
            <span
              onClick={() => setPeriod('weekly')}
              style={{
                color: period === 'weekly' ? 'black' : null,
                fontWeight: period === 'weekly' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              주간
            </span>
            <span
              onClick={() => setPeriod('monthly')}
              style={{
                color: period === 'monthly' ? 'black' : null,
                fontWeight: period === 'monthly' ? '700' : null,
                cursor: 'pointer',
              }}
            >
              월간
            </span>
          </div>
        </div>
        {isDataEmpty ? (
          <div className={styles.chartMessage}>블로그 데이터가 없습니다.</div>
        ) : (
          <>
            <div className={styles.chartTitle}>블로그 최고 순위</div>
            <div className={styles.chart}>
              <ReviewRankChart rawData={data} period={period} />
            </div>

            <div className={styles.chartTitle}>상단노출 블로그 수</div>
            <div className={styles.chart}>
              <ReviewHighRankChart rawData={data} period={period} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewChart;
