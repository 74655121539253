import React from 'react';
import styles from './KeywordModal.module.scss';
import { useLocation } from 'react-router-dom';

function KeywordModal({
  isModalOpen,
  handleCloseModal,
  newKeyword,
  setNewKeyword,
  handleAddKeyword,
  handleKeywordSelect,
  handleKeywordRemove,
  handleSaveKeywords,
  selectedKeywords,
  checkedKeywords,
  setCheckedKeywords,
  data,
  externalKeywords, // 외부에서 가져온 키워드
}) {
  const location = useLocation(); // 현재 위치(URL)를 얻습니다.
  const isRankingPage = location.pathname.includes('/ranking/trace');
  const isReviewPage = location.pathname.includes('/review/analyze');

  if (!isModalOpen) {
    return null;
  }

  return (
    <div className={styles.modal}>
      <button className={styles.closeBtn} onClick={handleCloseModal}>
        X
      </button>
      <div className={styles.modalHeader}>
        <div className={styles.headerTitle}>키워드 추가/편집</div>
        <div className={styles.headerDesc}>
          원하는 키워드를 직접 추가하거나 추출된 키워드에서 선택할 수 있어요.
        </div>
        <div className={styles.storeTitle}>
          [{data.values[0].name}] {data.values[0].address}
        </div>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.addInput}>
          <input
            className={styles.inputWord}
            placeholder="키워드를 콤마(,)로 구분하여 최대 20글자까지 입력"
            value={newKeyword}
            onChange={(e) => setNewKeyword(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevent form submission
                handleAddKeyword(newKeyword);
                setNewKeyword('');
              }
            }}
          />
          <button
            className={styles.inputBtn}
            type="submit"
            onClick={() => {
              handleAddKeyword(newKeyword);
              setNewKeyword('');
            }}
          >
            + 키워드 추가
          </button>
        </div>

        <div className={styles.keywordList}>키워드 목록</div>
        <div className={styles.listBox}>
          {selectedKeywords.map((keyword) => (
            <div key={keyword} className={styles.keywordTag}>
              <span>{keyword}</span>
              <button
                className={styles.tagDelete}
                onClick={() => handleKeywordRemove(keyword)}
              >
                X
              </button>
            </div>
          ))}
        </div>

        {externalKeywords && externalKeywords.length > 0 && (
          <>
            <div className={styles.tableTitle}>
              {isRankingPage
                ? '리뷰분석 키워드'
                : isReviewPage
                ? '순위추적 키워드'
                : ''}
            </div>
            <div className={styles.externalKeywordsSection}>
              {externalKeywords.map((keyword) => (
                <label
                  key={keyword}
                  className={styles.keywordItem}
                  htmlFor={keyword}
                >
                  <input
                    type="checkbox"
                    id={keyword}
                    className={styles.keywordCheckBtn}
                    onChange={() => handleKeywordSelect(keyword)}
                    checked={checkedKeywords[keyword] || false}
                  />
                  <span
                    className={`${styles.keywordText} ${
                      checkedKeywords[keyword] ? styles.black : styles.gray
                    }`}
                  >
                    {keyword}
                  </span>
                </label>
              ))}
            </div>
          </>
        )}

        <div className={styles.tableTitle}>추출된 키워드</div>
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>지역 맛집</th>
              <td>
                <div className={styles.keywordContainer}>
                  {data.values[0].placekeyword.map((placeKeyword) => (
                    <label
                      key={placeKeyword}
                      className={styles.keywordItem}
                      htmlFor={placeKeyword}
                    >
                      <input
                        type="checkbox"
                        id={placeKeyword}
                        className={styles.keywordCheckBtn}
                        onChange={() => handleKeywordSelect(placeKeyword)}
                        checked={checkedKeywords[placeKeyword] || false}
                      />
                      <span
                        className={`${styles.keywordText} ${
                          checkedKeywords[placeKeyword]
                            ? styles.black
                            : styles.gray
                        }`}
                      >
                        {placeKeyword}
                      </span>
                    </label>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <th>지역 메뉴</th>
              <td>
                <div className={styles.keywordContainer}>
                  {data.values[0].menukeyword.map((menuKeyword) => (
                    <label
                      key={menuKeyword}
                      className={styles.keywordItem}
                      htmlFor={menuKeyword}
                    >
                      <input
                        type="checkbox"
                        id={menuKeyword}
                        className={styles.keywordCheckBtn}
                        onChange={() => handleKeywordSelect(menuKeyword)}
                        checked={checkedKeywords[menuKeyword] || false}
                      />
                      <span
                        className={`${styles.keywordText} ${
                          checkedKeywords[menuKeyword]
                            ? styles.black
                            : styles.gray
                        }`}
                      >
                        {menuKeyword}
                      </span>
                    </label>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <th>태그 키워드</th>
              <td>
                <div className={styles.keywordContainer}>
                  {data.values[0].Tagkeyword.map((tagKeyword) => (
                    <label
                      key={tagKeyword}
                      className={styles.keywordItem}
                      htmlFor={tagKeyword}
                    >
                      <input
                        type="checkbox"
                        id={tagKeyword}
                        className={styles.keywordCheckBtn}
                        onChange={() => handleKeywordSelect(tagKeyword)}
                        checked={checkedKeywords[tagKeyword] || false}
                      />
                      <span
                        className={`${styles.keywordText} ${
                          checkedKeywords[tagKeyword]
                            ? styles.black
                            : styles.gray
                        }`}
                      >
                        {tagKeyword}
                      </span>
                    </label>
                  ))}
                </div>
              </td>
            </tr>

            <tr>
              <th>마이 키워드</th>
              <td>
                {data.values[0].user_find_keywords.length === 0 && (
                  <div className={styles.noKeywordMessage}>
                    키워드 찾기에서 선택한 키워드가 저장됩니다. <br />
                    키워드 찾기에서 키워드를 추가해보세요:)
                  </div>
                )}
                <div className={styles.keywordContainer}>
                  {data.values[0].user_find_keywords.map((userKeyword) => (
                    <label
                      key={userKeyword}
                      className={styles.keywordItem}
                      htmlFor={userKeyword}
                    >
                      <input
                        type="checkbox"
                        id={userKeyword}
                        className={styles.keywordCheckBtn}
                        onChange={() => handleKeywordSelect(userKeyword)}
                        checked={checkedKeywords[userKeyword] || false}
                      />
                      <span
                        className={`${styles.keywordText} ${
                          checkedKeywords[userKeyword]
                            ? styles.black
                            : styles.gray
                        }`}
                      >
                        {userKeyword}
                      </span>
                    </label>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.modalBottom}>
        <button className={styles.saveBtn} onClick={handleSaveKeywords}>
          저장하기
        </button>
      </div>
    </div>
  );
}

export default KeywordModal;
