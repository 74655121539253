import React, { useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { AiOutlineMenu } from 'react-icons/ai';
import MSidebar from '../Sidebar/MSidebar';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currentUserState } from '../../state';
import { verifyAndRefreshToken, userLogout } from '../../api';

function Header() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const user = useRecoilValue(currentUserState);
  const resetCurrentUser = useResetRecoilState(currentUserState);
  const setCurrentUser = useSetRecoilState(currentUserState);
  const [username, setUsername] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      setCurrentUser({ username: storedUsername });
    }
  }, []);

  //   useEffect(() => {
  //     const checkTokenValidity = async () => {
  //         try {
  //             await verifyAndRefreshToken();
  //             const storedUsername = localStorage.getItem('username');
  //             if (storedUsername) {
  //                 setUsername(storedUsername);
  //                 setCurrentUser({ username: storedUsername });
  //             }
  //         } catch (error) {
  //             if (error.message === 'No access token found') {
  //                 handleLogout();
  //             }
  //         }
  //     };

  //     checkTokenValidity();
  // }, []);

  const toggleSidebar = () => {
    if (isSidebarOpen) {
      setSidebarOpen(false);
      setTimeout(() => {
        setShouldRender(false);
      }, 300);
    } else {
      setShouldRender(true);
      setTimeout(() => {
        setSidebarOpen(true);
      }, 20);
    }
  };

  const handleLogout = async () => {
    const loggedOut = await userLogout();
    if (loggedOut) {
      localStorage.removeItem('username'); // 로그아웃 시 로컬 스토리지의 username 제거
      resetCurrentUser(); // Reset the currentUserState when logged out
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.contents}>
          <AiOutlineMenu className={styles.menuIcon} onClick={toggleSidebar} />
          {user ? (
            <div className={styles.navBox}>
              <span
                className={styles.nav}
                onClick={() => {
                  // navigate('/login');
                }}
              >
                {user.username}
              </span>
              <span>/</span>
              <span className={styles.nav} onClick={handleLogout}>
                로그아웃
              </span>
            </div>
          ) : (
            <div className={styles.navBox}>
              <span
                className={styles.nav}
                onClick={() => {
                  navigate('/login');
                }}
              >
                로그인
              </span>
              <span>/</span>
              <span
                className={styles.nav}
                onClick={() => {
                  navigate('/signup');
                }}
              >
                회원가입
              </span>
            </div>
          )}
        </div>

        {/* 사이드바 */}
        {shouldRender && (
          <MSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        )}

        {/* Overlay */}
        {shouldRender && (
          <div
            className={`${styles.overlay} ${isSidebarOpen ? styles.show : ''}`}
            onClick={toggleSidebar}
          />
        )}
        {/* onClick={toggleSidebar} 오버레이를 클릭할 때 사이드바를 닫기 */}
      </div>
    </div>
  );
}

export default Header;
