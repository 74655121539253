import React from 'react';
import styles from './Compare.module.scss';
import { useNavigate, Outlet } from 'react-router-dom';
// import SearchBar from '../../components/SearchBar/SearchBar';

function Compare() {
  const navigate = useNavigate();

  return (
    <div>
      <div className={styles.container}>
        {/* <Seo title="순위추적" /> */}
        <div className={styles.logo}>마케차이즈</div>
        <div className={styles.switch}>
          <div
            className={styles.switchLeft}
            onClick={() => navigate('/ranking/trace')}
          >
            순위 추적
          </div>
          <div className={styles.switchRight}>순위 비교</div>
        </div>
        <Outlet />
      </div>
    </div>
  );
}

export default Compare;
