import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { postStoreDetail } from '../../../api';
import FailureModal from '../../Modal/FailureModal';
import styles from './MarketingStore.module.scss';

function MarketingStore({
  storeData,
  onDiagnoseClick,
  isLoading,
  searchInputRef,
}) {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);

  const {
    name = '월피동 갈비창고',
    store_id = '1922241170',
    address = '경기도 안산시 상록구 예술광장로 19 1층 갈비창고',
    thumUrl = 'https://ldb-phinf.pstatic.net/20230710_261/1688957705560picMk_JPEG/GJS04533.jpg',
    placeReviewCount = 420,
    reviewCount = 210,
    is_diagnosed = true,
  } = storeData?.data || {};

  const mutation = useMutation(postStoreDetail, {
    onSuccess: (data, variables) => {
      navigate(`/ranking/trace/detail/${variables.name}/${variables.store_id}`);
    },
    onError: (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage(`Error while posting store detail: ${error}`);
      }
      setErrorModalOpen(true);
    },
  });

  const handleTraceClick = () => {
    mutation.mutate(storeData.data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <div className={styles.imgBox}>
          {thumUrl ? (
            <img src={thumUrl} alt={`${name} 이미지`} />
          ) : (
            <p className={styles.noImage}>등록된 이미지가 없습니다.</p>
          )}
        </div>
        <div className={styles.infoDetail}>
          <div className={styles.storeName}>{name}</div>
          <div className={styles.storeAdr}>{address}</div>
          <div className={styles.storeReview}>
            <p>
              매장 총 리뷰 수 <span>|</span>
            </p>
            <p className={styles.reviewCount}>방문자 {placeReviewCount}</p>
            <p className={styles.reviewCount}>블로그 {reviewCount}</p>
          </div>
          <div className={styles.btnContainer}>
            <button onClick={onDiagnoseClick} disabled={isLoading}>
              {isLoading
                ? '진단 중...'
                : is_diagnosed
                ? '다시 진단하기'
                : '가게 진단하기'}
            </button>
            <button onClick={handleTraceClick} disabled={!storeData}>
              이 가게 순위 추적하기
            </button>
          </div>
        </div>
      </div>

      {/* FailureModal 컴포넌트 렌더링 및 해당 컴포넌트의 props 설정 */}
      <FailureModal
        isOpen={isErrorModalOpen}
        onClose={() => {
          setErrorModalOpen(false);
          setErrorMessage(null);
        }}
        error={errorMessage}
      />
    </div>
  );
}

export default MarketingStore;
