import React, { useState, useEffect, useMemo, useReducer } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styles from './AnalyzeDetail.module.scss';
import KeywordModal from '../../components/KeywordModal/KeywordModal';
import AnalyzeStore from '../../components/AnalyzeStore/AnalyzeStore';
import AnalyzeTable from '../../components/AnalyzeTable/AnalyzeTable';

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getStoreDetailAnalyze,
  putKeywordListAnalyze,
  postKeywordLogAnalyze,
  getKeywordLogAnalyze,
  getTaskStatus,
  getReviewChart,
  downloadExcel,
} from '../../api';

import { GrPrevious, GrNext } from 'react-icons/gr';
import DateContainer from '../../components/DateContainer/DateContainer';
import FailureModal from '../../components/Modal/FailureModal';
import GuideModal from '../../components/Modal/GuideModal';
import ReviewChart from '../../components/ReviewChart/ReveiwChart';

/// 로딩 상태를 한 번에 업데이트하는 리듀서
function loadingReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_LOADING':
      const newLoadingStates = { ...state };
      action.keywords.forEach((keyword) => {
        newLoadingStates[keyword] = action.isLoading;
      });
      return newLoadingStates;
    default:
      return state;
  }
}

function useURLQuery() {
  return new URLSearchParams(useLocation().search);
}

function AnalyzeDetail() {
  const { storeName, storeId } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [checkedKeywords, setCheckedKeywords] = useState({});
  const [newKeyword, setNewKeyword] = useState('');

  const [selectedDate, setSelectedDate] = useState(null); // 선택된 날짜
  const [dateIndex, setDateIndex] = useState(0); // 현재 선택된 날짜 인덱스
  const [taskLoadingStates, setTaskLoadingStates] = useState({});
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [keywordChanged, setKeywordChanged] = useState(false);
  const [shouldFetchKeywordLog, setShouldFetchKeywordLog] = useState(false);
  const [completedKeywords, setCompletedKeywords] = React.useState(new Set());
  const [isFailureModalOpen, setFailureModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [clickedHashTag, setClickedHashTag] = useState(null);
  const [chartKeyword, setChartKeyword] = useState(null);
  const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false);
  // 바로가기 파라미터 키워드
  const [externalKeywords, setExternalKeywords] = useState([]);

  const MessageComponent = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        fontWeight: 500,
        fontSize: '18px',
      }}
    >
      <p style={{ fontSize: '20px', marginBottom: '12px' }}>연관 키워드</p>
      <p>
        <span style={{ color: '#ff6524' }}>{clickedHashTag}</span>를
      </p>
      <p>추가하시겠습니까?</p>
    </div>
  );

  // 초기 로딩 상태 정의 (selectedKeywords를 사용)
  const initialLoadingStates = useMemo(() => {
    return selectedKeywords.reduce((acc, keyword) => {
      acc[keyword] = false; // 초기 로딩 상태는 false로 설정
      return acc;
    }, {});
  }, [selectedKeywords]);

  const [loadingStates, dispatch] = useReducer(
    loadingReducer,
    initialLoadingStates,
  );

  function updateLoadingStates(keywords, isLoading) {
    dispatch({ type: 'UPDATE_LOADING', keywords, isLoading });
  }

  const location = useLocation();
  const queryClient = useQueryClient();
  const query = useURLQuery();
  const cameFromButton = query.get('fromButton') === 'true';

  const formatDateForAPI = (date) => {
    return date.replace(/-/g, '').substring(2);
  };

  const storeDetailQueryKey = useMemo(
    () => ['storeDetailAnalyze', { storeName, storeId }],
    [storeName, storeId],
  );

  const { isLoading, isError, data, error } = useQuery(
    storeDetailQueryKey,
    () => getStoreDetailAnalyze({ storeName, storeId }),
    { retry: false },
  );

  const shouldCallKeywordLogAPI =
    shouldFetchKeywordLog || data?.values[0].date_list?.length > 0;

  const keywordLogQueryKey = [
    'keywordLogAnalyze',
    { storeId, date: selectedDate ? formatDateForAPI(selectedDate) : '' },
  ];

  const {
    data: logData,
    isLoading: logLoading,
    isError: logError,
    error: logErrorMsg,
  } = useQuery(
    keywordLogQueryKey,
    () => {
      const dateForAPI = selectedDate ? formatDateForAPI(selectedDate) : '';
      console.log('date inside getKeywordLog:', dateForAPI);
      return getKeywordLogAnalyze({ storeId, date: dateForAPI });
    },
    {
      retry: false,
      onSuccess: (data) => {
        console.log('Success data:', data);
        // Save the keyword log data to local state
      },
      onError: (error) => {
        console.log('Error:', error);
        // ... 기존 코드
      },

      enabled: shouldCallKeywordLogAPI,
    },
  );

  useEffect(() => {
    const hasKeywords =
      data &&
      data.values[0].my_keywords &&
      data.values[0].my_keywords.length > 0;

    // shouldFetchKeywordLog 상태를 data가 변경될 때만 업데이트합니다.
    if (hasKeywords) {
      setShouldFetchKeywordLog(true);
    } else {
      setShouldFetchKeywordLog(false);
    }
  }, [data]);

  useEffect(() => {
    if (cameFromButton || (data && data.values[0].my_keywords.length === 0)) {
      if (!hasModalBeenOpened) {
        setIsModalOpen(true);
        setHasModalBeenOpened(true); // 모달이 열렸음을 기록
      }
    } else {
      setIsModalOpen(false);
    }
  }, [data, cameFromButton, hasModalBeenOpened]);

  useEffect(() => {
    // 쿼리 파라미터에서 키워드를 파싱
    const queryParams = new URLSearchParams(location.search);
    const keywordsParam = queryParams.get('keywords');
    if (keywordsParam) {
      setExternalKeywords(keywordsParam.split(','));
    }
  }, [location]);

  const mutation = useMutation(putKeywordListAnalyze, {
    onSuccess: (response) => {
      setFilteredKeywords(response.keywords);

      // 새로운 키워드의 로딩 상태를 초기화합니다.
      const newLoadingStates = response.keywords.reduce((acc, keyword) => {
        acc[keyword] = true; // 새로운 키워드의 로딩 상태를 true로 설정
        return acc;
      }, {});

      // 기존 로딩 상태와 새로운 로딩 상태를 병합합니다.
      setTaskLoadingStates((prevState) => ({
        ...prevState,
        ...newLoadingStates,
      }));

      queryClient.invalidateQueries('storeDetailAnalyze');
      setKeywordChanged(true);
    },
    onError: (error) => {
      console.error(`Error while saving keywords: ${error}`);

      // 여기에 FailureModal을 표시하는 로직을 추가합니다.
      let errorMessage = 'Server error'; // 기본 에러 메시지
      if (
        error &&
        error.response &&
        typeof error.response.data === 'object' &&
        error.response.data.error
      ) {
        errorMessage = error.response.data.error;
      }

      const displayedErrorMessage = Array.isArray(errorMessage)
        ? errorMessage[0]
        : errorMessage;
      openFailureModal(displayedErrorMessage);
    },
  });

  const openFailureModal = (error) => {
    setErrorMessage(error);
    setFailureModalOpen(true);
  };

  const closeFailureModal = () => setFailureModalOpen(false);

  // 호출할 차트 날짜 형식을 변환하는 함수
  const chartFormatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return year + month + day;
  };

  const getChart = () => {
    // selectedDate를 원하는 형식으로 변환
    const formattedDate = chartFormatDate(selectedDate);

    return getReviewChart({
      storeId,
      date: formattedDate,
      keyword: chartKeyword,
    });
  };
  const {
    data: reviewChartData,
    isError: reviewChartError,
    isLoading: reviewChartLoading,
  } = useQuery(['reviewChart', storeId, chartKeyword, selectedDate], getChart, {
    enabled: !!chartKeyword,
  });

  console.log('Is loading:', reviewChartLoading);
  console.log('Chart keyword:', chartKeyword);

  // 키워드 선택 이벤트 핸들러 (AnalyzeTable에서 호출됨)
  const handleChartKeywordSelect = (keyword) => {
    setChartKeyword(keyword);
  };

  const postKeywordLogMutation = useMutation(postKeywordLogAnalyze, {
    onSuccess: async (response) => {
      if (keywordChanged) {
        const taskIds = response.task_ids;

        const keywordTaskMapping = Object.keys(taskIds).reduce(
          (acc, keyword) => {
            acc[taskIds[keyword]] = keyword;
            return acc;
          },
          {},
        );

        const initialLoadingStates = Object.keys(taskIds).reduce(
          (acc, keyword) => {
            acc[keyword] = true;
            return acc;
          },
          { ...taskLoadingStates },
        );

        setTaskLoadingStates(initialLoadingStates);
        setKeywordChanged(false);

        // 이미 완료된 task_id를 저장할 상태
        const completedTaskIds = new Set();
        const failedTaskIds = new Set();
        const failedKeywords = new Set(); // 실패한 키워드를 저장할 Set

        const checkTaskStatus = async () => {
          const activeTaskIds = Object.values(taskIds).filter(
            (taskId) => !completedTaskIds.has(taskId),
          );
          const commaSeparatedTaskIds = activeTaskIds.join(',');

          if (commaSeparatedTaskIds.length === 0) {
            return;
          }

          const statusResponse = await getTaskStatus(commaSeparatedTaskIds);

          statusResponse.forEach((task) => {
            if (
              task.state === 'SUCCESS' ||
              (task.result &&
                task.result.msg === '이미 해당 키워드의 로그가 있습니다.')
            ) {
              completedTaskIds.add(task.task_id);
              const keyword = keywordTaskMapping[task.task_id];
              setTaskLoadingStates((prevState) => ({
                ...prevState,
                [keyword]: false,
              }));

              setCompletedKeywords((prevSet) => {
                const newSet = new Set(prevSet);
                newSet.add(keyword);
                return newSet;
              });
              queryClient.invalidateQueries('keywordLogAnalyze');
            } else if (task.state === 'FAILURE') {
              try {
                console.error(
                  `Task failed for keyword: ${
                    keywordTaskMapping[task.task_id]
                  }`,
                );
                const sanitizedStatus = task.status.replace(/'/g, '"');
                const errorDetail = JSON.parse(sanitizedStatus);

                // 특별한 에러 메시지를 처리
                let errorMessage = errorDetail.error; // 기본적으로 서버에서 받은 에러 메시지를 사용

                if (errorDetail.error === '검색결과가 없습니다.') {
                  failedKeywords.add(keywordTaskMapping[task.task_id]); // 실패한 키워드 저장
                  const failedKeywordString =
                    Array.from(failedKeywords).join(', '); // 배열을 문자열로 변환
                  errorMessage = `'${failedKeywordString}' 은(는) 검색결과가 없습니다.`; // 특별한 처리
                }

                openFailureModal(errorMessage);
              } catch (e) {
                console.error('Error parsing task status:', e);
                openFailureModal('알 수 없는 에러가 발생했습니다');
              }

              failedTaskIds.add(task.task_id);
              completedTaskIds.add(task.task_id);
              queryClient.invalidateQueries('keywordLogAnalyze');
            }
          });
          setTimeout(checkTaskStatus, 5000);
        };

        checkTaskStatus();
      }
    },
    onError: (error) => {
      console.error(`Error while fetching data: ${error}`);
    },
  });

  const keywordUpdateMutation = useMutation(postKeywordLogAnalyze, {
    // API 호출 함수는 동일하게 postKeywordLog를 사용하였습니다.
    onMutate: () => {
      // mutation이 시작되기 전에 최근 날짜로 설정
      setDateIndex(0);
    },
    onSuccess: async (response) => {
      const taskIds = response.task_ids;

      const keywordTaskMapping = Object.keys(taskIds).reduce((acc, keyword) => {
        acc[taskIds[keyword]] = keyword;
        return acc;
      }, {});

      const initialLoadingStates = Object.keys(taskIds).reduce(
        (acc, keyword) => {
          acc[keyword] = true;
          return acc;
        },
        { ...taskLoadingStates },
      );

      setTaskLoadingStates(initialLoadingStates);

      // 이미 완료된 task_id를 저장할 상태
      const completedTaskIds = new Set();
      const failedTaskIds = new Set(); // 실패한 작업을 추적

      const checkTaskStatus = async () => {
        const activeTaskIds = Object.values(taskIds).filter(
          (taskId) => !completedTaskIds.has(taskId),
        );
        const commaSeparatedTaskIds = activeTaskIds.join(',');

        if (commaSeparatedTaskIds.length === 0) {
          return;
        }

        const statusResponse = await getTaskStatus(commaSeparatedTaskIds);

        statusResponse.forEach((task) => {
          if (
            task.state === 'SUCCESS' ||
            (task.result &&
              task.result.msg === '이미 해당 키워드의 로그가 있습니다.')
          ) {
            completedTaskIds.add(task.task_id);
            const keyword = keywordTaskMapping[task.task_id];
            setTaskLoadingStates((prevState) => ({
              ...prevState,
              [keyword]: false,
            }));
            queryClient.invalidateQueries('keywordLogAnalyze');
          } else if (task.state === 'FAILURE') {
            try {
              console.error(
                `Task failed for keyword: ${keywordTaskMapping[task.task_id]}`,
              );
              const sanitizedStatus = task.status.replace(/'/g, '"');
              const errorDetail = JSON.parse(sanitizedStatus);

              let errorMessage = errorDetail.error; // 기본적으로 서버에서 받은 에러 메시지를 사용

              openFailureModal(errorMessage);
            } catch (e) {
              console.error('Error parsing task status:', e);
              openFailureModal('알 수 없는 에러가 발생했습니다');
            }

            failedTaskIds.add(task.task_id);
            completedTaskIds.add(task.task_id);
            queryClient.invalidateQueries('keywordLogAnalyze');
          }
        });
        setTimeout(checkTaskStatus, 5000);
      };

      checkTaskStatus();
    },
    onError: (error) => {
      console.error(`Error while updating keywords: ${error}`);
    },
  });

  const downloadExcelMutation = useMutation(({ tag, storeId }) =>
    downloadExcel(tag, storeId),
  );

  const handleDownloadClick = () => {
    const tag = 'review';

    downloadExcelMutation.mutate(
      { tag, storeId },
      {
        onSuccess: () => {
          console.log('Download successful!');
        },
        onError: (error) => {
          console.log('An error occurred:', error);
        },
      },
    );
  };

  useEffect(() => {
    if (data && logData) {
      console.log('logData:', logData); // 디버깅
      const myKeywords = data.values[0].my_keywords;

      const filteredKeywords = myKeywords; // 필터링 없음

      setSelectedKeywords(filteredKeywords);

      // 선택된 키워드를 별도의 상태로 저장
      setFilteredKeywords(filteredKeywords);

      const initialCheckedKeywords = filteredKeywords.reduce((acc, keyword) => {
        acc[keyword] = true;
        return acc;
      }, {});

      setCheckedKeywords(initialCheckedKeywords);
    }
  }, [data, logData]);

  useEffect(() => {
    if (
      data &&
      data.values[0].date_list &&
      data.values[0].date_list.length > 0
    ) {
      setSelectedDate(data.values[0].date_list[dateIndex]);
    } else {
      setSelectedDate(null);
    }
  }, [data, dateIndex]);

  useEffect(() => {
    if (keywordChanged) {
      postKeywordLogMutation.mutate(storeId);
    }
  }, [storeId, keywordChanged]);

  useEffect(() => {
    if (isModalOpen) {
      const currentKeywords = data?.values[0]?.my_keywords || [];
      setSelectedKeywords(currentKeywords);

      // checkedKeywords 상태 업데이트
      const initialCheckedKeywords = currentKeywords.reduce((acc, keyword) => {
        acc[keyword] = true;
        return acc;
      }, {});
      setCheckedKeywords(initialCheckedKeywords);
    }
  }, [isModalOpen, data]);

  useEffect(() => {
    updateLoadingStates(selectedKeywords, true);
  }, [selectedKeywords]);

  useEffect(() => {
    console.log('Completed Keywords:', completedKeywords);
  }, [completedKeywords]); // 디버깅

  const handleUpdateClick = () => {
    keywordUpdateMutation.mutate(storeId);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleKeywordSelect = (keyword) => {
    setSelectedKeywords((prevKeywords) => {
      const isChecked = prevKeywords.includes(keyword);
      if (isChecked) {
        // If the keyword is already selected, remove it from the array
        return prevKeywords.filter((kw) => kw !== keyword);
      } else {
        // If the keyword is not selected, add it to the array
        return [...prevKeywords, keyword];
      }
    });
    setCheckedKeywords((prevCheckedKeywords) => ({
      ...prevCheckedKeywords,
      [keyword]: !prevCheckedKeywords[keyword],
    }));
  };

  const handleKeywordRemove = (keyword) => {
    setSelectedKeywords((prevKeywords) =>
      prevKeywords.filter((kw) => kw !== keyword),
    );
    setCheckedKeywords((prevCheckedKeywords) => ({
      ...prevCheckedKeywords,
      [keyword]: false,
    }));
  };

  const handleAddKeyword = (keywordString) => {
    // 'keywordString'을 ','로 분리하여 개별 키워드의 배열을 만들고, 각 키워드에서 공백을 제거
    const keywords = keywordString
      .split(',')
      .map((keyword) => keyword.replace(/\s/g, ''));

    keywords.forEach((keyword) => {
      // 키워드가 비어있지 않고, 길이가 20자 이하인 경우
      if (keyword !== '' && keyword.length <= 20) {
        // 해당 키워드가 이미 'selectedKeywords'에 존재하는지 확인
        if (!selectedKeywords.includes(keyword)) {
          // 존재하지 않는 경우에만 추가
          setSelectedKeywords((prevKeywords) => [...prevKeywords, keyword]);
        }
      }

      // 새 키워드가 추출된 키워드 배열 중 어느 곳에도 존재하는지 확인
      const keywordExistsInData =
        data.values[0].placekeyword.includes(keyword) ||
        data.values[0].menukeyword.includes(keyword) ||
        data.values[0].Tagkeyword.includes(keyword) ||
        data.values[0].user_find_keywords.includes(keyword) ||
        externalKeywords.includes(keyword); // 'externalKeywords'에 존재하는지도 확인

      // 만약 존재한다면, 'checkedKeywords' 상태에서도 표시
      if (keywordExistsInData) {
        setCheckedKeywords((prev) => ({ ...prev, [keyword]: true }));
      }
    });
  };

  const handleSaveKeywords = () => {
    console.log('선택한 키워드', selectedKeywords);
    mutation.mutate({ storeId, keywords: selectedKeywords });
    setIsModalOpen(false);
  };

  // 날짜 변경 함수 내부에서 상태 초기화
  const handleDateChangeCommon = () => {
    setTaskLoadingStates({}); // 로딩 상태 초기화
    setCompletedKeywords(new Set()); // 완료된 키워드 초기화
    // 추가로 초기화해야 할 상태가 있다면 여기에 코드를 추가
  };

  const handlePreviousDate = () => {
    if (dateIndex < data.values[0].date_list.length - 1) {
      setDateIndex(dateIndex + 1); // 다음 날짜 인덱스로 이동
      handleDateChangeCommon(); // 상태 초기화
    }
  };

  const handleNextDate = () => {
    if (dateIndex > 0) {
      setDateIndex(dateIndex - 1); // 이전 날짜 인덱스로 이동
      handleDateChangeCommon(); // 상태 초기화
    }
  };

  const handleDateChange = (calendarDate) => {
    const formattedDate = calendarDate;
    const index = data.values[0].date_list.findIndex(
      (date) => formatDateForAPI(date) === formatDateForAPI(formattedDate),
    );

    setDateIndex(index);
    setSelectedDate(formattedDate);
    handleDateChangeCommon(); // 상태 초기화
  };

  if (isLoading) {
    return <div>로딩중입니다...</div>; // API 호출 중일 때 로딩 컴포넌트를 렌더링합니다.
  }

  if (logLoading && shouldCallKeywordLogAPI) {
    return <div>키워드 로딩중입니다...</div>;
  }

  if (postKeywordLogMutation.isLoading) {
    return <div>키워드 생성중입니다...</div>;
  }

  if (keywordUpdateMutation.isLoading) {
    return <div>키워드 업데이트중입니다...</div>;
  }

  if (isError || logError) {
    return <div>{error?.message || logErrorMsg?.message}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.topRight}>
          <button
            className={styles.returnBtn}
            onClick={() => navigate('/review/analyze')}
          >
            목록 돌아가기
          </button>
          <header>키워드별 네이버 블로그 리뷰 순위</header>
        </div>

        <div className={styles.topLeft}>
          <div>등록 상점 {data?.values[0].my_stores}/30</div>
          <div>등록 키워드 {data?.values[0].my_keywords?.length}/10</div>
        </div>
      </div>
      <div className={styles.contents}>
        <div className={styles.storeInfo}>
          <AnalyzeStore
            thumUrl={data.values[0].thumUrl}
            name={data.values[0].name}
            storeId={data.values[0].id}
            address={data.values[0].address}
            myKeywords={data.values[0].my_keywords}
            keywordUpdateMutation={keywordUpdateMutation}
            logData={logData}
            data={data}
            handleUpdateClick={handleUpdateClick}
          />
        </div>

        <div className={styles.btnContainer}>
          <button
            onClick={handleOpenModal}
            className={styles.keywordBtn}
            disabled={dateIndex !== 0}
          >
            + 키워드 추가/편집
          </button>

          <div className={styles.btnRightBox}>
            <button className={styles.excelBtn} onClick={handleDownloadClick}>
              <img src="/images/excel.png" alt="엑셀 이미지" />
            </button>
            <button className={styles.kakaoBtn}>
              <img src="/images/kakaologo.png" alt="카카오 심볼" />
              <div>순위 알림</div>
            </button>
          </div>
        </div>

        {data &&
        data.values[0].my_keywords &&
        data.values[0].my_keywords.length > 0 ? (
          <div className={styles.dateContainer}>
            <GrPrevious
              onClick={handlePreviousDate} // 이전 버튼 클릭 시 다음 날짜로 이동
              className={
                dateIndex < data?.values[0].date_list.length - 1
                  ? ''
                  : styles.disabledButton
              }
              style={{ cursor: 'pointer' }}
            />
            <DateContainer
              logData={logData}
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
            />
            <GrNext
              onClick={handleNextDate} // 다음 버튼 클릭 시 이전 날짜로 이동
              className={dateIndex > 0 ? '' : styles.disabledButton}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ) : null}

        {filteredKeywords.length > 0 ? (
          <>
            <AnalyzeTable
              logData={logData}
              taskLoadingStates={taskLoadingStates}
              myKeywords={filteredKeywords}
              completedKeywords={completedKeywords}
              onHashTagClick={(clickedTag) => {
                setClickedHashTag(clickedTag); // 클릭된 해시태그 저장
                setIsGuideOpen(true); // 모달 열기
              }}
              onChartKeywordSelect={handleChartKeywordSelect}
            />
            <ReviewChart
              data={reviewChartData}
              isLoading={reviewChartLoading}
              chartKeyword={chartKeyword}
            />
          </>
        ) : (
          <>
            <AnalyzeTable logData={logData} />
            <p className={styles.keyword_add_msg}>키워드를 추가해주세요</p>
          </>
        )}
      </div>

      {/* 키워드 모달 */}
      {isModalOpen && (
        <KeywordModal
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          newKeyword={newKeyword}
          setNewKeyword={setNewKeyword}
          handleAddKeyword={handleAddKeyword}
          handleKeywordSelect={handleKeywordSelect}
          handleKeywordRemove={handleKeywordRemove}
          handleSaveKeywords={handleSaveKeywords}
          selectedKeywords={selectedKeywords}
          checkedKeywords={checkedKeywords}
          data={data}
          externalKeywords={externalKeywords}
        />
      )}
      {isModalOpen && (
        <div
          className={`${styles.overlay} ${isModalOpen ? styles.show : ''}`}
          onClick={handleCloseModal}
        />
      )}
      {/* 키워드 모달 end*/}

      {/* 키워드 분석 실패 모달 */}
      <FailureModal
        isOpen={isFailureModalOpen}
        onClose={closeFailureModal}
        error={errorMessage}
      />

      <GuideModal
        isOpen={isGuideOpen}
        onClose={() => {
          setIsGuideOpen(false);
          setClickedHashTag(null); // 클릭된 해시태그 초기화
        }}
        onProceed={() => {
          if (clickedHashTag) {
            // 클릭된 해시태그가 이미 selectedKeywords에 있는지 확인
            if (!selectedKeywords.includes(clickedHashTag)) {
              // 없으면 추가
              const newKeywords = [...selectedKeywords, clickedHashTag];
              mutation.mutate({ storeId, keywords: newKeywords });
            }
          }
          setIsGuideOpen(false);
          setClickedHashTag(null); // 클릭된 해시태그 초기화
        }}
        MessageComponent={MessageComponent}
      />
    </div>
  );
}

export default AnalyzeDetail;
