import React from 'react';
import styles from './RankingStore.module.scss';
import { RxUpdate } from 'react-icons/rx';
import GoToAnalyzeBtn from '../Button/GoToAnalyzeBtn';

function RankingStore({
  thumUrl,
  name,
  storeId,
  address,
  mainKeyword,
  myKeywords,
  handleSetMainKeyword,
  keywordData,
  // keywordUpdateMutation,
  handleUpdateClick,
  data,
  logData,
}) {
  let mainKeywordRank = '- 위';
  let updateDays = null;

  const keywordKeys =
    logData && logData.keyword ? Object.keys(logData.keyword) : [];

  // mainKeyword가 keywordKeys에 없는 경우 대응을 위한 로직
  let selectedMainKeyword = mainKeyword;
  if (mainKeyword && !keywordKeys.includes(mainKeyword)) {
    // mainKeyword가 keywordKeys에 없을 경우 처리
    selectedMainKeyword = ''; // 예시로 빈 문자열 설정, 필요한 경우 다른 처리가 가능합니다.
  }

  if (logData && logData.keyword && mainKeyword) {
    // logData.keyword에서 메인 키워드에 해당하는 배열을 찾습니다.
    const keywordRankInfo = logData.keyword[mainKeyword];
    if (keywordRankInfo && keywordRankInfo.length > 0) {
      const lastRank = keywordRankInfo[keywordRankInfo.length - 1].rank;
      if (lastRank === 300) {
        mainKeywordRank = '순위밖';
      } else {
        mainKeywordRank = `${lastRank}위`;
      }
    }
  }

  if (logData && logData.date_list && logData.date_list.length > 0) {
    const lastUpdateDate = new Date(logData.date_list[0]); // 가장 최근 날짜를 가져옵니다.
    const today = new Date();

    // Reset the time part of the dates to 00:00:00.000
    lastUpdateDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const diffTime = Math.abs(today - lastUpdateDate);
    const daysAgo = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // convert to days
    updateDays = daysAgo === 0 ? '오늘' : `${daysAgo} 일전`;
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <div className={styles.imgBox}>
          {thumUrl ? (
            <img src={thumUrl} alt="store Img" />
          ) : (
            <p className={styles.noImage}>
              등록된 이미지가
              <br /> 없습니다.
            </p>
          )}
        </div>
        <div className={styles.infoDetail}>
          <div className={styles.storeName}>
            {name}
            <span>
              <GoToAnalyzeBtn store={data.values[0]} />
            </span>
          </div>
          <div className={styles.storeAdr}>{address}</div>
          <div className={styles.storeUpdate} onClick={handleUpdateClick}>
            <p>키워드 업데이트</p>
            <div className={styles.updateDetails}>
              <span>{updateDays}</span>
              <RxUpdate />
            </div>
          </div>
          <div className={styles.storeKeyword}>
            메인 키워드 |
            <span>
              <select
                onChange={(e) => handleSetMainKeyword(e.target.value)}
                value={selectedMainKeyword || ''} // selectedMainKeyword를 사용
              >
                <option value="" disabled>
                  키워드 선택
                </option>
                {keywordKeys.map((keyword) => (
                  <option key={keyword} value={keyword}>
                    {keyword}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.keywordBox}>
        <div className={styles.text}>메인키워드</div>
        <div className={styles.keywordRank}>{mainKeywordRank}</div>
      </div>
    </div>
  );
}

export default RankingStore;
