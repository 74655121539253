import React from 'react';
import styles from './MarketingScoreEx.module.scss';
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Progress } from '@chakra-ui/react';
import useWindowSize from '../../../hooks/useWindowSize';

function MarketingScoreEx({ searchInputRef }) {
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}년 ${
    currentDate.getMonth() + 1
  }월 ${currentDate.getDate()}일 00시 기준`;

  function CustomProgress({ value, color }) {
    const [windowWidth] = useWindowSize();
    const progressBarHeight = windowWidth < 576 ? '14px' : '20px';

    return (
      <Progress
        value={value}
        height={progressBarHeight}
        borderRadius="12px"
        sx={{
          bg: '#f1f1f1',
          border: '1px solid #999999',
          '> div': {
            bg: color || '#ff6524', // 지정된 색상이 없으면 기본 색상 사용
          },
        }}
      />
    );
  }

  const handleOverlayClick = () => {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div>종합 진단 평가</div>
      <div className={styles.entire}>
        <div className={styles.e_right}>
          이 플레이스의 진단 결과는
          <br />
          <span>보통</span> 입니다.
        </div>
        <div className={styles.e_center}>
          <CircularProgressbarWithChildren
            value={72}
            strokeWidth={5}
            styles={buildStyles({
              strokeLinecap: 'butt',
              textColor: 'black',
              pathColor: '#ff6524',
              trailColor: '#eeeeee',
            })}
          >
            <div className={styles.scoreLabel}>
              <strong>총점</strong>
            </div>
            <div className={styles.scoreValue}>72점</div>
            <div className={styles.scoreOutOf}>/100점</div>
          </CircularProgressbarWithChildren>
        </div>
        <div className={styles.e_left}>
          <div className={styles.e_left_badge}>
            <div className={styles.badge}>
              <img src="/images/badgeGray.png" alt="회색 뱃지 이미지" />
              <span>최상</span>
            </div>
            <div className={styles.badge}>
              <img src="/images/badgeGray.png" alt="회색 뱃지 이미지" />
              <span>양호</span>
            </div>
            <div className={styles.badge}>
              <img src="/images/badgeOrange.png" alt="주황 뱃지 이미지" />
              <span>보통</span>
            </div>
            <div className={styles.badge}>
              <img src="/images/badgeGray.png" alt="회색 뱃지 이미지" />
              <span>위험</span>
            </div>
            <div className={styles.badge}>
              <img src="/images/badgeGray.png" alt="회색 뱃지 이미지" />
              <span>심각</span>
            </div>
          </div>

          <div className={styles.e_left_update}>
            <p>마지막 업데이트</p>
            <p>{formattedDate}</p>
          </div>
        </div>
      </div>
      <div>항목 진단 평가</div>
      <div className={styles.detail}>
        <div className={styles.d_content}>
          <div className={styles.d_title}>콘텐츠</div>
          <div className={styles.d_score}>72점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={72} color="#ffc940" />
          </div>
        </div>
        <div className={styles.d_content}>
          <div className={styles.d_title}>리뷰</div>
          <div className={styles.d_score}>44점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={44} color="#ff6524" />
          </div>
        </div>
        <div className={styles.d_content}>
          <div className={styles.d_title}>연동</div>
          <div className={styles.d_score}>100점</div>
          <div className={styles.d_progress}>
            <CustomProgress value={100} color="#59c4fa" />
          </div>
        </div>
      </div>

      <div>점검 필요 사항</div>
      <div className={styles.inspection}>
        <div className={styles.overlay}>
          <div className={styles.overlayContent}>
            <img src="/images/store.png" alt="매장 아이콘 이미지" />
            <p>
              내 매장을 검색하고
              <br />
              최고의 솔루션을 받아보세요!
            </p>
          </div>
          <div className={styles.overlayFooter} onClick={handleOverlayClick}>
            내 매장 검색하고 솔루션 확인하기
          </div>
        </div>

        <div className={`${styles.i_content} ${styles.box1}`}>
          <div className={styles.content}>
            <header>콘텐츠 점수</header>
            <p className={styles.headerDes}>
              플레이스의 본문 내용을 분석했어요.
            </p>
            <p className={styles.text}>
              - 등록한 사진 수가 3장으로 적은 편이에요. 조금 더 업로드 해볼까요?
            </p>
            <p className={styles.textDes}>
              ▶︎ 네이버 플레이스에서 추천하는 이미지 등록 수는 20장이에요.
            </p>
          </div>
        </div>

        <div className={`${styles.i_content} ${styles.box2}`}>
          <div className={styles.content}>
            <header>리뷰 점수</header>
            <p className={styles.headerDes}>
              플레이스의 등록된 리뷰들을 분석했어요.
            </p>
            <p className={styles.text}>
              - 우리 가게의 주당 방문자 리뷰 수는 14건입니다. 방문자 리뷰수를
              늘려주세요.
            </p>
            <p className={styles.textDes}>
              ▶︎ 적정 플레이스 방문자 리뷰 수는 주 21건 입니다.
            </p>
          </div>
        </div>

        <div className={`${styles.i_content} ${styles.box3}`}>
          <div className={styles.content}>
            <header>연동 점수</header>
            <p className={styles.headerDes}>
              플레이스에 연동된 항목들을 분석했어요.
            </p>
            <p className={styles.text}>
              - 네이버 예약, 톡톡, 배달이 연동되어 있지 않아요.
            </p>
            <p className={styles.textDes}>
              ▶︎ 네이버 연동은 플레이스 점수에 아주 큰 영향을 줘요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketingScoreEx;
