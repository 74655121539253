import React from 'react';
import styles from './KeywordTable.module.scss';
import { Spinner } from '@chakra-ui/react';

function KeywordTable({
  logData = {}, // 기본값을 빈 객체로 설정
  taskLoadingStates = {}, // 기본값을 빈 객체로 설정
  myKeywords = [],
  completedKeywords = new Set(), // 기본값을 빈 Set으로 설정
  handleSetMainKeyword,
}) {
  // if (!logData || typeof logData !== 'object') {
  //   return null;
  // }

  // myKeywords에 해당하는 entry만 미리 추출합니다.
  // const keywordEntries = myKeywords.map((keyword) => {
  //   if (!logData.keyword[keyword]) {
  //     console.warn('Missing keyword in logData:', keyword); // 경고 로그 추가
  //   }
  //   const entry = logData.keyword[keyword] ? logData.keyword[keyword][0] : {};
  //   const isLoading = taskLoadingStates[keyword];
  //   console.log('isLoading for keyword:', keyword, 'is:', isLoading);
  //   return { keyword, entry, isLoading };
  // });

  // logData와 logData.keyword가 undefined인지 확인
  const keywordData = logData?.keyword || {};

  const keywordEntries = myKeywords
    .filter((keyword) => {
      return !(
        taskLoadingStates[keyword] !== true &&
        !keywordData[keyword] &&
        !completedKeywords.has(keyword)
      );
    })
    .map((keyword) => {
      const entry = (keywordData[keyword] && keywordData[keyword][0]) || {};
      const isLoading =
        taskLoadingStates[keyword] === true || !keywordData[keyword];
      return { keyword, entry, isLoading };
    });

  return (
    <div>
      <div className={styles.table_box}>
        <table className={`${styles.table} ${styles.table_min}`}>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>키워드</th>
              <th style={{ width: '18%' }}>월 검색량</th>
              <th style={{ width: '18%' }}>순위</th>
              <th style={{ width: '24%', fontSize: '12px' }}>
                (전주 대비 순위 변동)
              </th>
            </tr>
          </thead>
          <tbody>
            {keywordEntries.map(({ keyword, entry, isLoading }, index) => (
              <tr key={`${keyword}_${index}`}>
                <td
                  className={styles.keywordText}
                  onClick={() => handleSetMainKeyword(keyword)}
                >
                  {keyword}
                </td>
                {isLoading ? (
                  <td colSpan={3}>
                    <div className={styles.spinner}>
                      <span>분석중</span>
                      <Spinner
                        color="#ff6524"
                        size="md"
                        speed="0.65s"
                        emptyColor="gray.200"
                      />
                    </div>
                  </td>
                ) : (
                  <>
                    <td>
                      {entry.monthly_search_count &&
                      entry.monthly_search_count !== -1
                        ? entry.monthly_search_count.toLocaleString()
                        : '-'}
                    </td>
                    <td>
                      {entry.rank === 300 ? '순위 밖' : `${entry.rank}위`}
                    </td>
                    <td>
                      <p
                        className={`${styles.rankChange} ${
                          entry.rank_change < 0
                            ? styles.redBackground
                            : entry.rank_change > 0
                            ? styles.blueBackground
                            : styles.grayBackground
                        }`}
                      >
                        {entry.rank_change < 0
                          ? `▲ ${Math.abs(entry.rank_change)}` // -5를 5로 변경
                          : entry.rank_change > 0
                          ? `▼ ${Math.abs(entry.rank_change)}`
                          : '-'}
                      </p>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default KeywordTable;
