import { useCallback } from 'react';

const useStripHtml = () => {
  const stripHtml = useCallback((htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  }, []);

  return stripHtml;
};

export default useStripHtml;
