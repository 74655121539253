import React from 'react';
import styles from './AnalyzeStore.module.scss';
import { RxUpdate } from 'react-icons/rx';
import GoToRankingBtn from '../Button/GoToRankingBtn';
function AnalyzeStore({
  thumUrl,
  name,
  storeId,
  address,
  myKeywords,
  logData,
  keywordUpdateMutation,
  data,
  handleUpdateClick,
}) {
  let updateDays = null;

  const { today_data, values } = data;
  const isDefaultTodayData =
    today_data.reviewCount === 0 &&
    today_data.placeReviewCount === 0 &&
    today_data.cal_reviewCount === 0 &&
    today_data.cal_placeReviewCount === 0;

  const reviewCount = isDefaultTodayData
    ? values[0].reviewCount
    : today_data.reviewCount;
  const placeReviewCount = isDefaultTodayData
    ? values[0].placeReviewCount
    : today_data.placeReviewCount;

  const formatCalValue = (calValue) => {
    if (calValue > 0) return `▲${calValue}`;
    if (calValue < 0) return `▼${Math.abs(calValue)}`;
    return '-';
  };

  const cal_reviewCount = formatCalValue(today_data.cal_reviewCount);
  const cal_placeReviewCount = formatCalValue(today_data.cal_placeReviewCount);

  if (logData && logData.date_list && logData.date_list.length > 0) {
    const lastUpdateDate = new Date(logData.date_list[0]); // 가장 최근 날짜를 가져옵니다.
    const today = new Date();

    // Reset the time part of the dates to 00:00:00.000
    lastUpdateDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const diffTime = Math.abs(today - lastUpdateDate);
    const daysAgo = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // convert to days
    updateDays = daysAgo === 0 ? '오늘' : `${daysAgo} 일전`;
  }

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <div className={styles.imgBox}>
          {thumUrl ? (
            <img src={thumUrl} alt={`${name} 이미지`} />
          ) : (
            <p className={styles.noImage}>등록된 이미지가 없습니다.</p>
          )}
        </div>
        <div className={styles.infoDetail}>
          <div className={styles.storeName}>
            {name}
            <span>
              <GoToRankingBtn store={data.values[0]} />
            </span>
          </div>
          <div className={styles.storeAdr}>{address}</div>
          <div className={styles.storeReview}>
            <p>방문자 리뷰</p>
            <p className={styles.reviewdiff}>
              {placeReviewCount}
              <span className={styles.reviewcount}>
                (
                <span
                  style={{
                    color: cal_placeReviewCount.includes('▲')
                      ? 'rgb(255, 72, 0)'
                      : cal_placeReviewCount.includes('▼')
                      ? 'rgb(0, 110, 255)'
                      : 'inherit',
                  }}
                >
                  {cal_placeReviewCount}
                </span>
                )
              </span>
            </p>
            <span className={styles.reviewdiffText}>전주 대비</span>
          </div>
          <div className={styles.storeReview}>
            <p>블로그 리뷰</p>
            <p className={styles.reviewdiff}>
              {reviewCount}
              <span className={styles.reviewcount}>
                (
                <span
                  style={{
                    color: cal_reviewCount.includes('▲')
                      ? 'rgb(255, 72, 0)'
                      : cal_reviewCount.includes('▼')
                      ? 'rgb(0, 110, 255)'
                      : 'inherit',
                  }}
                >
                  {cal_reviewCount}
                </span>
                )
              </span>
            </p>
            <span className={styles.reviewdiffText}>전주 대비</span>
          </div>
        </div>
      </div>
      <div className={styles.keywordBox}>
        <div className={styles.storeUpdate} onClick={handleUpdateClick}>
          <p>새소식 업데이트</p>
          <div className={styles.updateDetails}>
            <span>{updateDays}</span>
            <RxUpdate />
          </div>
        </div>
        <div className={styles.keyword}>
          <div>
            <p className={styles.keywordTitle}>연관 키워드</p>
            <p className={styles.keywordCount}>
              {logData?.smart_keyword_count
                ? `키워드 ${logData.smart_keyword_count}개`
                : '-'}
            </p>
          </div>
          <div>
            <p className={styles.keywordTitle}>상단노출 키워드</p>
            <p className={styles.keywordCount}>
              {logData?.high_rank_keywords_count
                ? `키워드 ${logData.high_rank_keywords_count}개`
                : '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnalyzeStore;
