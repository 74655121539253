import React, { useState, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './Router';
import LogoutModal from './components/Modal/LogoutModal';
import { ModalProvider, useModal } from './hooks/ModalContext';
import { setGlobalModalHandler } from './api';
import Cookies from 'js-cookie';

function App() {
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    setGlobalModalHandler(() => {
      setModalVisible(true);
    });
  }, []);

  return (
    <ModalProvider value={{ isModalVisible, setModalVisible }}>
      <div>
        <RouterProvider router={router} />
        <LogoutModal
          isOpen={isModalVisible}
          onClose={() => setModalVisible(false)}
        />
      </div>
    </ModalProvider>
  );
}

export default App;
