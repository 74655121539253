import React, { useState } from 'react';
import styles from './CompareStoreTable.module.scss';

function CompareStoreTable({
  data,
  searchTerm,
  onStoreAdd,
  onStoreRemove,
  selectedStores,
  scrollable,
}) {
  const [loadMoreCount, setLoadMoreCount] = useState(0);

  const handleLoadMore = () => {
    setLoadMoreCount(loadMoreCount + 1);
  };

  const rowsToShow = 20 * (loadMoreCount + 1);

  const highlightSearchTerm = (storeName) => {
    const index = storeName.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (index === -1) return storeName;

    return (
      <span>
        {storeName.substring(0, index)}
        <span style={{ color: '#ff6524' }}>
          {storeName.substring(index, index + searchTerm.length)}
        </span>
        {storeName.substring(index + searchTerm.length)}
      </span>
    );
  };

  const handleCheckboxChange = (e, store) => {
    console.log('Is store.id in selectedStores?', selectedStores.has(store.id));
    if (e.target.checked) {
      // 상점 추가 로직
      onStoreAdd(store);
    } else {
      // 상점 삭제 로직
      onStoreRemove(store.id);
    }
  };

  return (
    <div
      className={`${styles.container} ${scrollable ? styles.scrollable : ''}`}
    >
      <div className={styles.table_box}>
        <table className={`${styles.table} ${styles.table_min}`}>
          <thead>
            <tr>
              <th style={{ width: '8%' }} className={styles.center}>
                추가
              </th>
              <th style={{ width: '24%' }} className={styles.left}>
                매장명
              </th>
              <th style={{ width: '40%' }} className={styles.left}>
                주소
              </th>
              <th style={{ width: '14%' }} className={styles.center}>
                방문자 리뷰
              </th>
              <th style={{ width: '14%' }} className={styles.center}>
                블로그 리뷰
              </th>
            </tr>
          </thead>
          <tbody>
            {data.values.slice(0, rowsToShow).map((store, index) => (
              <tr key={store.id}>
                <td style={{ textAlign: 'center' }}>
                  <input
                    className={styles.keywordCheckBtn}
                    type="checkbox"
                    checked={selectedStores.has(String(store.id))}
                    onChange={(e) => handleCheckboxChange(e, store)}
                  />
                </td>
                <td className={`${styles.left} ${styles.tableKeyword}`}>
                  {highlightSearchTerm(store.name)}
                </td>
                <td>{store.address}</td>
                <td className={styles.center}>{store.reviewCount}</td>
                <td className={styles.center}>{store.placeReviewCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data.values.length > rowsToShow && (
        <div className={styles.btnContainer}>
          <button onClick={handleLoadMore} className={styles.loadMoreButton}>
            더 보기
          </button>
        </div>
      )}
    </div>
  );
}

export default CompareStoreTable;
