import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import styles from './LocalSearch.module.scss';

function LocalSearch({ onOptionsChange }) {
  const [sidoOptions, setSidoOptions] = useState([]);
  const [sigoonOptions, setSigoonOptions] = useState([]);
  const [dongOptions, setDongOptions] = useState([]);
  const [selectedSido, setSelectedSido] = useState('');
  const [selectedSigoon, setSelectedSigoon] = useState('');
  const [selectedDong, setSelectedDong] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    sido: '',
    sigoon: '',
    dong: '',
  });

  useEffect(() => {
    // Fetch Sido options
    axios
      .get('https://api.vworld.kr/req/data', {
        adapter: jsonpAdapter,
        params: {
          key: process.env.REACT_APP_VWORLD_OPEN_API_KEY,
          domain: 'https://remarkable-cassata-368aab.netlify.app',
          service: 'data',
          version: '2.0',
          request: 'getfeature',
          format: 'json',
          size: 1000,
          page: 1,
          geometry: false,
          attribute: true,
          crs: 'EPSG:3857',
          geomfilter:
            'BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)',
          data: 'LT_C_ADSIDO_INFO',
        },
        dataType: 'jsonp',
      })
      .then((response) => {
        const customNames = {
          11: '서울',
          26: '부산',
          27: '대구',
          28: '인천',
          29: '광주',
          30: '대전',
          31: '울산',
          36: '세종',
          41: '경기',
          43: '충북',
          44: '충남',
          45: '전북',
          46: '전남',
          47: '경북',
          48: '경남',
          50: '제주',
          51: '강원',
        };

        const options =
          response.data.response.result.featureCollection.features.map((f) => ({
            code: f.properties.ctprvn_cd,
            name:
              customNames[f.properties.ctprvn_cd] || f.properties.ctp_kor_nm,
          }));
        setSidoOptions(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleSidoChange = (e) => {
    const selectedSidoCode = e.target.value;
    setSelectedSido(selectedSidoCode);
    setSelectedSigoon('');
    setSelectedDong('');
    setDongOptions([]);

    setSelectedOptions({
      sido: {
        code: selectedSidoCode,
        name: sidoOptions.find((option) => option.code === selectedSidoCode)
          ?.name,
      },
      sigoon: '',
      dong: '',
    });

    // Fetch Sigoon options
    axios
      .get('https://api.vworld.kr/req/data', {
        adapter: jsonpAdapter,
        params: {
          key: process.env.REACT_APP_VWORLD_OPEN_API_KEY,
          domain: 'https://remarkable-cassata-368aab.netlify.app',
          service: 'data',
          version: '2.0',
          request: 'getfeature',
          format: 'json',
          size: 1000,
          page: 1,
          geometry: false,
          attribute: true,
          crs: 'EPSG:3857',
          geomfilter:
            'BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)',
          data: 'LT_C_ADSIGG_INFO',
          attrfilter: `sig_cd:like:${selectedSidoCode}`,
        },
        dataType: 'jsonp',
      })
      .then((response) => {
        const options = response.data.response.result.featureCollection.features
          .filter((f) => f.properties.sig_cd.startsWith(selectedSidoCode)) // 여기서 필터링
          .map((f) => ({
            code: f.properties.sig_cd,
            name: f.properties.sig_kor_nm,
          }))
          .sort((a, b) => a.name.localeCompare(b.name, 'ko-KR'));
        setSigoonOptions(options);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSigoonChange = (e) => {
    const selectedSigoonCode = e.target.value;
    setSelectedSigoon(selectedSigoonCode);
    setSelectedDong('');

    setSelectedOptions({
      ...selectedOptions,
      sigoon: {
        code: selectedSigoonCode,
        name: sigoonOptions.find((option) => option.code === selectedSigoonCode)
          ?.name,
      },
      dong: '',
    });

    // Fetch Dong options
    axios
      .get('https://api.vworld.kr/req/data', {
        adapter: jsonpAdapter,
        params: {
          key: process.env.REACT_APP_VWORLD_OPEN_API_KEY,
          domain: 'https://remarkable-cassata-368aab.netlify.app',
          service: 'data',
          version: '2.0',
          request: 'getfeature',
          format: 'json',
          size: 1000,
          page: 1,
          geometry: false,
          attribute: true,
          crs: 'EPSG:3857',
          geomfilter:
            'BOX(13663271.680031825,3894007.9689600193,14817776.555251127,4688953.0631258525)',
          data: 'LT_C_ADEMD_INFO',
          attrfilter: `emd_cd:like:${selectedSigoonCode}`,
        },
        dataType: 'jsonp',
      })
      .then((response) => {
        const options = response.data.response.result.featureCollection.features
          .filter((f) => f.properties.emd_cd.startsWith(selectedSigoonCode)) // 여기에 필터링 추가
          .map((f) => ({
            code: f.properties.emd_cd,
            name: f.properties.emd_kor_nm,
          }))
          .sort((a, b) => a.name.localeCompare(b.name, 'ko-KR')); // 정렬
        setDongOptions(options);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDongChange = (e) => {
    const selectedDongCode = e.target.value;
    setSelectedDong(selectedDongCode);

    setSelectedOptions({
      ...selectedOptions,
      dong: {
        code: selectedDongCode,
        name: dongOptions.find((option) => option.code === selectedDongCode)
          ?.name,
      },
    });
  };

  const removeSelectedGroup = () => {
    // 모든 선택된 옵션 초기화
    setSelectedOptions({
      sido: '',
      sigoon: '',
      dong: '',
    });
    console.log('Before reset:', selectedSido);
    setSelectedSido('');
    console.log('After reset:', selectedSido);
    setSelectedSigoon(''); // 시구군 선택 초기화
    setSelectedDong(''); // 동읍면 선택 초기화
    setSigoonOptions([]); // 시구군 옵션 초기화
    setDongOptions([]); // 동읍면 옵션 초기화
  };

  // console.log(selectedOptions);

  useEffect(() => {
    console.log('Current selectedSido:', selectedSido);
  }, [selectedSido]);

  useEffect(() => {
    onOptionsChange(selectedOptions);
  }, [selectedOptions, onOptionsChange]);

  return (
    <div>
      <div className={styles.container}>
        <select id="sido_code" value={selectedSido} onChange={handleSidoChange}>
          <option value="">시·도</option>
          {sidoOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {option.name}
            </option>
          ))}
        </select>
        <select id="sigoon_code" onChange={handleSigoonChange}>
          <option value="">시·구·군</option>
          {sigoonOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {option.name}
            </option>
          ))}
        </select>
        <select id="dong_code" onChange={handleDongChange}>
          <option value="">동·읍·면</option>
          {dongOptions.map((option) => (
            <option key={option.code} value={option.code}>
              {option.name}
            </option>
          ))}
        </select>
      </div>

      {/* Selected options as tags */}
      {selectedOptions.sido && (
        <div className={styles.selected_option}>
          {selectedOptions.sido.name}
          {selectedOptions.sigoon && (
            <span className={styles.sub_option}>
              {' '}
              {selectedOptions.sigoon.name}
            </span>
          )}
          {selectedOptions.dong && (
            <span className={styles.sub_option}>
              {' '}
              {selectedOptions.dong.name}
            </span>
          )}
          <button onClick={removeSelectedGroup}>x</button>
        </div>
      )}
    </div>
  );
}

export default LocalSearch;
