import React from 'react';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={`${styles.wrapper} `}>
      <div className={styles.container}>
        <div className={styles.terms}>
          <span>이용약관</span>
          <div className={styles.spaceDashFirst}>|</div>
          <span>개인정보처리방침</span>
        </div>
        <div className={styles.info}>
          <span>상호명 : 마케차이즈</span>
          <div className={styles.spaceDash}>|</div>
          <span>대표 : 정영빈</span>
          <div className={styles.spaceDash}>|</div>
          <span>주소 : 경기도 의정부시 안골로 20 101동 1802호</span>
        </div>
        <div className={styles.company}>
          <span>이메일 : marketchise@gmail.com</span>
          <div className={styles.spaceDash}>|</div>
          <span>사업자 등록번호 : 239-03-03023</span>
        </div>
        <div className={styles.copyright}>
          Copyright 2023. MARKETCHISE Co. All rights reserved.{' '}
        </div>
      </div>
    </div>
  );
}

export default Footer;
