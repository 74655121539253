import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { userLogin } from '../../api';
import { useSetRecoilState } from 'recoil';
import { currentUserState } from '../../state';
import styles from './LoginPage.module.scss';
import { Spinner } from '@chakra-ui/react';
import FailureModal from '../../components/Modal/FailureModal';

function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [serverError, setServerError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const navigate = useNavigate();
  const token = Cookies.get('access');

  useEffect(() => {
    // 로그인 상태일 경우 홈페이지로 리다이렉트
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  const setCurrentUser = useSetRecoilState(currentUserState);

  const mutation = useMutation(userLogin, {
    onSuccess: (username) => {
      setCurrentUser({ username }); // set the current user state here
      navigate('/');
    },
    onError: (error) => {
      // 여기서 error 객체의 구조는 백엔드 API의 구현에 따라 달라집니다.
      // 만약 error.response.data에 "msg" 필드가 있다면 그 값을 사용합니다.
      const message =
        error.response?.data?.msg ||
        '서버 에러입니다. 잠시후 다시 시도해주세요.';
      setServerErrorMessage(message);
      setIsModalOpen(true); // 에러 발생 시 모달을 표시합니다.
      console.error('Login failed: ', message);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <div className={styles.logo}>마케차이즈</div>
        <label>
          아이디 :
          <input
            type="text"
            {...register('username', { required: true })}
            autoComplete="username"
          />
          {errors.username && <p>아이디를 확인해주세요.</p>}
        </label>
        <label>
          비밀번호 :
          <input
            type="password"
            {...register('password', { required: true })}
            autoComplete="current-password"
          />
          {errors.password && <p>비밀번호를 확인해주세요.</p>}
        </label>
        <button type="submit" disabled={mutation.isLoading}>
          {mutation.isLoading ? <Spinner /> : '로그인'}
        </button>
        {/* {mutation.isError && (
          <p className={styles.submitErr}>
            잘못된 요청입니다. <br />
            다시 시도해주세요.
          </p>
        )} */}
        <div className={styles.tag}>
          <span>또는</span>
        </div>
        <div className={styles.sign}>
          계정이 없으신가요?{' '}
          <span onClick={() => navigate('/signup')}>가입하기</span>
        </div>
      </form>
      <FailureModal
        isOpen={isModalOpen}
        onClose={closeModal}
        error={serverErrorMessage}
      />
    </div>
  );
}

export default LoginPage;
