import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import GlobalStyle from './styles/GlobalStyle';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import Seo from './seo/Seo';
import { ChakraProvider } from '@chakra-ui/react';
import { StateObserver } from './state';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <RecoilRoot>
    <StateObserver />
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <GlobalStyle />
        <HelmetProvider>
          <Seo title="마케차이즈" />
          <App />
        </HelmetProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </RecoilRoot>,
);
