import React, { useState, useContext } from 'react';
import styles from './UserStoreInfo.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getUserStoreList, deleteStore } from '../../api';
import GuideModal from '../Modal/GuideModal';
import Cookies from 'js-cookie';

import SolutionEx from '../Example/SolutionEx';
import SearchInputContext from '../../hooks/SearchInputContext';

function UserStoreInfo() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // 모달 및 삭제할 상점 ID와 이름의 상태
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [selectedStoreName, setSelectedStoreName] = useState(null);
  const searchInputRef = useContext(SearchInputContext);

  const getCategory = (pathname) => {
    switch (pathname) {
      case '/review/analyze':
        return 'review';
      case '/review/diagnosis':
        return 'blog';
      case '/ranking/trace':
        return 'keyword';
      // case '/ranking/compare':
      //   return 'compare';
      case '/solution':
        return 'solution';
      default:
        console.error('Invalid pathname:', pathname);
        return null;
    }
  };

  const category = getCategory(location.pathname);

  const {
    data: stores,
    isError,
    error,
    isLoading,
  } = useQuery(['userStoreList', category], () => getUserStoreList(category), {
    enabled: !!category,
    refetchOnWindowFocus: true,
  });

  const mutation = useMutation((storeId) => deleteStore(category, storeId), {
    onSuccess: () => {
      // When the store is successfully deleted, re-fetch the stores list
      queryClient.invalidateQueries(['userStoreList', category]);
    },
  });

  const handleDelete = (storeId) => {
    mutation.mutate(storeId);
  };

  const openModal = (storeId, storeName) => {
    setSelectedStoreId(storeId);
    setSelectedStoreName(storeName);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedStoreId(null);
    setSelectedStoreName(null);
    setModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (selectedStoreId) {
      handleDelete(selectedStoreId);
    }
    closeModal();
  };

  const getExampleComponent = (category) => {
    switch (category) {
      case 'review':
        return <div>분석할 상점을 등록해보세요!</div>;
      case 'blog':
        return <div>진단할 상점을 등록해보세요!</div>;
      case 'keyword':
        return <div>추적할 상점을 등록해보세요!</div>;
      case 'solution':
        return (
          <>
            <div>진단할 상점을 등록해보세요!</div>
            <SolutionEx />
          </>
        );
      default:
        return null;
    }
  };

  // 로그인 상태 확인
  const token = Cookies.get('access');
  if (!token) {
    return null; // 로그인이 되어 있지 않으면 아무 것도 렌더링하지 않습니다.
  }

  if (isLoading) {
    return <div>상점 목록 로딩 중...</div>;
  }
  if (mutation.isLoading) {
    return <div>상점 삭제 중...</div>;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }

  const totalStores = stores && stores.stores ? stores.stores.length : 0;

  const totalKeywords =
    stores && stores.stores
      ? stores.stores.reduce(
          (acc, store) => acc + (store.keywords ? store.keywords.length : 0),
          0,
        )
      : 0;

  const totalBlogs =
    stores && stores.stores
      ? stores.stores.reduce(
          (acc, store) => acc + (store.logs ? store.logs.length : 0),
          0,
        )
      : 0;

  return (
    <div className={styles.container}>
      {totalStores > 0 ? (
        <>
          {/* category가 'keyword', 'review', 'blog', 또는 'solution'일 때만 헤더를 표시합니다. */}
          {(category === 'keyword' ||
            category === 'review' ||
            category === 'blog' ||
            category === 'solution') && (
            <h2 className={styles.header}>
              <div className={styles.headerRight}>등록된 상점</div>
              <div className={styles.headerLeft}>
                <span>등록 상점 {totalStores}/30</span>
                {category === 'blog' && (
                  <span>
                    등록 블로그 {totalBlogs}/{totalStores * 10}
                  </span>
                )}
                {(category === 'keyword' || category === 'review') && (
                  <span>
                    등록 키워드 {totalKeywords}/{totalStores * 10}
                  </span>
                )}
              </div>
            </h2>
          )}

          {stores.stores.map((store) => (
            <div key={store.id} className={styles.storeBox}>
              <div className={styles.storeInfo}>
                <div className={styles.imgBox}>
                  {store.thumUrl ? (
                    <img src={store.thumUrl} alt={`${store.name} 이미지`} />
                  ) : (
                    <p className={styles.noImage}>등록된 이미지가 없습니다.</p>
                  )}
                </div>

                <div className={styles.storeDetail}>
                  <div className={styles.storeName}>{store.name}</div>
                  <div className={styles.storeAdr}>{store.address}</div>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <button
                  className={styles.navBtn}
                  onClick={() =>
                    navigate(
                      `${location.pathname}/detail/${store.name}/${store.store_id}`,
                    )
                  }
                >
                  상세보기
                </button>
                <button
                  className={styles.deleteBtn}
                  onClick={() => openModal(store.store_id, store.name)}
                >
                  삭제
                </button>
              </div>
            </div>
          ))}
        </>
      ) : (
        getExampleComponent(category)
      )}
      <GuideModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onProceed={handleDeleteConfirm}
        MessageComponent={() => (
          <p>[{selectedStoreName}] 상점을 삭제하시겠습니까?</p>
        )}
      />
    </div>
  );
}

export default UserStoreInfo;
