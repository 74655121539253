import React from 'react';
import styles from './Analyze.module.scss';
import { useNavigate, Outlet } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';

function Analyze() {
  const navigate = useNavigate();

  const handleSearch = (searchTerm, address) => {
    console.log('검색어:', searchTerm);
    console.log('주소:', address);

    const params = new URLSearchParams({
      adr: address,
      q: searchTerm,
    }).toString();

    navigate(`/review/analyze/search?${params}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>마케차이즈</div>
      <div className={styles.switch}>
        <div className={styles.switchLeft}>리뷰 분석</div>
        <div
          className={styles.switchRight}
          onClick={() => navigate('/review/diagnosis')}
        >
          리뷰 진단
        </div>
      </div>
      <SearchBar onSearch={handleSearch} />
      <Outlet />
    </div>
  );
}

export default Analyze;
