import React, { useState, useEffect } from 'react';
import styles from './AnalyzeTable.module.scss';
import { Spinner } from '@chakra-ui/react';

function AnalyzeTable({
  myKeywords = [],
  logData,
  taskLoadingStates,
  completedKeywords,
  onHashTagClick,
  onChartKeywordSelect,
}) {
  const [expandedKeywords, setExpandedKeywords] = useState({});
  const [isDataFullyReady, setIsDataFullyReady] = useState({});

  const stripHtml = (htmlString) => {
    var doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
  };

  const toggleExpand = (keyword) => {
    setExpandedKeywords((prevState) => {
      const newState = { ...prevState, [keyword]: !prevState[keyword] };
      console.log('New expandedKeywords:', newState);
      return newState;
    });
  };

  const keywordEntries = myKeywords
    .filter((keyword) => {
      return !(
        taskLoadingStates[keyword] !== true &&
        !(logData && logData.keyword && logData.keyword[keyword]) &&
        !completedKeywords.has(keyword)
      );
    })
    .map((keyword) => {
      const entry =
        logData && logData.keyword ? logData.keyword[keyword] || [] : [];
      const actualEntryLength = entry.filter(
        (e) => !('rel_keyword' in e),
      ).length;
      const relKeywordObj = entry.find((e) => 'rel_keyword' in e);
      const relKeywords = relKeywordObj ? relKeywordObj.rel_keyword : [];

      const isDataLoaded =
        logData && logData.keyword && logData.keyword[keyword];
      const isLoadingData = taskLoadingStates[keyword] === true;
      const isDataEmpty = isDataLoaded && entry.length === 0;

      const isDataReady =
        logData && logData.keyword && logData.keyword[keyword];

      return {
        keyword,
        entry,
        isLoadingData,
        isDataEmpty,
        isDataReady,
        relKeywords,
        actualEntryLength,
      };
    });

  // 랭크가 101등인 경우 null로 처리
  const isValidRank = (rank) => {
    return rank !== undefined && rank !== 101;
  };

  useEffect(() => {
    let fullyReadyState = {};
    let shouldUpdate = false;
    for (const {
      keyword,
      isDataReady,
      actualEntryLength,
      entry,
    } of keywordEntries) {
      // 데이터가 준비되었고 실제로 유효한 길이를 가지고 있거나, 빈 배열인 경우를 확인합니다.
      const isEntryValid = actualEntryLength > 0 || entry.length === 0;
      fullyReadyState[keyword] = isDataReady && isEntryValid;
      if (fullyReadyState[keyword] !== isDataFullyReady[keyword]) {
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      setIsDataFullyReady(fullyReadyState);
    }
  }, [keywordEntries, isDataFullyReady]);

  return (
    <div className={styles.container}>
      <div className={styles.table_box}>
        <table className={`${styles.table} ${styles.table_min}`}>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>
                키워드{' '}
                <span className={styles.keywordSearchCount}>(월 검색량)</span>
              </th>
              <th style={{ width: '58%' }}>블로그글 제목</th>
              <th style={{ width: '12%' }}>순위</th>
            </tr>
          </thead>
          <tbody>
            {keywordEntries.map(
              (
                {
                  keyword,
                  entry,
                  isLoadingData,
                  isDataReady,
                  isDataEmpty,
                  relKeywords,
                  actualEntryLength,
                },
                index,
              ) => {
                const monthlySearchCount =
                  entry[0]?.monthly_search_count || 'N/A'; // 월 검색량 추가
                return (
                  <tr key={`${keyword}_${index}`} className={styles.KeywordBox}>
                    <th
                      className={styles.keywordTitle}
                      style={{ verticalAlign: 'top' }}
                    >
                      <p
                        className={styles.keywordName}
                        onClick={() => onChartKeywordSelect(keyword)}
                      >
                        {keyword}{' '}
                        <span className={styles.keywordSearchCount}>
                          ({monthlySearchCount.toLocaleString()})
                        </span>
                      </p>
                      {relKeywords.length > 0 && (
                        <div className={styles.relKeywords}>
                          {relKeywords.map((rel, idx) => {
                            const noSpaceRel = rel.replace(/\s+/g, '');
                            return (
                              <span
                                key={idx}
                                className={styles.hashTag}
                                onClick={() => {
                                  if (onHashTagClick) {
                                    onHashTagClick(noSpaceRel);
                                  }
                                }}
                              >
                                #{noSpaceRel}
                              </span>
                            );
                          })}
                        </div>
                      )}
                      {actualEntryLength > 4 && (
                        <button
                          onClick={() => toggleExpand(keyword)}
                          className={styles.btn}
                        >
                          <span className={styles.underlineText}>
                            {expandedKeywords[keyword] ? '숨기기' : '더보기'}
                          </span>
                          {expandedKeywords[keyword] ? '▲' : '▼'}
                        </button>
                      )}
                    </th>
                    {isLoadingData ? (
                      <td colSpan={2}>
                        <Spinner color="#ff6524" size="md" />
                      </td>
                    ) : !isDataFullyReady[keyword] ? (
                      <td colSpan={2} style={{ verticalAlign: 'top' }}>
                        <Spinner color="#ff6524" size="md" />
                      </td>
                    ) : entry.length === 0 ? (
                      <td colSpan={2} style={{ verticalAlign: 'top' }}>
                        해당 날짜의 데이터가 없습니다.
                      </td>
                    ) : entry[0].rank === 101 ? (
                      <td colSpan={2} style={{ verticalAlign: 'top' }}>
                        해당 키워드로 상단노출된 블로그가 없습니다.
                      </td>
                    ) : (
                      <>
                        <td style={{ verticalAlign: 'top' }}>
                          <ul>
                            {entry
                              .slice(
                                0,
                                expandedKeywords[keyword] ? entry.length : 4,
                              )
                              .map((post, index) => {
                                if (
                                  post.rank === undefined ||
                                  post.blog_link === undefined ||
                                  post.blog_title === undefined
                                ) {
                                  return null;
                                }
                                return (
                                  <li
                                    key={index}
                                    className={`${styles.listItem} ${styles.linkHover}`}
                                  >
                                    <a
                                      href={post.blog_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {stripHtml(post.blog_title)}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </td>
                        <td style={{ verticalAlign: 'top' }}>
                          <ul>
                            {entry
                              .slice(
                                0,
                                expandedKeywords[keyword] ? entry.length : 4,
                              )
                              .map((post, index) => {
                                if (!isValidRank(post.rank)) {
                                  return null;
                                }
                                return (
                                  <li key={index} className={styles.listItem}>
                                    <span
                                      style={
                                        post.rank <= 10
                                          ? { color: '#ff6524' }
                                          : {}
                                      }
                                    >
                                      {post.rank}위
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </td>
                      </>
                    )}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AnalyzeTable;
