import React from 'react';
import styles from './LoginError.module.scss';
import { useNavigate } from 'react-router-dom';

function LoginError() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.logo}>마케차이즈</h1>
        <p className={styles.text}>
          로그인이 필요한 서비스입니다.
          <br />
          로그인 후 이용해주세요.
        </p>
        <p className={styles.nav} onClick={() => navigate('/login')}>
          로그인 하러 가기
        </p>
      </div>
    </div>
  );
}

export default LoginError;
