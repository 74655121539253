import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Root from './Root/Root';
import LoginPage from './pages/LoginPage/LoginPage';
import SignupPage from './pages/SignupPage/SignupPage';
import FAQPage from './pages/FAQPage/FAQPage';
import Ranking from './pages/Ranking/Ranking';
import RankingSearch from './pages/RankingSearchPage/RankingSearch';
import RankingDetail from './pages/RankingDetailPage/RankingDetail';
import Compare from './pages/Compare/Compare';
import CompareDetail from './pages/CompareDetail/CompareDetail';
import Analyze from './pages/Analyze/Analyze';
import AnalyzeSearch from './pages/AnalyzeSearch/AnalyzeSearch';
import AnalyzeDetail from './pages/AnalyzeDetail/AnalyzeDetail';
import Diagnosis from './pages/Diagnosis/Diagnosis';
import DiagnosisSearch from './pages/DiagnosisSearch/DiagnosisSearch';
import DiagnosisDetail from './pages/DiagnosisDetail/DiagnosisDetail';
import Find from './pages/Find/Find';
import FindDetail from './pages/FindDetail/FindDetail';
import Solution from './pages/Solution/Solution';
import SolutionSearch from './pages/SolutionSearch/SolutionSearch';
import SolutionDetail from './pages/SolutionDetail/SolutionDetail';

import LoginError from './Error/LoginError';
import NotFound from './Error/NotFound';
import UserStoreInfo from './components/UserStoreInfo/UserStoreInfo';

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// 이 컴포넌트는 자식 컴포넌트를 받아서, 만약 토큰이 없으면 로그인 페이지로 리다이렉트합니다.
function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const token = Cookies.get('access');

  useEffect(() => {
    if (!token) {
      navigate('/require');
    }
  }, [token, navigate]);

  if (!token) return null;

  return children;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Navigate replace to="/find" />, // 홈페이지 접속 시 /find로 리다이렉트
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'signup',
        element: <SignupPage />,
      },
      {
        path: 'find',
        element: <Find />,
        children: [
          {
            path: 'detail',
            element: (
              // <ProtectedRoute>
              <FindDetail />
              // </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'ranking/trace',
        element: <Ranking />,
        children: [
          {
            path: '',
            element: <UserStoreInfo />,
          },
          {
            path: 'search',
            element: (
              <ProtectedRoute>
                <RankingSearch />
              </ProtectedRoute>
            ),
          },
          {
            path: 'detail/:storeName/:storeId',
            element: (
              <ProtectedRoute>
                <RankingDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'ranking/compare',
        element: <Compare />,
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute>
                <CompareDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'review/analyze',
        element: <Analyze />,
        children: [
          {
            path: '',
            element: <UserStoreInfo />,
          },
          {
            path: 'search',
            element: (
              <ProtectedRoute>
                <AnalyzeSearch />
              </ProtectedRoute>
            ),
          },
          {
            path: 'detail/:storeName/:storeId',
            element: (
              <ProtectedRoute>
                <AnalyzeDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'review/diagnosis',
        element: <Diagnosis />,
        children: [
          {
            path: '',
            element: <UserStoreInfo />,
          },
          {
            path: 'search',
            element: (
              <ProtectedRoute>
                <DiagnosisSearch />
              </ProtectedRoute>
            ),
          },
          {
            path: 'detail/:storeName/:storeId',
            element: (
              <ProtectedRoute>
                <DiagnosisDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'solution',
        element: <Solution />,
        children: [
          {
            path: '',
            element: <UserStoreInfo />,
          },
          {
            path: 'search',
            element: (
              <ProtectedRoute>
                <SolutionSearch />
              </ProtectedRoute>
            ),
          },
          {
            path: 'detail/:storeName/:storeId',
            element: (
              <ProtectedRoute>
                <SolutionDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: 'faq',
        element: <FAQPage />,
      },
      {
        path: 'require',
        element: <LoginError />,
      },
    ],
  },
]);

export default router;
