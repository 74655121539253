import React, { useContext } from 'react';
import styles from './SolutionEx.module.scss';
import MarketingStore from '../Marketing/MarketingStore/MarketingStore';
import MarketingScoreEx from '../Marketing/MarketingScore/MarketingScoreEx';
import SearchInputContext from '../../hooks/SearchInputContext';

function SolutionEx({}) {
  const searchInputRef = useContext(SearchInputContext);

  const handleOverlayClick = () => {
    if (searchInputRef && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src="/images/eye.png" alt="눈 이미지" />
        <span>마케팅 솔루션 미리보기</span>
      </div>
      <div className={styles.storeInfo}>
        <MarketingStore
          searchInputRef={searchInputRef}
          onDiagnoseClick={handleOverlayClick}
        />
      </div>
      <div className={styles.scoreInfo}>
        <MarketingScoreEx searchInputRef={searchInputRef} />
      </div>
    </div>
  );
}

export default SolutionEx;
