import React from 'react';
import styles from './Ranking.module.scss';
import { useNavigate, Outlet } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
// import Seo from '../../seo/Seo';

function Ranking() {
  const navigate = useNavigate();

  const handleSearch = (searchTerm, address) => {
    console.log('검색어:', searchTerm);
    console.log('주소:', address);

    const params = new URLSearchParams({
      adr: address,
      q: searchTerm,
    }).toString();
    // 쿼리 매개변수를 활용한 동적 라우팅

    navigate(`/ranking/trace/search?${params}`);
  };

  return (
    <div className={styles.container}>
      {/* <Seo title="순위추적" /> */}
      <div className={styles.logo}>마케차이즈</div>
      <div className={styles.switch}>
        <div className={styles.switchLeft}>순위 추적</div>
        <div
          className={styles.switchRight}
          onClick={() => navigate('/ranking/compare')}
        >
          순위 비교
        </div>
      </div>
      <SearchBar onSearch={handleSearch} />
      <Outlet />
    </div>
  );
}

export default Ranking;
