import React, { useState, useEffect } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  StackedBarChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Dot,
} from 'recharts';
import useWindowSize from '../../hooks/useWindowSize';

function formatData(rawData, period) {
  // Data Validation
  console.log('Raw Data:', JSON.stringify(rawData));
  console.log('Period:', period);

  if (
    !rawData ||
    !rawData[period] ||
    !Array.isArray(rawData[period]) ||
    rawData[period].length === 0
  ) {
    return [];
  }

  if (period === 'weekly') {
    // rawData[period] 배열을 slice()로 복사하고 sort()로 날짜 기준 오름차순 정렬
    const sortedWeeklyData = rawData[period]
      .slice()
      .sort((a, b) => new Date(a.date) - new Date(b.date));
    return sortedWeeklyData.map((entry) => {
      // rank가 0일 경우 high_rank도 null로 설정
      if (entry.rank === 0) {
        return {
          date: entry.date,
          rank: null,
          topBlogs: null,
          index: entry.index,
        };
      }

      // rank가 0이 아닌 경우, high_rank가 0일 때 0.1로 설정
      return {
        date: entry.date,
        rank: entry.rank,
        topBlogs: entry.high_rank === 0 ? 0.1 : entry.high_rank,
        index: entry.index,
      };
    });
  }

  let periodData = rawData[period];
  let endDate = new Date(periodData[0]?.date);
  let startDate = new Date(endDate);

  // Check Start and End Date
  console.log('Start Date:', startDate);
  console.log('End Date:', endDate);

  if (period === 'daily') startDate.setDate(endDate.getDate() - 11);
  else if (period === 'monthly') startDate.setMonth(endDate.getMonth() - 11);

  let formattedData = [];
  for (let i = 0; i < 12; i++) {
    const dateStr = startDate.toISOString().slice(0, 10);
    const entry = periodData.find((e) => e.date === dateStr) || {
      date: dateStr,
      rank: null,
      high_rank: null,
    };

    let highRankValue = entry.high_rank;

    // high_rank 값이 "0"인 경우에만 0.1로 설정하고, 그렇지 않으면 null로 설정
    const topBlogs =
      highRankValue === 0 ? 0.1 : highRankValue ? Number(highRankValue) : null;

    formattedData.push({
      date: entry.date,
      rank: entry.rank,
      topBlogs,
    });

    if (period === 'daily') startDate.setDate(startDate.getDate() + 1);
    else if (period === 'monthly') startDate.setMonth(startDate.getMonth() + 1);
  }

  return formattedData;
}

const CustomBar = (props) => {
  const { x, y, width, height } = props;

  return (
    <rect
      x={x}
      y={y}
      rx={2} // 라운드된 모서리의 x반경
      ry={2} // 라운드된 모서리의 y반경
      width={width}
      height={height}
      fill="#ff6524" // 메인 색상
    />
  );
};

const CustomTooltipForHighRank = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // 'topBlogs' 값이 0.1인 경우 '0개'로 표시
    const topBlogsValue =
      payload[0].payload.topBlogs === 0.1 ? '0' : payload[0].payload.topBlogs;

    return (
      <div
        style={{
          background: 'white',
          borderRadius: '6px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          color: '#555555',
        }}
      >
        <p
          style={{ fontWeight: '500', fontSize: '14px' }}
        >{`${payload[0].payload.date.replace(/-/g, '.')} 기준`}</p>
        <p style={{ fontWeight: '500' }}>
          10등 이내 블로그 수 : {topBlogsValue}개
        </p>
      </div>
    );
  }
  return null;
};

function ReviewHighRankChart({ rawData, period }) {
  const windowSize = useWindowSize();
  const [chartConfig, setChartConfig] = useState({
    xAxisPadding: { left: 10, right: 10 },
    barSize: 20,
    tickFontSize: '14px',
  });

  useEffect(() => {
    // 화면 크기에 따라 XAxis의 패딩 값을 조정합니다.
    if (windowSize < 576) {
      setChartConfig({
        xAxisPadding: { left: 10, right: 10 },
        barSize: 10,
        tickFontSize: '12px',
      });
    } else {
      setChartConfig({
        xAxisPadding: { left: 10, right: 10 },
        barSize: 20,
        tickFontSize: '14px',
      });
    }
  }, [windowSize]); // windowSize가 바뀔 때마다 이 effect를 실행합니다.

  const data = formatData(rawData, period);

  return (
    <ResponsiveContainer width="100%" height={240}>
      <ComposedChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 10,
        }}
      >
        <XAxis
          dataKey={period === 'weekly' ? 'index' : 'date'}
          tickFormatter={(value) => {
            const date = new Date(value);
            if (period === 'daily') return value.slice(-5).replace(/-/g, '.');
            if (period === 'weekly') {
              return value; // 주간 데이터의 경우 'index' 값 그대로 표시
            }
            if (period === 'monthly')
              return value.slice(2, 7).replace(/-/g, '.');
            return value;
          }}
          tickLine={false}
          padding={chartConfig.xAxisPadding}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
          textAnchor="middle"
        />

        {/* YAxis for totalBlogs */}
        <YAxis
          domain={[0, 10]}
          ticks={[10, 8, 6, 4, 2, 0]}
          tickLine={false}
          padding={{ top: 10, bottom: 20 }}
          tick={{ fontWeight: '500', fontSize: chartConfig.tickFontSize }}
        />

        {/* YAxis for topBlogs
        <YAxis
          yAxisId="top"
          domain={[0, 10]}
          ticks={[10, 8, 6, 4, 2]}
          orientation="right"
          tickLine={false}
          padding={{ top: 20, bottom: 20 }}
          tick={{ fontWeight: '500', fontSize: '14px' }}
        /> */}

        <Tooltip content={<CustomTooltipForHighRank />} cursor={false} />

        {/* Bars for totalBlogs */}
        {/* <Bar
          yAxisId="total"
          dataKey="totalBlogs"
          fill="lightgray"
          stackId="total"
        /> */}

        {/* Bars for topBlogs */}
        <Bar
          type="monotone"
          dataKey="topBlogs"
          shape={<CustomBar />}
          barSize={chartConfig.barSize}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default ReviewHighRankChart;
