import React from 'react';
import styles from './StoreList.module.scss';

function StoreList({ store, onClick }) {
  return (
    <div>
      <div className={styles.container} onClick={onClick}>
        <div className={styles.name}>{store.name}</div>
        <div className={styles.adr}>{store.address}</div>
        <div className={styles.review}>
          방문자리뷰 {store.placeReviewCount} · 블로그리뷰 {store.reviewCount}
        </div>
      </div>
    </div>
  );
}

export default StoreList;
