import React, { useState, useEffect } from 'react';
import styles from './SearchBar.module.scss';
import { BiSearchAlt } from 'react-icons/bi';
import LocalSearch from './LocalSearch';

function SearchBar({ onSearch, inputRef }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionsChange = (options) => {
    setSelectedOptions(options);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(`Search Term: ${searchTerm}`);
    // console.log('Selected Options:', selectedOptions);

    const { sido, sigoon, dong } = selectedOptions;
    // Check if the names exist before adding them to the address.
    let address = sido && sido.name ? sido.name : '';
    address += sigoon && sigoon.name ? ' ' + sigoon.name : '';
    address += dong && dong.name ? ' ' + dong.name : '';

    // Trim the address to remove any leading or trailing spaces.
    address = address.trim();
    onSearch(searchTerm, address);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.searchBar}>
        <input
          type="text"
          placeholder="지역을 선택하고 매장명을 입력해주세요."
          value={searchTerm}
          onChange={handleChange}
          className={styles.searchInput}
          ref={inputRef}
        />
        <button
          type="submit"
          className={styles.searchButton}
          disabled={!searchTerm.trim()}
        >
          <BiSearchAlt />
          추적
        </button>
      </form>
      <LocalSearch onOptionsChange={handleOptionsChange} />
    </div>
  );
}

export default SearchBar;
