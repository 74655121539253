import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';

function FailureModal({ isOpen, onClose, error }) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent maxW={['320px', '320px', '480px']}>
        <ModalHeader>알림</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
        <ModalBody style={{ textAlign: 'center' }}>{error}</ModalBody>
        <ModalFooter style={{ gap: '10px', marginTop: '10px' }}>
          <Button
            onClick={() => {
              onClose();
            }}
            color="white"
            backgroundColor="#ff6524"
            _hover={{
              backgroundColor: '#e75719',
            }}
          >
            확인
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default FailureModal;
