import React, { useState, useEffect } from 'react';
import styles from './FindBar.module.scss';
import { BiSearchAlt } from 'react-icons/bi';

function FindBar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(`Search Term: ${searchTerm}`);
    // console.log('Selected Options:', selectedOptions);
    // 모든 공백 제거
    const trimmedSearchTerm = searchTerm.replace(/\s+/g, '');

    onSearch(trimmedSearchTerm);
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.searchBar}>
        <input
          type="text"
          placeholder="지역/키워드 형태소를 입력해보세요 (ex. 강남, 마라탕, 맛집 등)"
          value={searchTerm}
          onChange={handleChange}
          className={styles.searchInput}
        />
        <button
          type="submit"
          className={styles.searchButton}
          disabled={!searchTerm.trim()}
        >
          <BiSearchAlt />
          검색
        </button>
      </form>
    </div>
  );
}

export default FindBar;
