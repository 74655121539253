import React, { useState, useEffect, useMemo } from 'react';
import styles from './SolutionDetail.module.scss';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getStoreDetailSolution,
  postStoreSolution,
  getTaskStatus,
  getStoreSolution,
} from '../../api';
import MarketingStore from '../../components/Marketing/MarketingStore/MarketingStore';
import MarketingScore from '../../components/Marketing/MarketingScore/MarketingScore';
import { Spinner } from '@chakra-ui/react';
import FailureModal from '../../components/Modal/FailureModal';
import DateContainer from '../../components/DateContainer/DateContainer';
import { GrPrevious, GrNext } from 'react-icons/gr';

function SolutionDetail() {
  const { storeName, storeId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [enableSolutionQuery, setEnableSolutionQuery] = useState(false);
  const [isSolutionQueryTriggered, setIsSolutionQueryTriggered] =
    useState(false); // 새로운 상태 변수
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null); // 선택된 날짜
  const [dateIndex, setDateIndex] = useState(0); // 현재 선택된 날짜 인덱스

  const queryClient = useQueryClient();

  const formatDateForAPI = (date) => {
    return date ? date.replace(/-/g, '').substring(2) : null;
  };

  const storeDetailQueryKey = useMemo(
    () => ['storeDetailSolution', { storeName, storeId }],
    [storeName, storeId],
  );

  const solutionQueryKey = useMemo(
    () => [
      'storeSolution',
      { storeId, date: selectedDate ? formatDateForAPI(selectedDate) : null },
    ],
    [storeId, selectedDate],
  );

  const { isLoading: isStoreLoading, data: storeData } = useQuery(
    storeDetailQueryKey,
    () => getStoreDetailSolution({ storeName, storeId }),
    { retry: false },
  );

  useEffect(() => {
    if (storeData?.data?.is_diagnosed) {
      setEnableSolutionQuery(storeData.data.is_diagnosed);
    } else {
      setEnableSolutionQuery(false);
    }
  }, [storeData, storeId]);

  // 날짜 정보를 API 호출에 사용
  const {
    data: solutionData,
    isLoading: isSolutionLoading,
    refetch: refetchSolution,
  } = useQuery(
    solutionQueryKey,
    () => getStoreSolution({ storeId, date: formatDateForAPI(selectedDate) }),
    {
      enabled: enableSolutionQuery,
      retry: false,
    },
  );

  // 날짜 초기화
  useEffect(() => {
    if (storeData && storeData?.date_list && storeData.date_list.length > 0) {
      setSelectedDate(storeData.date_list[dateIndex]);
    } else {
      setSelectedDate(null);
    }
  }, [storeData, dateIndex]);

  const handleDiagnoseClick = async () => {
    setIsLoading(true);
    setIsSolutionQueryTriggered(true); // 쿼리가 실행됨을 표시
    try {
      const { task_id } = await postStoreSolution(storeId);

      const checkTaskStatus = async () => {
        const statusResponse = await getTaskStatus(task_id);
        const taskState = statusResponse[0].state;
        const statusMessage = statusResponse[0].status; // status 메시지 추출

        if (taskState === 'SUCCESS') {
          queryClient.invalidateQueries(storeDetailQueryKey);
          refetchSolution();
          setIsLoading(false);
        } else if (taskState === 'FAILURE') {
          // FAILURE 상태 처리
          setIsLoading(false);
          setErrorModalMessage(statusMessage); // 오류 메시지 설정
          setErrorModalOpen(true); // 모달을 띄웁니다
        } else {
          setTimeout(checkTaskStatus, 5000);
        }
      };

      checkTaskStatus();
    } catch (error) {
      setIsLoading(false);
      setErrorModalMessage('진단 중 오류가 발생했습니다.'); // 오류 메시지 설정
      setErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };

  //  // 날짜 변경 함수 내부에서 상태 초기화
  //  const handleDateChangeCommon = () => {
  //   setTaskLoadingStates({}); // 로딩 상태 초기화
  //   setCompletedKeywords(new Set()); // 완료된 키워드 초기화
  //   // 추가로 초기화해야 할 상태가 있다면 여기에 코드를 추가
  // };

  const handleDateChangeCommon = () => {
    // 여기에 상태 초기화 로직
  };

  const handlePreviousDate = () => {
    if (dateIndex < storeData.date_list.length - 1) {
      setDateIndex(dateIndex + 1);
      handleDateChangeCommon();
    }
  };

  const handleNextDate = () => {
    if (dateIndex > 0) {
      setDateIndex(dateIndex - 1);
      handleDateChangeCommon();
    }
  };

  const handleDateChange = (calendarDate) => {
    const formattedDate = calendarDate;
    const index = storeData.date_list.findIndex(
      (date) => date === formattedDate,
    );
    setDateIndex(index);
    setSelectedDate(formattedDate);
    handleDateChangeCommon();
  };

  if (isStoreLoading) {
    return <div>로딩중입니다...</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src="/images/eye.png" alt="눈 이미지" />
        <span>'{storeData.data.name}' 마케팅 솔루션 결과</span>
      </div>
      <div className={styles.storeInfo}>
        <MarketingStore
          storeData={storeData}
          onDiagnoseClick={handleDiagnoseClick}
          isLoading={isLoading}
        />
      </div>

      {storeData && storeData?.date_list && storeData.date_list.length > 0 ? (
        <div className={styles.dateContainer}>
          <GrPrevious
            onClick={handlePreviousDate} // 이전 버튼 클릭 시 다음 날짜로 이동
            className={
              dateIndex < storeData?.date_list.length - 1
                ? ''
                : styles.disabledButton
            }
            style={{ cursor: 'pointer' }}
          />
          <DateContainer
            logData={solutionData}
            selectedDate={selectedDate}
            onDateChange={handleDateChange}
          />
          <GrNext
            onClick={handleNextDate} // 다음 버튼 클릭 시 이전 날짜로 이동
            className={dateIndex > 0 ? '' : styles.disabledButton}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ) : null}

      <div className={styles.scoreInfo}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <Spinner
              color="#ff6524"
              size="xl"
              speed="0.65s"
              emptyColor="gray.200"
              thickness="4px"
            />
            <p className={styles.messageText}>
              진단 중...
              <br />
              잠시만 기다려 주세요.
            </p>
          </div>
        ) : enableSolutionQuery && isSolutionLoading ? ( // 진단이 되어 있고, 로딩 중일 때
          <div className={styles.loadingContainer}>
            <Spinner
              color="#ff6524"
              size="xl"
              speed="0.65s"
              emptyColor="gray.200"
              thickness="4px"
            />
            <p className={styles.messageText}>
              솔루션 데이터 로딩 중...
              <br />
              잠시만 기다려 주세요.
            </p>
          </div>
        ) : storeData?.data?.is_diagnosed ? (
          solutionData ? (
            <MarketingScore solutionData={solutionData} storeData={storeData} />
          ) : (
            <div className={styles.loadingContainer}>
              <p className={styles.messageText}>데이터가 없습니다.</p>
            </div>
          )
        ) : (
          <div className={styles.loadingContainer}>
            <p className={styles.messageText}>
              진단이 필요합니다.
              <br />
              진단하기 버튼을 눌러 진단해주세요.
            </p>
          </div> // 진단이 되어있지 않을 경우
        )}
      </div>

      {/* 오류 모달 */}
      <FailureModal
        isOpen={isErrorModalOpen}
        onClose={handleCloseErrorModal}
        error={errorModalMessage}
      />
    </div>
  );
}

export default SolutionDetail;
